import SignUpView from "./SignUp.view";
import { useState, useEffect } from "react";
import { FirebaseAuthService, OTPService, UserService } from "../../services";

function SignUpContainer(props) {
    const [currentStep, setCurrentStep] = useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const [phoneCode, setPhoneCode] = useState("+91");
    const [country, setCountry] = useState("IN");
    const [searchTerm, setSearchTerm] = useState("");
    const [enterPhoneOtpSignup, setEnterPhoneOtpSignup] = useState(false);
    const [signUpEmailOtp, setSignUpEmailOtp] = useState(false);
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState(40); // => SA || 120 seconds for 2 minutes, this might differ in accordance per time prescribed by the sir.
    const [isDisabled, setIsDisabled] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [countryCodes, setCountryCodes] = useState(null);
    // const [countryId, setCountryId] = useState(null);
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    useEffect(() => {
        let interval = null;
        if (isDisabled) {
            interval = setInterval(() => {
                setTimer((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isDisabled]);

    useEffect(() => {
        if (timer === 0) {
            setIsDisabled(false);
        }
    }, [timer]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        setSearchTerm("");
    };

    const handleSearchInput = (e) => setSearchTerm(e.currentTarget.value);

    const togglePhone = (i) => {
        if (i) setPhoneCode(i);
    };
    const toggleCountry = (i) => {
        if (i) setCountry(i);
    };
    const toggleCurrentStep = async (event, i) => {
        if (event.key && event.key !== "Enter") return;
        if (i === 1) setCurrentStep(i);
        if (i === 2) {
            setLoading(true);
            const data = await OTPService.verifyOTP(
                "email",
                userData.email,
                userData["email-otp"],
                "signup"
            );
            setShowMessage(true);
            setMessageClass(data?.success ? "Success" : "error");
            setMessageText(data?.message);
            setLoading(false);
            if (data?.success) setCurrentStep(i);
        }
        if (i === 3) {
            setLoading(true);
            const data = await OTPService.verifyOTP(
                "phone",
                userData.phoneNumber,
                userData["phone-otp"],
                "signup"
            );
            setShowMessage(true);
            setMessageClass(data?.success ? "Success" : "error");
            setMessageText(data?.message);
            setLoading(false);
            if (data?.success) setCurrentStep(i);
        }
    };

    const toggleSignUpEmailOtp = async (event, i, requestType) => {
        if (event.key && event.key !== "Enter") return;
        if (!userData.name) {
            setShowMessage(true);
            setMessageClass("error");
            setMessageText("Enter Name to proceed!");
            return;
        }
        if (!userData.email) {
            setShowMessage(true);
            setMessageClass("error");
            setMessageText("Enter Email to proceed!");
            return;
        }
        if (
            !userData?.email?.includes("@") ||
            !userData?.email?.includes(".")
        ) {
            setLoading(true);
            setShowMessage(true);
            setMessageClass("error");
            setMessageText("Enter a valid Email!");
            setLoading(false);
        } else {
            setLoading(true);
            const data = await OTPService.requestOTP(
                "email",
                userData.email,
                requestType,
                "signup"
            );
            setShowMessage(true);
            setMessageClass(data?.success ? "Success" : "error");
            setMessageText(data?.message);
            if (requestType === "resend" && data?.success) {
                setTimer(40);
                setIsDisabled(true);
            }
            setLoading(false);
            if (data?.success) {
                if (i) setSignUpEmailOtp(i);
            }
        }
    };

    const toggleEnterPhoneOtpSignup = async (event, i, requestType) => {
        if (event.key && event.key !== "Enter") return;
        if (userData?.phoneNumber?.length !== 10) {
            setLoading(true);
            setShowMessage(true);
            setMessageClass("error");
            setMessageText("Enter a valid phone number!");
            setLoading(false);
        } else {
            setLoading(true);
            const data = await OTPService.requestOTP(
                "phone",
                userData.phoneNumber,
                requestType,
                "signup",
                userData.country
            );
            setShowMessage(true);
            setMessageClass(data?.success ? "Success" : "error");
            setMessageText(data?.message);
            if (requestType === "resend" && data?.success) {
                setTimer(40);
                setIsDisabled(true);
            }
            setLoading(false);
            if (data?.success) {
                if (i) setEnterPhoneOtpSignup(i);
            }
        }
    };

    const updateUserData = (e) =>
        setUserData({ ...userData, [e.target.name]: e.target.value });

    const updateUserDataforCountryId = (name, value) =>
        setUserData((prevUserData) => ({ ...prevUserData, [name]: value }));

    const signUpUser = async (event) => {
        if (event.key && event.key !== "Enter") return;
        setLoading(true);
        const data = await UserService.signup(userData);
        setShowMessage(true);
        setMessageClass(data?.success ? "Success" : "error");
        setMessageText(data?.message);
        setLoading(false);
        if (data?.success) {
            setTimeout(function () {
                window.location.href = "/auth";
            }, 2000);
        }
    };

    const signUpWithGoogle = async () => {
        const data = await FirebaseAuthService.signinWithGoogle();
        setUserData({
            email: data?.user?.email,
            name: data?.user?.displayName,
        });
        setCurrentStep(2);
        setShowMessage(true);
        setMessageClass(data?.success ? "Success" : "error");
        setMessageText(data?.message);
    };

    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const messageController = () => setShowMessage(!showMessage);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await UserService.getCountries();
                setCountryCodes(data?.data?.countries);
                const india = data?.data?.countries.find(
                    (country) => country.abbr === "IN"
                );
                if (india) {
                    updateUserDataforCountryId("country", india._id);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);
    return (
        <SignUpView
            currentStep={currentStep}
            countryCodes={countryCodes}
            handleSearchInput={handleSearchInput}
            searchTerm={searchTerm}
            togglePhone={togglePhone}
            toggleCountry={toggleCountry}
            toggleDropdown={toggleDropdown}
            phoneCode={phoneCode}
            country={country}
            isOpen={isOpen}
            enterPhoneOtpSignup={enterPhoneOtpSignup}
            signUpEmailOtp={signUpEmailOtp}
            toggleSignUpEmailOtp={toggleSignUpEmailOtp}
            toggleEnterPhoneOtpSignup={toggleEnterPhoneOtpSignup}
            toggleCurrentStep={toggleCurrentStep}
            updateUserData={updateUserData}
            signUpUser={signUpUser}
            showMessage={showMessage}
            messageText={messageText}
            messageClass={messageClass}
            messageController={messageController}
            signUpWithGoogle={signUpWithGoogle}
            loading={loading}
            timer={timer}
            isDisabled={isDisabled}
            passwordVisible={passwordVisible}
            togglePasswordVisibility={togglePasswordVisibility}
            updateUserDataforCountryId={updateUserDataforCountryId}
        />
    );
}

export default SignUpContainer;
