import { useState, useEffect, useContext } from "react";
import AddVoiceView from "./AddVoice.view";
import { UserService, VoiceService } from "../../services";
import { Context } from "../../context/Context";
// import { useNavigate } from "react-router-dom";

function AddVoiceContainer(props) {
    const [voices, setVoices] = useState([]);
    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [showPopUp, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState("");

    const metaBucket = process.env.REACT_APP_META_BUCKET_NAME;
    const { setUseVoice } = useContext(Context);
    // const navigate = useNavigate();
    const messageController = () => setShowMessage(!showMessage);
    const togglePopup = () => {
        setShowPopup(!showPopUp);
        setId(id);
    };
    const toggleSetVoice = (id) => {
        setUseVoice(id);
        props.changeNavOptions("Speech");
    };
    function copyTextToClipboard(textToCopy) {
        const textarea = document.createElement("textarea");
        textarea.value = textToCopy;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        setShowMessage(true);
        setMessageClass("Success");
        setMessageText("Copied successfully!!");
    }

    const deleteVoiceFunction = async () => {
        setLoading(true);
        try {
            if (!id) throw new Error("Voice ID missing!");
            const data = await VoiceService.deleteVoice(id, "voice");
            setShowMessage(true);
            setMessageClass(data.success ? "Success" : "error");
            setMessageText(data.message);
            if (data.success) {
                fetchVoices();
                togglePopup();
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    };
    const deleteSpeech = (id) => {
        setId(id);
        setShowPopup(true);
    };
    async function fetchVoices() {
        setLoading(true);
        const data = await VoiceService.fetchVoices();
        if (data.success) setVoices(data);
        setLoading(false);
    }
    const downloadSpeechFunction = async (s3ObjectKey, name) => {
        try {
            setLoading(true);
            if (!s3ObjectKey) throw new Error("S3 Object Key missing!");
            const data = await UserService.presignedURL(
                "GET",
                metaBucket,
                s3ObjectKey
            );
            handleDownload(data?.data?.presigned_url, name);
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };
    const handleDownload = (data, name) => {
        if (data) {
            fetch(data)
                .then((response) => response.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = name + ".mp3";
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch((error) => {
                    console.error("Error downloading file:", error);
                });
        }
    };
    useEffect(() => {
        fetchVoices();
    }, [props.successAddVoice]);
    return (
        <AddVoiceView
            copyTextToClipboard={copyTextToClipboard}
            addVoiceController={props.addVoiceController}
            voices={voices}
            deleteVoiceFunction={deleteVoiceFunction}
            showMessage={showMessage}
            messageText={messageText}
            messageClass={messageClass}
            messageController={messageController}
            togglePopup={togglePopup}
            showPopUp={showPopUp}
            deleteSpeech={deleteSpeech}
            downloadSpeechFunction={downloadSpeechFunction}
            toggleSetVoice={toggleSetVoice}
            loading={loading}
        />
    );
}

export default AddVoiceContainer;
