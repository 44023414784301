import { Message, Spinner } from "../";
import { sortBySearchTerm } from "../../utility/sortBySearchTerm";
function SignUpView(props) {
    return (
        <>
            {props.showMessage && (
                <Message
                    messageText={props.messageText}
                    messageClass={props.messageClass}
                    messageController={props.messageController}
                />
            )}
            <>
                <h5 className="text-left font-[600] text-[28px] leading-[34px] text-[#1C2347]">
                    Sign Up
                </h5>
                <p className="font-[300] text-[14px] text-[#444444] mb-5 mt-1">
                    Create account to explore our diverse metaverse
                </p>

                <div className="w-full bg-[#EDEDED] rounded-full h-2">
                    <div
                        className={`${props.currentStep === 1 ? "w-[33%]" : props.currentStep === 2 ? "w-[66%]" : "w-[100%]"} bg-[#489BF6] h-2 rounded-full`}
                    ></div>
                </div>
                <div className="text-[12px] leading-[16px] font-[400] flex justify-end py-2">
                    <p>
                        Step{" "}
                        <span className="text-[#489BF6]">
                            {props.currentStep + " "}
                        </span>
                        of <span>3</span>
                    </p>
                </div>
                {props.currentStep === 1 && (
                    <>
                        <div className="grid">
                            <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3">
                                Name<span className="text-red-500">*</span>
                            </label>
                            <input
                                className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-transparent border-[1px] border-[#C1C3C0] focus:outline-[#489BF6] rounded-md my-1"
                                type="text"
                                placeholder="Enter Name"
                                name="name"
                                onChange={props.updateUserData}
                                required
                            />
                            <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3">
                                Email<span className="text-red-500">*</span>
                            </label>
                            <input
                                className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-transparent border-[1px] border-[#C1C3C0] focus:outline-[#489BF6] rounded-md my-1"
                                type="email"
                                placeholder="Enter email address"
                                name="email"
                                onChange={props.updateUserData}
                                onKeyDown={(event) =>
                                    props.toggleSignUpEmailOtp(
                                        event,
                                        true,
                                        "request"
                                    )
                                }
                                required
                            />
                            {!props.signUpEmailOtp && (
                                <button
                                    onClick={(event) =>
                                        props.toggleSignUpEmailOtp(
                                            event,
                                            true,
                                            "request"
                                        )
                                    }
                                    disabled={props.loading}
                                    className="py-3 w-full my-3 font-[600] text-white transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600 flex justify-center"
                                >
                                    {props.loading ? <Spinner /> : "Get OTP"}
                                </button>
                            )}
                            {props.signUpEmailOtp && (
                                <>
                                    <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3">
                                        OTP
                                        <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                        className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px] input-number  max-md:px-2 bg-transparent border-[1px] border-[#C1C3C0] focus:outline-[#489BF6] rounded-md my-1"
                                        type="number"
                                        placeholder="Enter OTP"
                                        name="email-otp"
                                        onChange={props.updateUserData}
                                        onKeyDown={(event) =>
                                            props.toggleCurrentStep(event, 2)
                                        }
                                        required
                                    />
                                    <div className="text-[12px] font-[400] text-[#595959] leading-[16.41px] py-3">
                                        <p>
                                            Didn’t get OTP ?{" "}
                                            {!props.isDisabled ? (
                                                <span
                                                    onClick={(event) => {
                                                        props.toggleSignUpEmailOtp(
                                                            event,
                                                            true,
                                                            "resend"
                                                        );
                                                    }}
                                                    className="font-[600] text-[#489BF6] cursor-pointer"
                                                >
                                                    Resend OTP
                                                </span>
                                            ) : (
                                                <span className="font-[500]">
                                                    Resend OTP in{" "}
                                                    <span className="font-[500] text-[#489BF6]">
                                                        {props.timer}
                                                    </span>
                                                    &nbsp;secs
                                                </span>
                                            )}
                                        </p>
                                    </div>
                                    <button
                                        disabled={props.loading}
                                        onClick={(event) =>
                                            props.toggleCurrentStep(event, 2)
                                        }
                                        className="py-3 w-full my-3 font-[600] text-white transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600 flex justify-center"
                                    >
                                        {props.loading ? (
                                            <Spinner />
                                        ) : (
                                            "Verify & Next"
                                        )}
                                    </button>
                                </>
                            )}
                            {
                                <>
                                    <div
                                        id="recaptcha-container"
                                        className="px-10 max-md:px-4 flex justify-center"
                                    ></div>
                                    <div className="py-5 text-[#6B6B6B8F] font-[700]">
                                        <hr />
                                        <p className="text-center -mt-[14px]">
                                            <span className="bg-[#FFFFFF] px-2">
                                                Or
                                            </span>
                                        </p>
                                    </div>
                                    <button
                                        className="login-page-buttons"
                                        onClick={props.signUpWithGoogle}
                                    >
                                        <img
                                            src="../images/login/googlelogo.webp"
                                            alt=""
                                            className="pr-2"
                                        />
                                        Sign Up with Google
                                    </button>
                                </>
                            }
                        </div>
                    </>
                )}
                {props.currentStep === 2 && (
                    <>
                        {!props.enterPhoneOtpSignup && (
                            <div className="grid">
                                {" "}
                                <div>
                                    <div className="relative flex items-center border-[1px] border-[#C1C3C0] text-sm font-normal  rounded-lg">
                                        <div
                                            onClick={props.toggleDropdown}
                                            className="flex items-center p-4 cursor-pointer w-[120px] bg-[#489BF638] hover:bg-[#489BF6] group hover:rounded-lg"
                                        >
                                            <img
                                                src={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${props.country}.svg`}
                                                alt={props.country}
                                                className="w-[30px]"
                                            />
                                            <p className="font-[600] text-gray-700 group-hover:text-white pl-1">
                                                {props.phoneCode}
                                            </p>

                                            <i className="fa fa-angle-down pl-1 group-hover:text-white"></i>
                                        </div>
                                        {props.isOpen && (
                                            <div className="absolute z-[9999] mt-56 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 h-[150px] w-[250px] overflow-y-auto thin-scrollbar">
                                                <input
                                                    id="search-input"
                                                    className="block w-full px-4 py-2 text-gray-800 border rounded-md border-gray-300 focus:outline-none"
                                                    type="text"
                                                    placeholder="Search items"
                                                    onChange={
                                                        props.handleSearchInput
                                                    }
                                                ></input>
                                                {sortBySearchTerm(
                                                    props.countryCodes.filter(
                                                        (item) =>
                                                            item.name
                                                                .toLowerCase()
                                                                .includes(
                                                                    props.searchTerm.toLowerCase()
                                                                )
                                                    ),
                                                    props.searchTerm
                                                ).map((item, i) => (
                                                    <li
                                                        key={i}
                                                        className="flex items-center px-2 py-2 text-[12px] text-gray-700 text-center hover:bg-[#489BF6] hover:text-white font-[600] cursor-pointer rounded-md"
                                                        value={item.code}
                                                        name="countryId"
                                                        onClick={(e) => {
                                                            props.togglePhone(
                                                                "+" +
                                                                    e.currentTarget.value.toString()
                                                            );
                                                            props.updateUserDataforCountryId(
                                                                "country",
                                                                item._id
                                                            );
                                                            props.toggleCountry(
                                                                item.abbr
                                                            );
                                                            props.toggleDropdown();
                                                        }}
                                                    >
                                                        <img
                                                            src={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${item.abbr}.svg`}
                                                            alt={item.code}
                                                            className="w-[30px] pr-1"
                                                        />
                                                        {item.name}({item.code})
                                                    </li>
                                                ))}
                                            </div>
                                        )}
                                        <input
                                            className="w-full text-grey px-4 max-md:px-2 bg-transparent input-number py-4 focus:outline-none text-[16px]"
                                            type="number"
                                            placeholder="Enter phone no."
                                            name="phoneNumber"
                                            onChange={props.updateUserData}
                                            onKeyDown={(event) =>
                                                props.toggleEnterPhoneOtpSignup(
                                                    event,
                                                    true,
                                                    "request"
                                                )
                                            }
                                            required
                                        />
                                    </div>
                                </div>
                                <span className="italic text-[14px] font-[400] text-[#FF6767] mt-1">
                                    Note - Phone number with OTP is valid for
                                    India region only
                                </span>
                                {props.phoneCode === "+91" &&
                                    !props.enterPhoneOtpSignup && (
                                        <button
                                            onClick={(event) =>
                                                props.toggleEnterPhoneOtpSignup(
                                                    event,
                                                    true,
                                                    "request"
                                                )
                                            }
                                            disabled={props.loading}
                                            className="py-3 w-full my-3 font-[600] text-white transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600 flex justify-center"
                                        >
                                            {props.loading ? (
                                                <Spinner />
                                            ) : (
                                                "GET OTP"
                                            )}
                                        </button>
                                    )}
                                {props.phoneCode !== "+91" && (
                                    <>
                                        <button
                                            onClick={(event) =>
                                                props.toggleCurrentStep(
                                                    event,
                                                    3
                                                )
                                            }
                                            className="py-3 w-full my-3 font-[600] text-white transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600"
                                        >
                                            Next
                                        </button>
                                        <button
                                            onClick={(event) =>
                                                props.toggleCurrentStep(
                                                    event,
                                                    3
                                                )
                                            }
                                            className="py-3 w-full my-3 font-[600] transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 bg-transparent border-[1px] border-[#489BF6] text-[#489BF6]"
                                        >
                                            Skip
                                        </button>
                                    </>
                                )}
                            </div>
                        )}
                        {props.enterPhoneOtpSignup && (
                            <>
                                <div className="grid">
                                    <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3">
                                        OTP
                                        <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                        className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-transparent border-[1px] input-number border-[#C1C3C0] focus:outline-[#489BF6] rounded-md my-1"
                                        type="number"
                                        placeholder="Enter OTP"
                                        name="phone-otp"
                                        onChange={props.updateUserData}
                                        onKeyDown={(event) =>
                                            props.toggleCurrentStep(event, 3)
                                        }
                                        required
                                    />
                                    <div className="text-[12px] font-[400] text-[#595959] leading-[16.41px] py-3">
                                        <p>
                                            Didn’t get OTP ?{" "}
                                            {!props.isDisabled ? (
                                                <span
                                                    onClick={(event) => {
                                                        props.toggleEnterPhoneOtpSignup(
                                                            event,
                                                            true,
                                                            "resend"
                                                        );
                                                    }}
                                                    className="font-[600] text-[#489BF6] cursor-pointer"
                                                >
                                                    Resend OTP
                                                </span>
                                            ) : (
                                                <span className="font-[500]">
                                                    Resend OTP in{" "}
                                                    <span className="font-[500] text-[#489BF6]">
                                                        {props.timer}
                                                    </span>
                                                    &nbsp;secs
                                                </span>
                                            )}
                                        </p>
                                    </div>
                                    <button
                                        onClick={(event) =>
                                            props.toggleCurrentStep(event, 3)
                                        }
                                        disabled={props.loading}
                                        className="py-3 w-full my-3 font-[600] text-white transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600 flex justify-center"
                                    >
                                        {props.loading ? (
                                            <Spinner />
                                        ) : (
                                            "Verify & Login"
                                        )}
                                    </button>
                                </div>
                            </>
                        )}
                    </>
                )}
                {props.currentStep === 3 && (
                    <>
                        <div className="grid">
                            <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3">
                                Password
                            </label>
                            <div className="relative flex items-center">
                                <input
                                    className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-transparent border-[1px] input-number border-[#C1C3C0] focus:outline-[#489BF6] rounded-md my-1"
                                    type={
                                        props.passwordVisible
                                            ? "text"
                                            : "password"
                                    }
                                    placeholder="Enter Password"
                                    name="password"
                                    onChange={props.updateUserData}
                                    onKeyDown={(event) =>
                                        props.signUpUser(event)
                                    }
                                    required
                                />
                                <i
                                    className={`${
                                        props.passwordVisible
                                            ? "fa fa-eye"
                                            : "fa fa-eye-slash"
                                    } absolute right-0 px-4 py-2 cursor-pointer`}
                                    onClick={props.togglePasswordVisibility}
                                    aria-hidden="true"
                                ></i>
                            </div>
                            <button
                                onClick={(event) => props.signUpUser(event)}
                                disabled={props.loading}
                                className="py-3 w-full my-3 font-[600] text-white transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600 flex justify-center"
                            >
                                {props.loading ? (
                                    <Spinner />
                                ) : (
                                    "Save and Continue"
                                )}
                            </button>
                            <button
                                onClick={(event) => props.signUpUser(event)}
                                disabled={props.loading}
                                className="py-3 w-full my-3 font-[600] transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 bg-transparent border-[1px] border-[#489BF6] text-[#489BF6] flex justify-center"
                            >
                                {props.loading ? <Spinner /> : "Skip"}
                            </button>
                        </div>
                    </>
                )}
            </>
        </>
    );
}

export default SignUpView;
