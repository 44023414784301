import { useState } from "react";
import SubscriptionView from "./Subscription.view";
function SubscriptionContainer(props) {
    const [transactionView, setTransactionView] = useState("current plan");
    const toggleTransactionView = (i) => {
        setTransactionView(i);
    };
    return (
        <SubscriptionView
            data={data}
            toggleTransactionView={toggleTransactionView}
            transactionView={transactionView}
        />
    );
}

export default SubscriptionContainer;
const data = [
    {
        title: "Basic",
        price: "1999/-",
        features: [
            "Convert 3D models into AR model accessible link, within standard price",
            "Get Model AR link which accessible in any area",
            "Generate 10 models links",
        ],
    },
    {
        title: "Standard",
        price: "2999/-",
        features: [
            "Convert 3D models into AR model accessible link, within standard price",
            "Get Model AR link which accessible in any area",
            "Generate 50 models links",
        ],
    },
    {
        title: "Premium",
        price: "3999/-",
        features: [
            "Convert 3D models into AR model accessible link, within standard price",
            "Get Model AR link which accessible in any area",
            "Generate 100 models links",
        ],
    },
    {
        title: "Advanced",
        price: "5999/-",
        features: [
            "Convert 3D models into AR model accessible link, within standard price",
            "Get Model AR link which accessible in any area",
            "Generate Unlimited models Links",
        ],
    },
];
