import GenerateARModel from "../GenereateARModel";
import { Loader } from "..";
function ThreeViewerView(props) {
    return (
        <>
            {props.showGeneratePopup && (
                <GenerateARModel
                    toogleShowGenerate={props.toogleShowGenerate}
                    modelId={props?.modelData?._id}
                />
            )}
            <div className="overflow-hidden p-4 text-left font-['barlow']">
                <h5 className="my-4 text-[#1C2347] text-[22px] font-[600] leading-[25px]">
                    AR products
                </h5>
                <div className="bg-gradient-to-br from-[#D4ECFF54] to-[#0A528D54] p-2">
                    <div className="p-4  w-full bg-white shadow-xl rounded-md my-2 grid grid-cols-[200px_1fr]">
                        <div>
                            <h3 className="text-[#1C2347] font-[500] leading-[16px]">
                                Name
                            </h3>
                            <p className="text-[#626262] font-[400] leading-[20px] text-[14px]">
                                {props.modelData?.name}
                            </p>
                        </div>
                        <div className="flex justify-between">
                            <div>
                                <h3 className="text-[#1C2347] font-[500] leading-[16px]">
                                    Description
                                </h3>
                                <p className="text-[#626262] font-[400] leading-[20px] text-[14px] max-w-[500px]">
                                    {props.modelData?.description}
                                </p>
                            </div>
                            <button
                                onClick={props.toogleShowGenerate}
                                className={`bg-gradient-to-br from-blue-500 to-purple-700 my-2 max-md:w-full w-[180px] py-1 px-2  rounded-md text-white font-[600]`}
                            >
                                Generate AR Link
                            </button>
                        </div>
                    </div>

                    <div
                        ref={props.mountRef}
                        children
                        className="box-content relative w-full h-[500px]"
                    >
                        {props.loading && (
                            <div className="absolute z-[99] w-full flex justify-center h-[500px] items-center">
                                <div className="border-t-transparent border-solid animate-spin  rounded-full border-blue-400 border-8 h-28 w-28"></div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ThreeViewerView;
