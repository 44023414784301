import { Loader, Spinner, Message, GenerateARModel } from "..";

function ListARView(props) {
    return (
        <>
            {props.showMessage && (
                <Message
                    messageText={props.messageText}
                    messageClass={props.messageClass}
                    messageController={props.messageController}
                />
            )}
            {props.showGeneratePopup && (
                <GenerateARModel
                    toogleShowGenerate={props.toogleShowGenerate}
                    modelId={props.modelId}
                />
            )}
            {props.showPopUp && (
                <>
                    <div className="absolute left-0 top-0 w-full h-screen grid justify-items-center items-center font-['barlow']">
                        <div
                            className="absolute left-0 top-0 bg-[#00000060] z-[999] w-full h-screen"
                            data-testid="background"
                            onClick={props.togglePopup}
                        ></div>
                        <div className="text-center fixed z-[9999999999] bg-white rounded-lg p-8 w-[600px] max-[1200px]:w-[400px] max-[1200px]:p-4 max-[400px]:w-full max-md:p-2 text-center grid grid-cols-1 justify-items-center">
                            <div className="flex flex-col items-center">
                                <img
                                    alt=""
                                    src="../images/generatekeys/header.webp"
                                />
                                <h5 className="text-[#1C2347] text-[22px] font-[500] leading-[26px] my-5">
                                    Are Sure you want to delete ?
                                </h5>
                                <div>
                                    <button
                                        onClick={props.togglePopup}
                                        className="py-3 px-10 my-3 font-[600] transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 text-[#489BF6] border-[2px] border-[#489BF6]"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        disabled={props.loading}
                                        onClick={props.deleteARFunction}
                                        className={`bg-gradient-to-r from-blue-500 to-purple-600 transition duration-300 ease-in-out hover:scale-[1.05] py-3.5 px-10 my-3 font-[600] text-white  rounded-lg mr-4 hover:bg-gradient-to-l`}
                                    >
                                        {props.loading ? (
                                            <Spinner />
                                        ) : (
                                            "Confirm"
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                </>
            )}
            {props.editShowPopup && (
                <>
                    <div className="absolute left-0 top-0 w-full h-screen grid justify-items-center items-center font-['barlow']">
                        <div
                            className="absolute left-0 top-0 bg-[#00000060] z-[999] w-full h-screen"
                            data-testid="background"
                            onClick={props.toggleEditPopup}
                        ></div>
                        <div className="text-center fixed z-[9999999999] bg-white rounded-lg p-8 w-[600px] max-[1200px]:w-[400px] max-[1200px]:p-4 max-[400px]:w-full max-md:p-2 text-center grid grid-cols-1 justify-items-center">
                            <div className="flex justify-between w-full pb-4">
                                <h5 className="text-[#1C2347] font-[600] text-[24px] leading-[29px]">
                                    Update Details
                                </h5>
                                <i
                                    onClick={props.toggleEditPopup}
                                    className="fa fa-times-circle text-[32px] text-[#C7C7C7] cursor-pointer"
                                ></i>
                            </div>
                            {/* <img
                                alt=""
                                src="../images/addArProduct/listARupdate.webp"
                            /> */}
                            <div className="grid w-full text-left">
                                <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3">
                                    Name<span className="text-red-500">*</span>
                                </label>
                                <input
                                    className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-transparent border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                    type="text"
                                    name="name"
                                    value={props.arDetails?.name}
                                    onChange={props.handleInputChange}
                                    required
                                />
                            </div>
                            <div>
                                <button
                                    onClick={props.updateARFunction}
                                    className={`bg-gradient-to-r from-blue-500 to-purple-600 transition duration-300 ease-in-out hover:scale-[1.05] py-3.5 px-10 my-3 font-[600] text-white  rounded-lg mr-4 hover:bg-gradient-to-l`}
                                >
                                    {props.loading ? <Spinner /> : "Confirm"}
                                </button>
                            </div>
                        </div>
                    </div>{" "}
                </>
            )}
            <div className="text-left p-4 font-['barlow'] overflow-y-auto height-of-inner-div thin-scrollbar">
                <div className="flex justify-between items-center">
                    {/* <h5 className="my-4 text-[#1C2347] text-[22px] font-[600] leading-[25px]">
                        AR products/Added Model
                    </h5> */}
                    <div className="bg-[#489BF61A] py-4 px-6 rounded-lg flex items-center hover:scale-[1.05] cursor-pointer duration-300">
                        <p className="text-[#489BF6] font-[15px] font-[400] leading-[17.5px] ">
                            Filter
                        </p>
                        <img
                            src="../images/arproduct/filter.webp"
                            alt=""
                            className="px-2"
                        />
                    </div>
                </div>
                <div>
                    <div className="added-models-header">
                        <h5>Date & Time</h5>
                        <h5>Name</h5>
                        <h5>Product ID</h5>
                        <h5>Description</h5>
                        <h5>Link</h5>
                        <h5>Added Points</h5>
                        <h5>Actions</h5>
                    </div>
                    <div className="p-2">
                        {" "}
                        {props.loading && <Loader />}
                        {!props.loading &&
                            (props.arModel?.length > 0 ? (
                                <>
                                    {props?.arModel?.map((ar, i) => (
                                        <div
                                            className="added-models-table"
                                            key={i}
                                        >
                                            <p>{ar.createdAt}</p>
                                            <p>{ar.name}</p>
                                            <p id="textToCopy">
                                                {ar._id}{" "}
                                                <i
                                                    id="copyIcon"
                                                    className="fa fa-files-o text-[14px] cursor-pointer text-[#489BF6] font-[800]"
                                                    onClick={() =>
                                                        props.copyTextToClipboard(
                                                            ar._id
                                                        )
                                                    }
                                                ></i>
                                            </p>
                                            <p>{ar.description}</p>
                                            <div
                                                onClick={() => {
                                                    props.toogleShowGenerate();
                                                    props.modelIdFunction(
                                                        ar._id
                                                    );
                                                }}
                                            >
                                                <div className="">
                                                    <i className="fa fa-eye px-2 text-[18px]"></i>
                                                    <p>View AR</p>
                                                </div>
                                            </div>
                                            <p>3</p>
                                            <div className="">
                                                <div
                                                    className=""
                                                    onClick={() =>
                                                        props.updateARToogle({
                                                            _id: ar?._id,
                                                            name: ar?.name,
                                                            isActive: true,
                                                            s3ObjectKey:
                                                                ar?.s3ObjectKey,
                                                        })
                                                    }
                                                >
                                                    <img
                                                        src="../images/arproduct/edit.webp"
                                                        alt=""
                                                    />{" "}
                                                    <p>Edit Details</p>
                                                </div>
                                                <i
                                                    className="fa fa-trash text-[#C2C2C2] text-[24px] hover:text-red-500 cursor-pointer"
                                                    onClick={() =>
                                                        props.deleteARToogle({
                                                            _id: ar?._id,
                                                            name: ar?.name,
                                                            isActive: false,
                                                            s3ObjectKey:
                                                                ar?.s3ObjectKey,
                                                        })
                                                    }
                                                ></i>
                                            </div>
                                        </div>
                                    ))}

                                    {props?.arModel?.map((ar, i) => (
                                        <div
                                            className="mobile-view-list-ar"
                                            key={i}
                                        >
                                            <p>
                                                <span>Date & Time : </span>
                                                {ar.createdAt}
                                            </p>
                                            <p>
                                                <span>Name : </span>
                                                {ar.name}
                                            </p>
                                            <p id="textToCopy">
                                                <span>Product ID : </span>
                                                {ar._id}{" "}
                                                <i
                                                    id="copyIcon"
                                                    className="fa fa-files-o text-[14px] cursor-pointer text-[#489BF6] font-[800]"
                                                    onClick={() =>
                                                        props.copyTextToClipboard(
                                                            ar._id
                                                        )
                                                    }
                                                ></i>
                                            </p>

                                            <p>
                                                <span>Description : </span>
                                                {ar.description}
                                            </p>
                                            <p>
                                                <span>Added Points : </span>3
                                            </p>
                                            <div className="flex items-center justify-between pt-1 cursor-pointer ">
                                                <p
                                                    onClick={() => {
                                                        props.toogleShowGenerate();
                                                        props.modelIdFunction(
                                                            ar._id
                                                        );
                                                    }}
                                                    className="text-[#489BF6] bg-[#489BF626] p-2 rounded-lg flex items-center hover:scale-[1.05] duration-300"
                                                >
                                                    <i className="fa fa-eye px-1 text-[18px]"></i>
                                                    View AR
                                                </p>
                                                <div className="flex items-center">
                                                    <p
                                                        className="bg-[#489BF626] p-2 rounded-lg mx-2 cursor-pointer hover:scale-[1.05] duration-300"
                                                        onClick={() =>
                                                            props.updateARToogle(
                                                                {
                                                                    _id: ar?._id,
                                                                    name: ar?.name,
                                                                    isActive: true,
                                                                    s3ObjectKey:
                                                                        ar?.s3ObjectKey,
                                                                }
                                                            )
                                                        }
                                                    >
                                                        <span className="text-[#489BF6]">
                                                            Edit Details{" "}
                                                        </span>
                                                    </p>
                                                    <p>
                                                        <i
                                                            className="fa fa-trash text-red-500 text-[24px] hover:text-red-500 cursor-pointer hover:scale-[1.05] duration-300"
                                                            onClick={() =>
                                                                props.deleteARToogle(
                                                                    {
                                                                        _id: ar?._id,
                                                                        name: ar?.name,
                                                                        isActive: false,
                                                                        s3ObjectKey:
                                                                            ar?.s3ObjectKey,
                                                                    }
                                                                )
                                                            }
                                                        ></i>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <>
                                    <div className="font-[600] text-[#969696] text-center">
                                        ---- No data Found!!----{" "}
                                    </div>
                                </>
                            ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ListARView;
