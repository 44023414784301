import SubscriptionCard from "../SubscriptionCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1572 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1572, min: 1222 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 1222, min: 0 },
        items: 1,
    },
};
function SubscriptionView(props) {
    return (
        <>
            <div className="text-left p-4 font-['barlow'] overflow-y-auto height-of-inner-div thin-scrollbar">
                <div className="">
                    {/* <h5 className="my-4 text-[#1C2347] text-[22px] font-[600] leading-[25px]">
                        AR products/ Subscription model
                    </h5> */}
                </div>
                <div className="w-[calc(100vw-500px)] max-md:w-full">
                    <Carousel
                        swipeable={true}
                        draggable={true}
                        showDots={true}
                        responsive={responsive}
                        infinite={true}
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                    >
                        {props.data && props.data.length > 0 ? (
                            props.data.map((item, index) => (
                                <div
                                    key={index}
                                    className="bg-[#E1EFFF] rounded-md p-4 w-[350px] hover:bg-[#489BF6] text-[#595959] duration-300 hover:text-white group mx-5"
                                >
                                    <h5 className="text-[#1C2347] font-[600] text-[20px] px-2 group-hover:text-white">
                                        {item.title}
                                    </h5>
                                    <div className="flex items-end">
                                        <h5 className="text-[#1C2347] text-[44px] font-[600] px-2 group-hover:text-white">
                                            {item.price}
                                        </h5>
                                        <p className="font-[400] text-[16px] ml-4">
                                            Month
                                        </p>
                                    </div>
                                    <div className="px-2 mb-5 mt-2">
                                        {item.features.map(
                                            (feature, featureIndex) => (
                                                <p
                                                    key={featureIndex}
                                                    className="font-[400] text-[14px] py-1 flex"
                                                >
                                                    <i className="fa fa-check-circle text-[#009045] pr-2 py-1 group-hover:text-white"></i>
                                                    {feature}
                                                </p>
                                            )
                                        )}
                                    </div>
                                    <button className="py-3 my-3 w-full font-[600] transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 text-[#489BF6] border-[2px] border-[#489BF6] group-hover:border-white group-hover:text-white">
                                        Subscribe
                                    </button>
                                </div>
                            ))
                        ) : (
                            <p>No items to display</p>
                        )}
                    </Carousel>
                </div>
                <div className="mt-10 flex text-[#959596] font-[600] text-[20px] leading-[24px]">
                    <div
                        className={`${props.transactionView === "current plan" ? "text-[#489BF6] pb-1 border-b-[2px] border-[#489BF6]" : ""} mr-2 hover:text-[#489BF6] cursor-pointer`}
                        onClick={() =>
                            props.toggleTransactionView("current plan")
                        }
                    >
                        Current Plan
                    </div>
                    <div
                        className={`${props.transactionView === "transaction History" ? "text-[#489BF6] pb-1 border-b-[2px] border-[#489BF6]" : ""} hover:text-[#489BF6] cursor-pointer`}
                        onClick={() =>
                            props.toggleTransactionView("transaction History")
                        }
                    >
                        Transaction History
                    </div>
                </div>
                <div className="my-10">
                    {props.transactionView === "current plan" && (
                        <>
                            <div className="border-[1px] border-[#D6E8FD] rounded-lg grid grid-cols-[7fr_3fr] items-center px-5 py-3">
                                <div className="pb-5 pt-2">
                                    <div className="flex justify-between mt-5 items-center max-md:block">
                                        <div>
                                            <h5 className="text-[#1C2347] text-[20px] font-[500] leading-[23px]">
                                                Basic
                                            </h5>
                                            <p className="text-[#595959] font-[400] text-[14px] pt-1">
                                                Your current Plan details
                                            </p>
                                        </div>
                                        <div className="flex items-end">
                                            <h5 className="text-[#1C2347] font-[600] text-[32px] leading-[37.5px]">
                                                2999/-
                                            </h5>
                                            <p className="text-[#595959] text-[14px] font-[400] py-1 px-2">
                                                Rupees Only
                                            </p>
                                        </div>
                                        <div className="flex items-end">
                                            <h5 className="text-[#489BF6] font-[600] text-[32px] leading-[37.5px]">
                                                10
                                            </h5>
                                            <p className="text-[#595959] text-[14px] font-[400] py-1 px-2">
                                                Models Left
                                            </p>
                                        </div>
                                        <div className="flex items-end">
                                            <h5 className="text-[#1C2347] font-[600] text-[14px] leading-[16x] pr-5">
                                                Validity period
                                            </h5>
                                            <p className="text-[#595959] text-[14px] font-[400] ">
                                                31-mar-2024 - 31-Apr-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-end px-6">
                                    <p className="underline text-[16px] font-[600] text-[#489BF6] hover:scale-[1.02] cursor-pointer">
                                        Upgrade plan
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                    {props.transactionView === "transaction History" && (
                        <>
                            <div className="w-full p-4 border-[1px] border-[#D6E8FD] rounded-lg"></div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default SubscriptionView;
