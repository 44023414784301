import Message from "../Message";
import Spinner from "../Spinner";
function AddInstantVoiceView(props) {
    return (
        <>
            {props.showMessage && (
                <Message
                    messageText={props.messageText}
                    messageClass={props.messageClass}
                    messageController={props.messageController}
                />
            )}
            <div className="fixed left-0 top-0 w-full h-screen grid max-md:block justify-items-center items-center font-['barlow']">
                <div
                    className="absolute left-0 top-0 bg-[#00000060] z-[999] w-full h-screen"
                    data-testid="background"
                    onClick={() => props.addVoiceController()}
                ></div>
                <div className="text-center fixed z-[9999999999] bg-white p-8 w-[1000px] max-[1200px]:w-[700px] max-[1200px]:p-4 max-md:w-full max-md:p-2 text-center grid grid-cols-1 justify-items-center">
                    <div className="flex justify-between w-full">
                        <h5 className="text-[#1C2347] font-[600] text-[24px] leading-[29px]">
                            Add instant voice
                        </h5>
                        <i
                            onClick={() => props.addVoiceController()}
                            className="fa fa-times-circle text-[32px] text-[#C7C7C7] cursor-pointer"
                        ></i>
                    </div>
                    <div className="w-full text-left grid py-2">
                        <label className="text-[#1C2347] text-[12px] font-[500] leading-[14px]">
                            Name&nbsp;<span className="text-red-500">*</span>
                        </label>
                        <input
                            type="text"
                            placeholder="Enter name"
                            className="outline-none border-b-[1px] border-b-[#CFD3D4] py-1 text-[#ABAFB1]"
                            onChange={props.handleNameChange}
                        ></input>
                    </div>
                    <div className="flex w-full max-[1200px]:block my-2 h-[150px] max-[1200px]:h-full">
                        <div className="w-4/6 max-[1200px]:w-full mr-2 max-[1200px]:mr-0">
                            <p className="text-[#3D3C46] text-[14px] font-[500] leading-[17px] text-left">
                                Upload audio &nbsp;
                                <span className="text-red-500">*</span>
                            </p>
                            {/* <div className="border-[1px] border-[#595959] border-dashed p-2 rounded-md flex max-md:grid max-md:w-full items-center justify-between my-2 py-8 px-4 h-full">
                                <div className="flex item-center">
                                    <img
                                        src="../images/addinstantvoice/upload.webp"
                                        className="p-2"
                                        alt=""
                                    />
                                    <div className="">
                                        <p className="leading-[22px] font-[400] underline text-[#489BF6] py-1 cursor-pointer">
                                            Click to upload a file or drag and
                                            drop
                                        </p>
                                        <p className="leading-[22px] font-[400] text-[#959595] py-1">
                                            Audio or video file, up to 10 MB
                                            each
                                        </p>
                                    </div>
                                </div>
                                <p className="text-[#959595] text-[14px] font-[400]">
                                    or
                                </p>
                                <button className="text-[#3D3C46] font-[600] text-[16px] border-[1px] border-[#3D3C46] rounded-md bg-[#FFFFFF] py-2 px-10 max-md:px-2">
                                    Record audio
                                </button>
                            </div> */}

                            <div className="flex items-center justify-center w-full">
                                <label
                                    htmlFor="dropzone-file"
                                    className={`flex flex-col items-center justify-center w-full my-2 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer ${props.fileName ? `bg-[#E9F3FF]` : `bg-gray-50`}   ${props.fileName ? `` : `hover:bg-gray-100`}`}
                                >
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                        <img
                                            src={`../images/addinstantvoice/upload${props.fileName && `blue`}.webp`}
                                            className="p-2"
                                            alt=""
                                        />
                                        <p className="mb-2 text-sm text-gray-500">
                                            <span className="font-semibold">
                                                Click to upload
                                            </span>{" "}
                                            or drag and drop
                                        </p>
                                        {!props.fileName && (
                                            <>
                                                <p className="text-xs text-gray-500">
                                                    MP3 Files only
                                                </p>
                                            </>
                                        )}
                                        {props.fileName && (
                                            <p className="text-xs text-gray-500 dark:text-gray-400">
                                                {props.fileName}
                                            </p>
                                        )}
                                    </div>
                                    <input
                                        id="dropzone-file"
                                        type="file"
                                        className="hidden"
                                        accept=".mp3"
                                        onChange={props.handleFileChange}
                                    />
                                </label>
                            </div>
                        </div>
                        {/* <div className="px-0.5 w-2/6 max-[1200px]:w-full max-[1200px]:my-2">
                            <p className="text-[#3D3C46] text-[14px] font-[500] leading-[17px] text-left">
                                Auido uploaded 0/25
                            </p>
                            <div className="bg-[#F9F9F9] border-[1px] border-[#C5C6C5] w-full my-2 rounded-md h-full overflow-y-scroll thin-scrollbar">
                                <div className="p-4 grid justify-items-center">
                                    <img
                                        src="../images/addinstantvoice/filenotuploaded.webp"
                                        className="my-2"
                                        alt=""
                                    />
                                    <p className="text-[#959595] text-[14px] leading-[20px] font-[400]">
                                        No items uploaded yet. Upload audio
                                        samples of the voice you would like to
                                        clone.
                                    </p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="w-full mt-6 max-[1200px]:mt-0">
                        <p className="text-[#3D3C46] text-[14px] font-[500] leading-[17px] text-left">
                            Description&nbsp;
                            <span className="text-red-500">*</span>
                        </p>
                        <textarea
                            id="message"
                            rows="4"
                            className="block p-2.5 w-full text-[14px] text-[#959595] bg-white rounded-lg border-[1px] border-[#C1C3C0] outline-0 focus:border-[#489BF6] my-2"
                            placeholder="Input your text in this field, the model performs optimally with longer segments."
                            onChange={props.handleDescriptionChange}
                        ></textarea>
                    </div>
                    <div className="w-full flex items-start my-4">
                        <input
                            type="checkbox"
                            id="custom-checkbox"
                            className="my-1"
                            checked={props.isChecked}
                            onChange={props.handleCheckboxChange}
                        />
                        <label
                            htmlFor="custom-checkbox"
                            className={`${props.errorMessage ? "text-red-500 font-[600] duration-300 transition" : ""} cursor-pointer text-[#595959] font-[400] text-[14px]`}
                        >
                            I hereby confirm that I have all necessary rights or
                            consents to upload and clone these voice samples and
                            that I will not use the platform-generated content
                            for any illegal, fraudulent, or harmful purpose. I
                            reaffirm my obligation to abide by Invinciblemeta.ai
                            &nbsp;
                            <a
                                href="https://invincibleocean.com/terms-cancellation-policy/"
                                target="_blank"
                            >
                                <span className="underline">
                                    Terms of Service
                                </span>
                            </a>
                            &nbsp;and &nbsp;
                            <a
                                href="https://invincibleocean.com/privacy-policy/"
                                target="_blank"
                            >
                                <span className="underline">
                                    Privacy Policy.
                                </span>
                            </a>
                        </label>
                    </div>
                    <div className="flex w-full">
                        <button
                            disabled={
                                !props.s3Data ||
                                props.loading ||
                                !props.description ||
                                !props.name
                            }
                            onClick={props.addVoiceFunction}
                            className={`${
                                !props.s3Data ||
                                !props.description ||
                                !props.name ||
                                props.loading
                                    ? "cursor-not-allowed"
                                    : "hover:bg-gradient-to-t hover:scale-[1.05] duration-300"
                            } bg-gradient-to-br flex justify-center from-blue-500 to-purple-700 max-md:w-full w-[180px] py-2.5 rounded-md text-white font-[600]`}
                        >
                            {props.loading ? <Spinner /> : "Add"}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddInstantVoiceView;
