import { Link } from "react-router-dom";
function SidebarView(props) {
    return (
        <>
            <div className="font-['barlow'] font-[500]">
                <div className="px-5 h-screen overflow-auto custom-scrollbar grid grid-rows-[120px_1fr_140px] pb-5">
                    <div className="flex text-white items-center max-md:h-16">
                        <img
                            src="../images/invincible.webp"
                            alt=""
                            className="pl-4"
                        />
                    </div>
                    <div className="text-white">
                        <div className="">
                            <Link to="/">
                                <div
                                    className={`${props.active === "/" ? `bg-[#489BF6]` : ``}  text-white flex text-white my-1 hover:bg-[#489bf6] rounded-lg pl-4 p-3 group cursor-pointer`}
                                >
                                    <img
                                        src="../images/dashboard-sidebar/dashboard-icon.svg"
                                        alt=""
                                        className="pr-5 max-md:pr-2"
                                    />

                                    <p className="text-[18px] text-left leading-[18.75px] group-hover:text-white">
                                        Dashboard
                                    </p>
                                </div>
                            </Link>
                            <p className="text-[13px] text-[#969696] text-left py-5 pl-4">
                                API
                            </p>

                            <div
                                className={`${props.active === "/api-documentation" ? `bg-[#489BF6]` : ``} flex text-white my-1 hover:bg-[#489bf6] rounded-lg pl-4 p-3 group cursor-pointer`}
                            >
                                <img
                                    src="../images/dashboard-sidebar/api-documents.svg"
                                    alt=""
                                    className="pr-5 max-md:pr-2"
                                />{" "}
                                <p className="text-[18px] text-left leading-[18.75px] group-hover:text-white ">
                                    APIs documentation
                                </p>
                            </div>
                            <Link to="/api-keys">
                                <div
                                    className={`${props.active === "/api-keys" ? `bg-[#489BF6]` : ``} flex text-white my-1 hover:bg-[#489bf6] rounded-lg pl-4 p-3 group cursor-pointer`}
                                >
                                    <img
                                        src="../images/dashboard-sidebar/api-keys.svg"
                                        alt=""
                                        className="pr-5 max-md:pr-2"
                                    />{" "}
                                    <p className="text-[18px] text-left leading-[18.75px] group-hover:text-white">
                                        APIs Keys
                                    </p>
                                </div>
                            </Link>
                            {/* <Link to="/host-keys">
                                <div
                                    className={`${props.active === "/host-keys" ? `bg-[#489BF6]` : ``} flex text-white my-1 hover:bg-[#489bf6] rounded-lg pl-4 p-3 group cursor-pointer`}
                                >
                                    <img
                                        src="../images/dashboard-sidebar/hostkeys.svg"
                                        alt=""
                                        className="pr-5 max-md:pr-2"
                                    />{" "}
                                    <p className="text-[18px] text-left leading-[18.75px] group-hover:text-white">
                                        Host Keys
                                    </p>
                                </div>
                            </Link> */}
                            {/* <div
                                className={`${props.active === "/api-pricing" ? `bg-[#489BF6]` : ``} flex text-white my-1 hover:bg-[#489bf6] rounded-lg pl-4 p-3 group cursor-pointer`}
                            >
                                <img
                                    src="../images/dashboard-sidebar/apipricing.webp"
                                    alt=""
                                    className="pr-5 max-md:pr-2"
                                />{" "}
                                <p className="text-[18px] text-left leading-[18.75px] group-hover:text-white">
                                    APIs pricing
                                </p>
                            </div> */}
                            {/* <div
                                className={`${props.active === "/api-usage" ? `bg-[#489BF6]` : ``} flex text-white my-1 hover:bg-[#489bf6] rounded-lg pl-4 p-3 group cursor-pointer`}
                            >
                                <img
                                    src="../images/dashboard-sidebar/apiusage.webp"
                                    alt=""
                                    className="pr-5 max-md:pr-2"
                                />{" "}
                                <p className="text-[18px] text-left leading-[18.75px] group-hover:text-white">
                                    APIs Usage
                                </p>
                            </div> */}
                            <p className="text-[13px] text-[#969696] text-left py-5 pl-4">
                                Product Menu
                            </p>
                            <Link to="/ai-voice-cloning">
                                <div
                                    className={`${props.active === "/ai-voice-cloning" ? `bg-[#489BF6]` : ``} flex text-white my-1 hover:bg-[#489bf6] rounded-lg pl-4 p-3 group cursor-pointer`}
                                    onClick={props.toggleDropDown}
                                >
                                    <img
                                        src="../images/dashboard-sidebar/ai-voice-clonning.svg"
                                        alt=""
                                        className="pr-5 max-md:pr-2"
                                    />{" "}
                                    <div className="flex justify-between w-full">
                                        <p className="text-[18px] text-left leading-[18.75px] group-hover:text-white">
                                            AI Voice Cloning
                                        </p>

                                        <i
                                            className={`${
                                                props.showDropdown &&
                                                document.location.pathname ===
                                                    "/ai-voice-cloning"
                                                    ? `fa fa-angle-up`
                                                    : `fa fa-angle-down`
                                            } pl-2`}
                                        ></i>
                                    </div>
                                </div>
                            </Link>
                            {props.showDropdown && (
                                <>
                                    {document.location.pathname ===
                                        "/ai-voice-cloning" && (
                                        <div className="flex text-white flex-col text-left my-1 text-[14px] leading-[18px] bg-[#f5f5f51a] font-[500] rounded-md">
                                            <p
                                                className={`${props.navOptions === "Speech" ? `text-[#489BF6] bg-[#f5f5f51a] ` : `text-[#FFFFFF]`}  pl-12 py-2.5 my-0.5 rounded-md cursor-pointer hover:text-[#489BF6] hover:bg-[#f5f5f51a]`}
                                                onClick={() =>
                                                    props.changeNavOptions(
                                                        "Speech"
                                                    )
                                                }
                                            >
                                                Create Audio
                                            </p>
                                            <p
                                                className={`${props.navOptions === "ClonedVoice" ? `text-[#489BF6] bg-[#f5f5f51a] ` : `text-[#FFFFFF]`}  pl-12 py-2.5 my-0.5 rounded-md cursor-pointer hover:text-[#489BF6] hover:bg-[#f5f5f51a]`}
                                                onClick={() =>
                                                    props.changeNavOptions(
                                                        "ClonedVoice"
                                                    )
                                                }
                                            >
                                                Audio Files
                                            </p>
                                            <p
                                                className={`${props.navOptions === "AddVoice" ? `text-[#489BF6] bg-[#f5f5f51a] ` : `text-[#FFFFFF]`}  pl-12 py-2.5 my-0.5 rounded-md cursor-pointer hover:text-[#489BF6] hover:bg-[#f5f5f51a]`}
                                                onClick={() =>
                                                    props.changeNavOptions(
                                                        "AddVoice"
                                                    )
                                                }
                                            >
                                                Clone voice
                                            </p>
                                            <p
                                                className={`${props.navOptions === "Charges" ? `text-[#489BF6] bg-[#f5f5f51a] ` : `text-[#FFFFFF]`}  pl-12 py-2.5 my-0.5 rounded-md cursor-pointer hover:text-[#489BF6] hover:bg-[#f5f5f51a]`}
                                                onClick={() =>
                                                    props.changeNavOptions(
                                                        "Charges"
                                                    )
                                                }
                                            >
                                                Charges
                                            </p>
                                        </div>
                                    )}
                                </>
                            )}
                            <Link to="/ar-products">
                                <div
                                    className={`${props.active === "/ar-products" ? `bg-[#489BF6]` : ``} flex text-white my-1 hover:bg-[#489bf6] rounded-lg pl-4 p-3 group cursor-pointer`}
                                    onClick={props.toggleDropDown}
                                >
                                    <img
                                        src="../images/dashboard-sidebar/ar-products.svg"
                                        alt=""
                                        className="pr-5 max-md:pr-2"
                                    />{" "}
                                    <div className="flex justify-between w-full">
                                        <p className="text-[16px] text-left leading-[18.75px] group-hover:text-white">
                                            AR Products
                                        </p>

                                        <i
                                            className={`${
                                                props.showDropdown &&
                                                document.location.pathname ===
                                                    "/ar-products"
                                                    ? `fa fa-angle-up`
                                                    : `fa fa-angle-down`
                                            } pl-2`}
                                        ></i>
                                    </div>
                                </div>
                            </Link>
                            {props.showDropdown && (
                                <>
                                    {document.location.pathname ===
                                        "/ar-products" && (
                                        <div className="flex text-white flex-col text-left my-1 text-[14px] bg-[#f5f5f51a] rounded-md leading-[14px] font-[500]">
                                            <p
                                                onClick={() =>
                                                    props.changeNavOptions(
                                                        "Create AR"
                                                    )
                                                }
                                                className={`${props.navOptions === "Create AR" ? `text-[#489BF6] bg-[#f5f5f51a]` : `text-[#FFFFFF]`}  pl-14 py-2.5 my-0.5 rounded-md  cursor-pointer hover:text-[#489BF6] hover:bg-[#f5f5f51a]`}
                                            >
                                                Create AR
                                            </p>
                                            <p
                                                onClick={() =>
                                                    props.changeNavOptions(
                                                        "HostKeys"
                                                    )
                                                }
                                                className={`${props.navOptions === "HostKeys" ? `text-[#489BF6] bg-[#f5f5f51a]` : `text-[#FFFFFF]`}  pl-14 py-2.5 my-0.5 rounded-md  cursor-pointer hover:text-[#489BF6] hover:bg-[#f5f5f529]`}
                                            >
                                                Host Keys
                                            </p>
                                            <p
                                                onClick={() =>
                                                    props.changeNavOptions(
                                                        "List AR"
                                                    )
                                                }
                                                className={`${props.navOptions === "List AR" ? `text-[#489BF6] bg-[#f5f5f51a]` : `text-[#FFFFFF]`} pl-14 py-2.5 my-0.5 rounded-md  cursor-pointer hover:text-[#489BF6] hover:bg-[#f5f5f51a]`}
                                            >
                                                List of AR Links
                                            </p>
                                            <p
                                                onClick={() =>
                                                    props.changeNavOptions(
                                                        "Subscription"
                                                    )
                                                }
                                                className={`${props.navOptions === "Subscription" ? `text-[#489BF6] bg-[#f5f5f51a]` : `text-[#FFFFFF]`} pl-14 py-2.5 my-0.5 rounded-md  cursor-pointer hover:text-[#489BF6] hover:bg-[#f5f5f51a]`}
                                            >
                                                Subscription
                                            </p>
                                        </div>
                                    )}
                                </>
                            )}
                            <p className="text-[13px] text-[#969696] text-left py-5 pl-4">
                                Support
                            </p>
                            <Link to="/my-account">
                                <div
                                    className={`${props.active === "/my-account" ? `bg-[#489BF6]` : ``} flex text-white my-1 hover:bg-[#489bf6] rounded-lg pl-4 p-3 group cursor-pointer`}
                                >
                                    <img
                                        src="../images/dashboard-sidebar/my-account.svg"
                                        alt=""
                                        className="pr-5 max-md:pr-2"
                                    />{" "}
                                    <p className="text-[18px] text-left leading-[18.75px] group-hover:text-white">
                                        My Account
                                    </p>
                                </div>
                            </Link>
                            {/* <div
                                className={`${props.active === "/faq" ? `bg-[#489BF6]` : ``} flex text-white my-1 hover:bg-[#489bf6] rounded-lg pl-4 p-3 group cursor-pointer`}
                            >
                                <img
                                    src="../images/dashboard-sidebar/faq.webp"
                                    alt=""
                                    className="pr-5 max-md:pr-2"
                                />{" "}
                                <p className=" text-[18px] text-left leading-[18.75px] group-hover:text-white">
                                    FAQs
                                </p>
                            </div> */}
                            <div
                                className={` flex text-white my-1 hover:bg-[#489bf6] rounded-lg pl-4 p-3 group cursor-pointer`}
                                onClick={props.logoutFuntion}
                            >
                                <img
                                    src="../images/dashboard-sidebar/logout.svg"
                                    alt=""
                                    className="pr-5 max-md:pr-2"
                                />{" "}
                                <p className=" text-[18px] text-left leading-[18.75px] group-hover:text-white">
                                    Logout
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="grid items-end">
                        <div className="bg-gradient-to-b from-blue-500 to-blue-900 text-center font-['barlow'] rounded-md mb-2 flex text-white flex-col items-center justify-end h-[100px]">
                            <div className="rounded-full bg-white w-[70px] h-[70px] flex text-white flex-col items-center justify-center border-[8px] -mt-54 border-[#1C2347]">
                                <img
                                    src="../images/dashboard-sidebar/book-a-demo.webp"
                                    className="w-[50px] h-[50px]"
                                    alt=""
                                />
                            </div>
                            <p className="text-sm font-semibold p-5 underline">
                                Book a Demo Call{" "}
                                <i
                                    className="fa fa-external-link-square pl-2"
                                    aria-hidden="true"
                                ></i>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SidebarView;
