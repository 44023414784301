import Message from "../Message";
import { QRCode } from "react-qrcode-logo";
import { Link } from "react-router-dom";
import Spinner from "../Spinner";
function GenerateARModelView(props) {
    return (
        <>
            {props.showMessage && (
                <Message
                    messageText={props.messageText}
                    messageClass={props.messageClass}
                    messageController={props.messageController}
                />
            )}
            <div className="absolute left-0 top-0 w-full h-screen grid justify-items-center items-center font-['barlow']">
                <div
                    className="absolute left-0 top-0 bg-[#00000060] z-[999] w-full h-screen"
                    data-testid="background"
                    onClick={() => props.toogleShowGenerate()}
                ></div>

                <div className="text-center fixed rounded-lg z-[9999999999] bg-white p-8 w-[1000px] max-[1200px]:w-[700px] max-[1200px]:p-4 max-md:w-full max-md:p-2 text-center grid grid-cols-1 justify-items-center">
                    <div className="bg-[#FFFFFF] flex py-4 justify-between items-center w-full px-4">
                        <h5 className="text-[#1C2347] text-[22px] font-[600]">
                            Generate AR
                        </h5>
                        <div className="flex items-center">
                            <i
                                className="fa fa-times-circle text-[32px] text-[#C7C7C7] cursor-pointer"
                                onClick={() => props.toogleShowGenerate()}
                            ></i>
                        </div>
                    </div>
                    {props.fetchLoading ? (
                        <div className="border-gray-300 h-[100px] w-[100px] animate-spin rounded-full border-4 border-t-yellow-500" />
                    ) : !props.generation ? (
                        <>
                            {props.apiKeys.length > 0 ? (
                                <>
                                    <div className="w-full py-5">
                                        <h5 className="text-[#489BF6] font-[600] text-[18px]">
                                            Please Select the Host Name !!
                                            <span className="text-red-500 pl-1">
                                                *
                                            </span>
                                        </h5>
                                    </div>
                                    <select
                                        className="flex justify-between text-[#595959] my-5 border-[1px] border-[#1C2347] hover:bg-[#489BF636] w-full focus:ring-[1px] focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-4 items-center"
                                        onChange={props.handleChangeHost}
                                    >
                                        {props.apiKeys.map((host, i) => (
                                            <option
                                                value={host?.key}
                                                className="block px-4 py-2 hover:bg-[#489BF636] hover:cursor-pointer"
                                                key={i}
                                            >
                                                {host?.hostName}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="text-left w-full">
                                        <p className="text-[12px]">
                                            <span className="text-red-500">
                                                *
                                            </span>
                                            Note: The AR Model would be working
                                            on this HostName
                                        </p>
                                    </div>
                                    <button
                                        onClick={props.toggleGeneration}
                                        className="bg-gradient-to-br my-5 from-blue-500 to-purple-700 hover:bg-gradient-to-t hover:scale-[1.05] duration-300 max-md:w-full w-[180px] py-2.5 rounded-md text-white font-[600]"
                                    >
                                        Proceed
                                    </button>
                                </>
                            ) : (
                                <>
                                    <div className="text-center bg-white rounded-lg p-8 w-[600px] max-[1200px]:w-[400px] max-[1200px]:p-2 max-[400px]:w-full max-md:p-2 text-center grid grid-cols-1 justify-items-center">
                                        <div className="flex flex-col items-center">
                                            <img
                                                alt=""
                                                className="w-4/6"
                                                src="../images/generatekeys/header-hostkey.webp"
                                            />
                                            <h5 className="text-[#1C2347] text-[22px] font-[500] leading-[26px] my-5">
                                                You dont have a Host Key!
                                            </h5>
                                            <div>
                                                <Link to="/host-keys">
                                                    <div className="py-3 px-10 my-3 font-[600] transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 text-[#489BF6] border-[2px] border-[#489BF6]">
                                                        Create Host Key
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    ) : (
                        props.generation === true && (
                            <>
                                <div className="w-full py-5">
                                    <h5 className="text-[#489BF6] font-[600] text-[18px]">
                                        Customized AR Model Link
                                    </h5>
                                </div>
                                <div className="w-full">
                                    <div className="flex justify-items-center items-center">
                                        <input
                                            disabled
                                            className="w-full  border border-gray-400 rounded-md p-4 overflow-hidden"
                                            value={`https://view-ar-invincibleocean.vercel.app/view-ar?hostKey=${props.selectedHost}&modelId=${props.modelId}`}
                                        />
                                        <i
                                            id="copyIcon"
                                            className="fa fa-files-o text-[14px] cursor-pointer text-[#489BF6] font-[800] px-2"
                                            onClick={() =>
                                                props.copyTextToClipboard(
                                                    `https://view-ar-invincibleocean.vercel.app/view-ar?hostKey=${props.selectedHost}&modelId=${props.modelId}`
                                                )
                                            }
                                        ></i>
                                    </div>
                                    <div className="w-full pt-5 pb-2">
                                        <h5 className="font-[600] text-[18px] text-[#1C2347] leading-[15px]">
                                            Scan this QR Code
                                        </h5>
                                        <p className="text-[12px] text-[#489BF6]">
                                            To View AR Model on your device
                                        </p>
                                    </div>
                                    <div className="w-full flex justify-center">
                                        <QRCode
                                            value={`https://view-ar-invincibleocean.vercel.app/view-ar?hostKey=${props.selectedHost}&modelId=${props.modelId}`}
                                            // enableCORS={true}
                                            logoImage={
                                                "../images/logoshort.webp"
                                            }
                                            logoWidth={"50"}
                                            logoHeight={"50"}
                                            ecLevel={"L"}
                                            eyeColor={"#489BF6"}
                                            qrStyle={"dots"}
                                            logoOpacity={"0.7"}
                                            // eyeColor={[
                                            //     {
                                            //         // top/left eye
                                            //         outer: "yellow",
                                            //         inner: "black",
                                            //     },
                                            //     "blue", // top/right eye
                                            //     "#ffc0cb", // bottom/left eye}}
                                        />
                                    </div>
                                </div>
                            </>
                        )
                    )}
                </div>
            </div>
        </>
    );
}

export default GenerateARModelView;
