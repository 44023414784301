import axios from "axios";

const OTPService = {
    requestOTP: async (
        type,
        destination,
        requestType,
        otpFor,
        country = ""
    ) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/user/otp/${type}/${requestType}`,
                {
                    destination,
                    otpFor,
                    country,
                }
            );
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
    verifyOTP: async (type, destination, otp, otpFor = "") => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/user/otp/${type}/verify`,
                {
                    destination,
                    otp,
                    otpFor,
                }
            );
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
};

export default OTPService;
