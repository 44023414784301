import { useState, useEffect } from "react";
import ForgotPasswordView from "./ForgotPassword.view";
import { OTPService, UserService } from "../../services";
export default function ForgotPasswordContainer(props) {
    const [verificationMode, setVerificationMode] = useState("email");
    const [destination, setDestination] = useState("");
    const [otp, setOtp] = useState("");
    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState(40); // => SA || 120 seconds for 2 minutes, this might differ in accordance per time prescribed by the sir.
    const [isDisabled, setIsDisabled] = useState(false);
    const [enteredOTP, setEnteredOTP] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };
    const messageController = () => setShowMessage(!showMessage);

    const handleModeChange = (e) => {
        const mode = e.target.value;
        setVerificationMode(mode);
    };
    useEffect(() => {
        let interval = null;
        if (isDisabled) {
            interval = setInterval(() => {
                setTimer((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isDisabled]);

    useEffect(() => {
        if (timer === 0) {
            setIsDisabled(false);
        }
    }, [timer]);
    const handleInputChange = (e) => {
        if (e.target.name === "phone" || e.target.name === "email")
            setDestination(e.target.value);
        if (e.target.name === "otp") setOtp(e.target.value);
        if (e.target.name === "password") setPassword(e.target.value);
    };
    const toggleEnterOtp = async (event, i, requestType) => {
        if (event.key && event.key !== "Enter") return;
        let data;
        setLoading(true);
        if (verificationMode === "phone" && destination?.length !== 10) {
            setShowMessage(true);
            setMessageClass("error");
            setMessageText("Enter a valid phone number!");
            setLoading(false);
            return;
        }
        if (
            verificationMode === "email" &&
            (!destination?.includes("@") || !destination?.includes("."))
        ) {
            setShowMessage(true);
            setMessageClass("error");
            setMessageText("Enter a valid Email!");
            setLoading(false);
            return;
        } else {
            data = await OTPService.requestOTP(
                verificationMode,
                destination,
                requestType,
                "signIn"
            );
            setShowMessage(true);
            setMessageClass(data?.success ? "Success" : "error");
            setMessageText(data?.message);
            if (requestType === "resend" && data?.success) {
                setTimer(40);
                setIsDisabled(true);
            }
            if (data.success) {
                setEnteredOTP(i);
            }
        }
        setLoading(false);
    };
    const forgotPasswordFuntion = async (authType) => {
        setLoading(true);
        try {
            if (!verificationMode || !destination) {
                setShowMessage(true);
                setMessageClass("error");
                setMessageText(`Please enter input details to proceed!`);
                throw new Error("Details missing!");
            }
            if (
                (verificationMode === "email" &&
                    (!destination?.includes("@") ||
                        !destination?.includes("."))) ||
                (verificationMode === "phone" && destination?.length !== 10)
            ) {
                setShowMessage(true);
                setMessageClass("error");
                setMessageText(`Enter a valid ${verificationMode}!`);
                throw new Error(`Enter a Valid ${verificationMode}!`);
            }
            const data = await UserService.forgotPassword(
                verificationMode,
                destination,
                otp,
                password,
                confirmPassword,
                authType
            );
            setShowMessage(true);
            setMessageClass(data.success ? "Success" : "error");
            setMessageText(data.message);
            if (authType === "request" && data.success) {
                setEnteredOTP(true);
            }
            if (authType === "verify" && data.success) {
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }
            setLoading(false);
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    };
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setPasswordMatch(e.target.value === password);
    };
    return (
        <>
            <ForgotPasswordView
                verificationMode={verificationMode}
                handleModeChange={handleModeChange}
                handleInputChange={handleInputChange}
                toggleEnterOtp={toggleEnterOtp}
                enteredOTP={enteredOTP}
                showMessage={showMessage}
                messageText={messageText}
                messageClass={messageClass}
                messageController={messageController}
                loading={loading}
                isDisabled={isDisabled}
                timer={timer}
                password={password}
                confirmPassword={confirmPassword}
                passwordMatch={passwordMatch}
                handleConfirmPasswordChange={handleConfirmPasswordChange}
                otp={otp}
                forgotPasswordFuntion={forgotPasswordFuntion}
                passwordVisible={passwordVisible}
                confirmPasswordVisible={confirmPasswordVisible}
                togglePasswordVisibility={togglePasswordVisibility}
                toggleConfirmPasswordVisibility={
                    toggleConfirmPasswordVisibility
                }
            />
        </>
    );
}
