import CaptureImage from "../CaptureImage";
import CaptureVideo from "../CaptureVideo";
import UploadFile from "../UploadFile";
import Message from "../Message";
// import { DndProvider } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
function UserKYCView(props) {
    return (
        <>
            {props.showMessage && (
                <Message
                    messageText={props.messageText}
                    messageClass={props.messageClass}
                    messageController={props.messageController}
                />
            )}
            <div className="absolute left-0 top-0 w-full h-screen grid max-md:block justify-items-center items-center font-['barlow']">
                <div
                    className="absolute left-0 top-0 bg-[#00000060] z-[999] w-full h-screen"
                    data-testid="background"
                    onClick={() => props.popupController()}
                ></div>
                <div className="text-center fixed z-[9999999999] bg-[#F5F5F5]  w-[1000px] max-[1200px]:w-[700px] max-md:w-full  text-center grid grid-cols-1 justify-items-center">
                    <div className="bg-[#FFFFFF] flex py-4 justify-between items-center w-full px-4">
                        <h5 className="text-[#1C2347] text-[22px] font-[600]">
                            User KYC
                        </h5>
                        <div className="flex items-center">
                            <select className="border-[1px] border-[#C4C4C4DB] bg-[#F8F8F8] px-4 py-2 mr-2  rounded-md outline-none focus:border-[#489BF6] duration-300">
                                <option>English</option>
                                <option>Hindi</option>
                                <option>Japanese</option>
                            </select>
                            <i
                                className="fa fa-times-circle text-[32px] text-[#C7C7C7] cursor-pointer"
                                onClick={() => props.popupController()}
                            ></i>
                        </div>
                    </div>
                    <div className="w-full">
                        {/* {props.user?.kycSteps?.length === 0 && (
                            <>
                                <div className="">
                                    <h5 className="text-[#1C2347] text-[18px] font-[500] text-center my-8">
                                        Select Country
                                    </h5>
                                    <div className="px-10 my-5">
                                        <div className="relative flex items-center border-[1px] border-[#C1C3C0] text-sm font-normal rounded-lg">
                                            <div
                                                onClick={props.toggleDropdown}
                                                className="flex items-center p-2 w-full justify-between cursor-pointer bg-[#489BF638] hover:bg-[#489BF6] group hover:rounded-lg"
                                            >
                                                <div className="flex items-center">
                                                    <img
                                                        src={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${props.country}.svg`}
                                                        alt={props.country}
                                                        className="w-[40px]"
                                                    />
                                                    <p className="font-[600] text-gray-700 group-hover:text-white pl-1 text-[18px]">
                                                        {props.countryName}
                                                    </p>
                                                </div>

                                                <i className="fa fa-angle-down pl-1 group-hover:text-white"></i>
                                            </div>
                                            {props.isOpen && (
                                                <div className="absolute z-[9999] mt-56 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 h-[150px] w-full overflow-y-auto thin-scrollbar">
                                                    <input
                                                        id="search-input"
                                                        className="block w-full px-4 py-2 text-gray-800 border rounded-md border-gray-300 focus:outline-none"
                                                        type="text"
                                                        placeholder="Search items"
                                                        onChange={
                                                            props.handleSearchInput
                                                        }
                                                    ></input>
                                                    {props.countryCodes.filter(
                                                        (item) =>
                                                            item.name
                                                                .toLowerCase()
                                                                .includes(
                                                                    props.searchTerm.toLowerCase()
                                                                )
                                                    ).map((item, i) => (
                                                        <li
                                                            key={i}
                                                            className="flex items-center px-2 py-2 text-[14px] text-gray-700 text-center hover:bg-[#489BF6] hover:text-white font-[600] cursor-pointer rounded-md"
                                                            value={
                                                                item.code
                                                            }
                                                            onClick={(e) => {
                                                                props.toggleCountryName(
                                                                    item.name
                                                                );
                                                                props.toggleCountry(
                                                                    item.abbr
                                                                );
                                                                props.toggleDropdown();
                                                            }}
                                                        >
                                                            <img
                                                                src={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${item.abbr}.svg`}
                                                                alt={item.abbr}
                                                                className="w-[40px] pr-1"
                                                            />
                                                            {item.name}
                                                        </li>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-36 pb-10">
                                    <button
                                        onClick={props.toggleCountrySelected}
                                        className="py-3 px-16 my-3 font-[600] text-white transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600"
                                    >
                                        Continue
                                    </button>
                                </div>
                            </>
                        )} */}
                        {props.user?.kycSteps?.length > 0 && (
                            <>
                                {/* {props.country === "IN" && (
                                    <>
                                        {props.verificationStep === 1 && (
                                            <div className="w-full text-center">
                                                <h5 className="text-[#1C2347] text-[18px] font-[500] my-5">
                                                    Help us to verify your
                                                    identity
                                                </h5>
                                                <p className="font-[500] text-[14px] leading-[19px] my-2 text-[#6B6B6B]">
                                                    Identity Verification
                                                    Consent
                                                </p>
                                                <p className="font-[400] text-[14px] leading-[21px] text-[#6B6B6B] px-16 my-5 max-md:px-2">
                                                    I declare that I am at least
                                                    16 years of age; I agree to
                                                    the collection, processing
                                                    or storage of my personal
                                                    information, including
                                                    biometric data, by
                                                    Inivincible meta for the
                                                    purpose(s) of identity
                                                    verification; that the
                                                    information I provide is
                                                    true and accurate to the
                                                    best of my knowledge; and I
                                                    shall be fully responsible
                                                    in case I provide wrong
                                                    information or any of the
                                                    documents I use are fake,
                                                    forged, counterfeit etc.
                                                </p>
                                                <div className="flex flex-col items-center">
                                                    <div className="flex justify-center text-center px-2">
                                                        <input
                                                            type="checkbox"
                                                            id="custom-checkbox"
                                                            className="my-1 mr-2"
                                                            checked={
                                                                props.isChecked
                                                            }
                                                            onChange={
                                                                props.handleCheckboxChange
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="custom-checkbox"
                                                            className={`${props.errorMessage ? "text-red-500 font-[600] duration-300 transition" : ""} cursor-pointer text-[#595959] font-[400] text-[14px]`}
                                                        >
                                                            I agree to the above
                                                            statement, and I
                                                            have read Invincible
                                                            Meta&nbsp;
                                                            <span className="underline text-[#489BF6]">
                                                                Privacy Policy
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="my-10">
                                                    <button
                                                        onClick={
                                                            props.handleButtonClick
                                                        }
                                                        className="py-3 px-16 my-3 font-[600] text-white transition duration-500 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600"
                                                    >
                                                        Continue
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                        {props.verificationStep === 2 && (
                                            <div>
                                                <h5 className="text-[#1C2347] text-[18px] font-[500] my-5">
                                                    Let’s verify your identity
                                                </h5>
                                                <p className="text-[#686768] text-[12px] font-[500] leading-[14px] my-4">
                                                    Step{" "}
                                                    {props.identifcationStep} of
                                                    2
                                                </p>
                                                <div className="flex justify-center my-5">
                                                    <div
                                                        className={`${props.identifcationStep === 1 ? "border-[#489BF6] cursor-pointer" : "border-[#0FB01F]"} flex flex-col items-center p-4 w-[300px] max-md:w-full bg-white shadow-lg rounded-lg mr-5 max-md:mr-1 border-[1px] text-[#489BF6]`}
                                                    >
                                                        <img
                                                            src="../images/userkyc/documentverification.webp"
                                                            alt=""
                                                        />
                                                        <p className="text-[#6B6B6B] text-[10px] font-[500] leading-[11px] my-2">
                                                            STEP 1
                                                        </p>
                                                        <p className="text-[14px] leading-[18px] font-[400] my-2">
                                                            Identity Document
                                                            verification
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={`${props.identifcationStep === 1 ? "" : "border-[#489BF6] cursor-pointer"} flex flex-col items-center p-4 w-[300px] max-md:w-full bg-white shadow-lg rounded-lg ml-5 max-md:ml-1 border-[1px] text-[#1C2347]`}
                                                    >
                                                        <img
                                                            src="../images/userkyc/faceverification.webp"
                                                            alt=""
                                                        />
                                                        <p className="text-[#6B6B6B] text-[10px] font-[500] leading-[11px] my-2">
                                                            STEP 2
                                                        </p>
                                                        <p className="text-[14px] leading-[18px] font-[400] my-2">
                                                            Face Verification
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="my-10">
                                                    <button
                                                        onClick={() =>
                                                            props.identifcationStep ===
                                                            1
                                                                ? props.toogleVerificationStep(
                                                                      3
                                                                  )
                                                                : props.toogleVerificationStep(
                                                                      6
                                                                  )
                                                        }
                                                        className="py-3 px-16 my-3 font-[600] text-white transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600"
                                                    >
                                                        {props.identifcationStep ===
                                                        1
                                                            ? "Continue"
                                                            : "Start verification"}
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                        {props.verificationStep === 3 && (
                                            <>
                                                <div>
                                                    <h5 className="text-[#1C2347] text-[18px] font-[500] my-5">
                                                        {props.IDType === ""
                                                            ? "Choose document would you like to use"
                                                            : `How would you like to submit your ${props.IDType} ?`}
                                                    </h5>
                                                    <div className="flex flex-col items-center my-5">
                                                        <div className="bg-white py-4 px-8 w-[400px] my-10 rounded-md">
                                                            <div
                                                                onClick={
                                                                    props.IDType ===
                                                                    ""
                                                                        ? () =>
                                                                              props.toggleIDType(
                                                                                  "National ID"
                                                                              )
                                                                        : () => {
                                                                              props.toggleIDSubmissionType(
                                                                                  "Take a Photo"
                                                                              );
                                                                              props.toogleVerificationStep(
                                                                                  4
                                                                              );
                                                                          }
                                                                }
                                                                className="flex justify-between items-center text-[#1C2347] my-3 p-2 rounded-md hover:bg-[#E9F3FF] cursor-pointer"
                                                            >
                                                                <div className="flex items-center">
                                                                    <img
                                                                        src="../images/userkyc/nationalid.webp"
                                                                        className="mr-2"
                                                                        alt=""
                                                                    />
                                                                    <p className="text-[14px] font-[400] leading-[16px]">
                                                                        {props.IDType ===
                                                                        ""
                                                                            ? "National ID"
                                                                            : "Take a Photo"}
                                                                    </p>
                                                                </div>
                                                                <i className="fa fa-caret-right"></i>
                                                            </div>
                                                            <div
                                                                onClick={
                                                                    props.IDType ===
                                                                    ""
                                                                        ? () =>
                                                                              props.toggleIDType(
                                                                                  "Driving License"
                                                                              )
                                                                        : () => {
                                                                              props.toggleIDSubmissionType(
                                                                                  "Record a Video"
                                                                              );
                                                                              props.toogleVerificationStep(
                                                                                  4
                                                                              );
                                                                          }
                                                                }
                                                                className="flex justify-between items-center text-[#1C2347] my-3 p-2 rounded-md hover:bg-[#E9F3FF] cursor-pointer"
                                                            >
                                                                <div className="flex items-center">
                                                                    <img
                                                                        src="../images/userkyc/drivinglicense.webp"
                                                                        className="mr-2"
                                                                        alt=""
                                                                    />
                                                                    <p className="text-[14px] font-[400] leading-[16px]">
                                                                        {props.IDType ===
                                                                        ""
                                                                            ? `Driving License`
                                                                            : `Record a Video`}
                                                                    </p>
                                                                </div>
                                                                <i className="fa fa-caret-right"></i>
                                                            </div>
                                                            <div
                                                                onClick={
                                                                    props.IDType ===
                                                                    ""
                                                                        ? () =>
                                                                              props.toggleIDType(
                                                                                  "Driving License"
                                                                              )
                                                                        : () => {
                                                                              props.toggleIDSubmissionType(
                                                                                  "Upload Photo/Video"
                                                                              );
                                                                              props.toogleVerificationStep(
                                                                                  4
                                                                              );
                                                                          }
                                                                }
                                                                className="flex justify-between items-center text-[#1C2347] my-3 p-2 rounded-md hover:bg-[#E9F3FF] cursor-pointer"
                                                            >
                                                                <div className="flex items-center">
                                                                    <img
                                                                        src="../images/userkyc/passport.webp"
                                                                        className="mr-2"
                                                                        alt=""
                                                                    />
                                                                    <p className="text-[14px] font-[400] leading-[16px]">
                                                                        {props.IDType ===
                                                                        ""
                                                                            ? `Passport`
                                                                            : `Upload Photo/Video`}
                                                                    </p>
                                                                </div>
                                                                <i className="fa fa-caret-right"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {props.verificationStep === 4 &&
                                            props.IDSubmissionType ===
                                                "Take a Photo" && (
                                                <CaptureImage
                                                    data={
                                                        props.captureDocumentData
                                                    }
                                                    toogleVerificationStep={() => {
                                                        props.toogleVerificationStep(
                                                            5
                                                        );
                                                    }}
                                                />
                                            )}
                                        {props.verificationStep === 4 &&
                                            props.IDSubmissionType ===
                                                "Record a Video" && (
                                                <CaptureVideo
                                                    data={
                                                        props.captureDocumentData
                                                    }
                                                    toogleVerificationStep={() => {
                                                        props.toogleVerificationStep(
                                                            5
                                                        );
                                                    }}
                                                />
                                            )}
                                        {props.verificationStep === 4 &&
                                            props.IDSubmissionType ===
                                                "Upload Photo/Video" && (
                                                <UploadFile
                                                    toogleVerificationStep={() => {
                                                        props.toogleVerificationStep(
                                                            5
                                                        );
                                                    }}
                                                />
                                            )}

                                        {props.verificationStep === 5 && (
                                            <div>
                                                <DndProvider
                                                    backend={HTML5Backend}
                                                >
                                                    <div className="flex bg-[#F1F1F1] p-2">
                                                        <div className="w-1/3 p-4 bg-white mr-2 rounded-md">
                                                            <div className="text-left">
                                                                <h5 className="text-[#4D4A4B] text-[14px] font-[500]">
                                                                    OCR Fetched
                                                                    Details
                                                                </h5>
                                                                <p className="py-2 text-[#89898A] text-[12px]">
                                                                    Drag & Drop
                                                                    fields to
                                                                    fill details
                                                                </p>
                                                            </div>
                                                            {props.ocrData.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <props.Tile
                                                                        key={
                                                                            index
                                                                        }
                                                                        item={
                                                                            item
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </div>
                                                        <div className="w-2/3 bg-white p-4 rounded-md">
                                                            <div className="space-y-4">
                                                                <div className="text-left">
                                                                    <h5 className="text-[#1C2347] text-[18px] font-[500]">
                                                                        Confirm
                                                                        your ID
                                                                        Information,
                                                                        make an
                                                                        edit, if
                                                                        needed.
                                                                    </h5>
                                                                </div>
                                                                <div className="grid grid-cols-2 gap-2">
                                                                    {Object.keys(
                                                                        props.formData
                                                                    ).map(
                                                                        (
                                                                            key
                                                                        ) => (
                                                                            <props.InputField
                                                                                key={
                                                                                    key
                                                                                }
                                                                                name={
                                                                                    key
                                                                                }
                                                                                value={
                                                                                    props
                                                                                        .formData[
                                                                                        key
                                                                                    ]
                                                                                }
                                                                                onChange={
                                                                                    props.handleChange
                                                                                }
                                                                                type={
                                                                                    key ===
                                                                                    "dob"
                                                                                        ? "date"
                                                                                        : "text"
                                                                                }
                                                                                setFieldValue={
                                                                                    props.handleDrop
                                                                                }
                                                                            />
                                                                        )
                                                                    )}
                                                                </div>
                                                                <div className="flex justify-end w-full">
                                                                    <button className="py-3 px-16 my-3 font-[600] text-white transition duration-500 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600">
                                                                        Continue
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </DndProvider>
                                            </div>
                                        )}
                                        {props.verificationStep === 6 && (
                                            <CaptureImage
                                                data={props.captureFaceData}
                                                toogleVerificationStep={() => {
                                                    props.toogleVerificationStep(
                                                        7
                                                    );
                                                }}
                                            />
                                        )}
                                        {props.verificationStep === 7 && (
                                            <div className="flex flex-col items-center my-10">
                                                <img
                                                    src="../images/userkyc/successful.webp"
                                                    alt=""
                                                    className="my-5"
                                                />
                                                <h5 className="text-[#1C2347] text-[18px] font-[500]">
                                                    Verification successful
                                                </h5>
                                                <p className="text-[#1C2347] px-36 max-md:px-5 my-5 text-[14px] font-[400]">
                                                    Invincible Meta respects
                                                    your right to access or
                                                    request the deletion of your
                                                    data. For more information,
                                                    please consult our privacy
                                                    policy or reach out to us at
                                                    <span className="text-[#489BF6]">
                                                        &nbsp;privacy@invinciblemeta.com.
                                                    </span>
                                                    "
                                                </p>
                                            </div>
                                        )}
                                    </>
                                )} */}
                                {props.country === "IN" && (
                                    <>
                                        {props.user?.kycSteps?.length === 1 && (
                                            <>
                                                {props.verificationStep ===
                                                    1 && (
                                                    <div className="w-full text-center">
                                                        <h5 className="text-[#1C2347] text-[18px] font-[500] my-5">
                                                            Help us to verify
                                                            your identity
                                                        </h5>
                                                        <p className="font-[500] text-[14px] leading-[19px] my-2 text-[#6B6B6B]">
                                                            Identity
                                                            Verification Consent
                                                        </p>
                                                        <p className="font-[400] text-[14px] leading-[21px] text-[#6B6B6B] px-16 my-5 max-md:px-2">
                                                            I declare that I am
                                                            at least 16 years of
                                                            age; I agree to the
                                                            collection,
                                                            processing or
                                                            storage of my
                                                            personal
                                                            information,
                                                            including biometric
                                                            data, by Invincible
                                                            Meta for the
                                                            purpose(s) of
                                                            identity
                                                            verification; that
                                                            the information I
                                                            provide is true and
                                                            accurate to the best
                                                            of my knowledge; and
                                                            I shall be fully
                                                            responsible in case
                                                            I provide wrong
                                                            information or any
                                                            of the documents I
                                                            use are fake,
                                                            forged, counterfeit
                                                            etc.
                                                        </p>
                                                        <div className="flex flex-col items-center">
                                                            <div className="flex justify-center text-center px-2">
                                                                <input
                                                                    type="checkbox"
                                                                    id="custom-checkbox"
                                                                    className="my-1 mr-2"
                                                                    checked={
                                                                        props.isChecked
                                                                    }
                                                                    onChange={
                                                                        props.handleCheckboxChange
                                                                    }
                                                                />
                                                                <label
                                                                    htmlFor="custom-checkbox"
                                                                    className={`${props.errorMessage ? "text-red-500 font-[600] duration-300 transition" : ""} cursor-pointer text-[#595959] font-[400] text-[14px]`}
                                                                >
                                                                    I agree to
                                                                    the above
                                                                    statement,
                                                                    and I have
                                                                    read
                                                                    Invincible
                                                                    Meta&nbsp;
                                                                    <span className="underline text-[#489BF6]">
                                                                        Privacy
                                                                        Policy
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="my-10">
                                                            <button
                                                                onClick={
                                                                    props.handleButtonClick
                                                                }
                                                                className="py-3 px-16 my-3 font-[600] text-white transition duration-500 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600"
                                                            >
                                                                Continue
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                                {props.verificationStep ===
                                                    2 && (
                                                    <div className="flex flex-col items-center">
                                                        <h5 className="text-[#1C2347] text-[18px] font-[500] my-5">
                                                            Choose KYC method
                                                        </h5>
                                                        <div className="mb-5">
                                                            <div
                                                                onClick={() => {
                                                                    props.toggleKYCMethod(
                                                                        "INDIVIDUAL"
                                                                    );
                                                                }}
                                                                className="mb-4 w-[400px] max-md:w-full mx-2 bg-white rounded-md p-4 grid grid-cols-[50px_1fr_50px] items-center cursor-pointer hover:bg-[#489BF6] group duration-300"
                                                            >
                                                                <img
                                                                    src="../images/userkyc/individualkyc.webp"
                                                                    alt=""
                                                                />
                                                                <div className="text-left">
                                                                    <h5 className="text-[#1C2347] group-hover:text-white  text-[16px] font-[500]">
                                                                        Individual
                                                                        KYC
                                                                    </h5>
                                                                    <p className="text-[#6B6B6B] group-hover:text-white  text-[12px] font-[400]">
                                                                        Enter
                                                                        PAN &
                                                                        Aadhaar
                                                                        details
                                                                    </p>
                                                                </div>
                                                                <i className="fa fa-caret-right group-hover:text-white"></i>
                                                            </div>
                                                            <div
                                                                // onClick={() => {
                                                                //     props.toggleKYCMethod(
                                                                //         "Company KYC"
                                                                //     );
                                                                // }}
                                                                className="mb-4 w-[400px] max-md:w-full mx-2 bg-white rounded-md p-4 grid grid-cols-[50px_1fr_50px] items-center cursor-pointer hover:bg-[#489BF6] group duration-300"
                                                            >
                                                                <img
                                                                    src="../images/userkyc/companykyc.webp"
                                                                    alt=""
                                                                />
                                                                <div className="text-left">
                                                                    <h5 className="text-[#1C2347] group-hover:text-white  text-[16px] font-[500]">
                                                                        Company
                                                                        KYC
                                                                    </h5>
                                                                    <p className="text-[#6B6B6B] group-hover:text-white text-[12px] font-[400]">
                                                                        Enter
                                                                        Company
                                                                        PAN, CIN
                                                                        &
                                                                        Director
                                                                        details
                                                                    </p>
                                                                </div>
                                                                <i className="fa fa-caret-right group-hover:text-white"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        {(props.user?.kycSteps?.length === 2 ||
                                            props.user?.kycSteps?.length ===
                                                3) &&
                                            props.user?.kycType ===
                                                "INDIVIDUAL" && (
                                                <>
                                                    <div className="flex flex-col items-center">
                                                        <h5 className="text-[#1C2347] text-[18px] font-[500] my-5">
                                                            Verify PAN Card &
                                                            Aadhaar Card Details
                                                        </h5>
                                                        <p className="text-[#6B6B6B] text-[14px] font-[400] leading-[16px] mb-5">
                                                            Verify&nbsp;
                                                            {props.user
                                                                ?.kycSteps
                                                                ?.length === 2
                                                                ? "PAN Number"
                                                                : "Aadhar Number"}
                                                            &nbsp;detail
                                                        </p>
                                                        <div className="grid mb-2 text-left w-[500px]">
                                                            <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3 ">
                                                                Enter&nbsp;
                                                                {props.user
                                                                    ?.kycSteps
                                                                    ?.length ===
                                                                2
                                                                    ? "PAN Number"
                                                                    : "Aadhaar Number"}
                                                            </label>
                                                            <input
                                                                className="w-full text-[#1C2347] focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-transparent border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                                                type="text"
                                                                name={
                                                                    props.user
                                                                        ?.kycSteps
                                                                        ?.length ===
                                                                    2
                                                                        ? props
                                                                              .individualVerificationType
                                                                              .pan
                                                                        : props
                                                                              .individualVerificationType
                                                                              .aadhaar
                                                                }
                                                                value={
                                                                    props.user
                                                                        ?.kycSteps
                                                                        ?.length ===
                                                                    2
                                                                        ? props.indvidualPanNumber
                                                                        : props.indvidualAadharNumber
                                                                }
                                                                onChange={
                                                                    props.handleInputChange
                                                                }
                                                                placeholder={
                                                                    props.user
                                                                        ?.kycSteps
                                                                        ?.length ===
                                                                    2
                                                                        ? "Ex. ABCTY1234D"
                                                                        : "ex. 000011112222"
                                                                }
                                                                required
                                                            />
                                                        </div>
                                                        <div className="my-10">
                                                            <button
                                                                onClick={
                                                                    props.user
                                                                        ?.kycSteps
                                                                        ?.length ===
                                                                    2
                                                                        ? props.individualPanVerification
                                                                        : props.individualAadharVerification
                                                                }
                                                                className="py-3 px-16 my-3 font-[600] text-white transition duration-500 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600"
                                                            >
                                                                Verify
                                                            </button>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        {/* {props.verificationStep === 3 &&
                                            props.KYCMethod ===
                                                "Company KYC" && (
                                                <>
                                                    {props.companyKYCStep ===
                                                        1 && (
                                                        <div>
                                                            <div className="flex flex-col items-center">
                                                                <h5 className="text-[#1C2347] text-[18px] font-[500] my-5">
                                                                    Verify
                                                                    Company PAN
                                                                    Card, CIN &
                                                                    Director
                                                                    details
                                                                </h5>
                                                                <p className="text-[#6B6B6B] text-[14px] font-[400] leading-[16px] mb-5">
                                                                    Verify&nbsp;
                                                                    {
                                                                        props.companykycVerificationStep
                                                                    }
                                                                    &nbsp;detail
                                                                </p>
                                                                <div className="grid mb-2 text-left w-[500px]">
                                                                    <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3">
                                                                        Enter&nbsp;
                                                                        {props.companykycVerificationStep ===
                                                                        "Pan Card"
                                                                            ? "PAN Number"
                                                                            : "CIN Number"}
                                                                    </label>
                                                                    <input
                                                                        className="w-full text-[#1C2347] focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-transparent border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                                                        type="text"
                                                                        name="text"
                                                                        placeholder={
                                                                            "Ex. cYNOD8999A"
                                                                        }
                                                                        required
                                                                    />
                                                                </div>
                                                                <div className="my-10">
                                                                    <button
                                                                        onClick={
                                                                            props.companykycVerificationStep ===
                                                                            "Pan Card"
                                                                                ? props.toggleCompanykycVerificationStep
                                                                                : () =>
                                                                                      props.togglecomapnyKYCStep(
                                                                                          2
                                                                                      )
                                                                        }
                                                                        className="py-3 px-16 my-3 font-[600] text-white transition duration-500 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600"
                                                                    >
                                                                        Verify
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {props.companyKYCStep ===
                                                        2 && (
                                                        <div className="flex flex-col items-center">
                                                            <h5 className="text-[#1C2347] text-[18px] font-[500] my-5">
                                                                Details fetched
                                                                from CIN Number
                                                            </h5>
                                                            <div className="w-[500px] h-[300px] bg-white thin-scrollbar p-2 overflow-y-scroll my-2 text-left">
                                                                <div className="grid grid-cols-2">
                                                                    <div className="grid mr-2">
                                                                        <label className="text-[#444444] text-[12px] font-[400] -mb-7 pl-4 pt-3 z-[999]">
                                                                            CIN
                                                                        </label>
                                                                        <input
                                                                            className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-[#F3F3F3] border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                                                            type="text"
                                                                            placeholder="Enter CIN"
                                                                            required
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="grid ml-2">
                                                                        <label className="text-[#444444] text-[12px] font-[400] -mb-7 pl-4 pt-3 z-[999]">
                                                                            Date
                                                                            of
                                                                            registration
                                                                        </label>
                                                                        <input
                                                                            className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-[#F3F3F3] border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                                                            type="text"
                                                                            placeholder="Enter Date of registration"
                                                                            required
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="grid grid-cols-2">
                                                                    <div className="grid mr-2">
                                                                        <label className="text-[#444444] text-[12px] font-[400] -mb-7 pl-4 pt-3 z-[999]">
                                                                            Category
                                                                        </label>
                                                                        <input
                                                                            className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-[#F3F3F3] border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                                                            type="text"
                                                                            placeholder="Enter category"
                                                                            required
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="grid ml-2">
                                                                        <label className="text-[#444444] text-[12px] font-[400] -mb-7 pl-4 pt-3 z-[999]">
                                                                            Pincode
                                                                        </label>
                                                                        <input
                                                                            className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-[#F3F3F3] border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                                                            type="text"
                                                                            placeholder="Enter pincode"
                                                                            required
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="grid grid-cols-2">
                                                                    <div className="grid mr-2">
                                                                        <label className="text-[#444444] text-[12px] font-[400] -mb-7 pl-4 pt-3 z-[999]">
                                                                            State
                                                                        </label>
                                                                        <input
                                                                            className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-[#F3F3F3] border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                                                            type="text"
                                                                            placeholder="Enter state"
                                                                            required
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="grid ml-2">
                                                                        <label className="text-[#444444] text-[12px] font-[400] -mb-7 pl-4 pt-3 z-[999]">
                                                                            Company
                                                                            Name
                                                                        </label>
                                                                        <input
                                                                            className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-[#F3F3F3] border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                                                            type="text"
                                                                            placeholder="Enter company name"
                                                                            required
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="grid grid-cols-2">
                                                                    <div className="grid mr-2">
                                                                        <label className="text-[#444444] text-[12px] font-[400] -mb-7 pl-4 pt-3 z-[999]">
                                                                            Email
                                                                        </label>
                                                                        <input
                                                                            className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-[#F3F3F3] border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                                                            type="text"
                                                                            placeholder="Enter email"
                                                                            required
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="grid ml-2">
                                                                        <label className="text-[#444444] text-[12px] font-[400] -mb-7 pl-4 pt-3 z-[999]">
                                                                            Sub-category
                                                                        </label>
                                                                        <input
                                                                            className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-[#F3F3F3] border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                                                            type="text"
                                                                            placeholder="Enter sub-category"
                                                                            required
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="grid grid-cols-2">
                                                                    <div className="grid mr-2">
                                                                        <label className="text-[#444444] text-[12px] font-[400] -mb-7 pl-4 pt-3 z-[999]">
                                                                            City
                                                                        </label>
                                                                        <input
                                                                            className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-[#F3F3F3] border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                                                            type="text"
                                                                            required
                                                                            placeholder="Enter city"
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="grid ml-2">
                                                                        <label className="text-[#444444] text-[12px] font-[400] -mb-7 pl-4 pt-3 z-[999]">
                                                                            Country
                                                                        </label>
                                                                        <input
                                                                            className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-[#F3F3F3] border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                                                            type="text"
                                                                            required
                                                                            placeholder="Enter country"
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="my-4">
                                                                <button
                                                                    onClick={() =>
                                                                        props.togglecomapnyKYCStep(
                                                                            3
                                                                        )
                                                                    }
                                                                    className="py-3 px-16 my-3 font-[600] text-white transition duration-500 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600"
                                                                >
                                                                    Verify
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {props.companyKYCStep ===
                                                        3 && (
                                                        <>
                                                            <div className="flex flex-col items-center">
                                                                <h5 className="text-[#1C2347] text-[18px] font-[500] my-5">
                                                                    Verify
                                                                    Company PAN
                                                                    Card, CIN &
                                                                    Director
                                                                    details
                                                                </h5>
                                                                <p className="text-[#6B6B6B] text-[14px] font-[400] leading-[16px] mb-5">
                                                                    Verify
                                                                    Director PAN
                                                                    Number
                                                                </p>
                                                                <div className="w-[500px] max-md:w-full">
                                                                    <select className="w-full text-grey focus:text-[#1C2347] py-4 px-4 text-[16px]  max-md:px-2 bg-[#F3F3F3] border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1">
                                                                        <option value="Select Director">
                                                                            --
                                                                            Select
                                                                            Director
                                                                            --
                                                                        </option>
                                                                        <option>
                                                                            Have
                                                                            to
                                                                            Map
                                                                            the
                                                                            Data{" "}
                                                                        </option>
                                                                    </select>
                                                                    <div className="grid mr-2 text-left my-4">
                                                                        <label className="text-[#444444] text-[12px] font-[400] -mb-7 pl-4 pt-3 z-[999]">
                                                                            CIN
                                                                        </label>
                                                                        <input
                                                                            className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-[#F3F3F3] border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                                                            type="text"
                                                                            placeholder="Enter CIN"
                                                                            required
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="my-4">
                                                                    <button
                                                                        onClick={() =>
                                                                            props.togglecomapnyKYCStep(
                                                                                4
                                                                            )
                                                                        }
                                                                        className="py-3 px-16 my-3 font-[600] text-white transition duration-500 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600"
                                                                    >
                                                                        Verify
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {props.companyKYCStep ===
                                                        4 && (
                                                        <div className="flex flex-col items-center">
                                                            <h5 className="text-[#1C2347] text-[18px] font-[500] my-5">
                                                                Verify Company
                                                                PAN Card, CIN &
                                                                Director details
                                                            </h5>
                                                            <p className="text-[#6B6B6B] text-[14px] font-[400] leading-[16px] mb-5">
                                                                Verify Director
                                                                Aadhaar Number
                                                            </p>
                                                            <div className="w-[500px] max-md:w-full">
                                                                <div className="grid mr-2 text-left my-4">
                                                                    <label className="text-[#444444] text-[12px] font-[400] -mb-7 pl-4 pt-3 z-[999]">
                                                                        Director
                                                                        Aadhaaar
                                                                        Number
                                                                    </label>
                                                                    <input
                                                                        className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-[#F3F3F3] border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                                                        type="text"
                                                                        placeholder="Ex. CYNOD8999A"
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="my-4">
                                                                <button
                                                                    onClick={() =>
                                                                        props.toogleVerificationStep(
                                                                            4
                                                                        )
                                                                    }
                                                                    className="py-3 px-16 my-3 font-[600] text-white transition duration-500 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600"
                                                                >
                                                                    Verify
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            )} */}
                                        {props.user?.kycSteps?.length === 4 && (
                                            <>
                                                <div className="flex flex-col items-center my-10">
                                                    <img
                                                        src="../images/userkyc/successful.webp"
                                                        alt=""
                                                        className="my-5"
                                                    />
                                                    <h5 className="text-[#1C2347] text-[18px] font-[500]">
                                                        Verification successful
                                                    </h5>
                                                    <p className="text-[#1C2347] px-36 max-md:px-5 my-5 text-[14px] font-[400]">
                                                        Invincible Meta respects
                                                        your right to access or
                                                        request the deletion of
                                                        your data. For more
                                                        information, please
                                                        consult our privacy
                                                        policy or reach out to
                                                        us at
                                                        <span className="text-[#489BF6]">
                                                            &nbsp;privacy@invinciblemeta.com.
                                                        </span>
                                                        "
                                                    </p>
                                                </div>
                                                <div className="my-4">
                                                    <button
                                                        onClick={() =>
                                                            props.popupController()
                                                        }
                                                        className="py-3 px-16 my-3 font-[600] text-white transition duration-500 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600"
                                                    >
                                                        Done
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <div className="p-4 bg-[#E9F3FF] w-full flex items-center justify-center">
                        <div className="flex text-[#489BF6] font-[600]">
                            <p>&copy;Powered by:&nbsp;</p>
                            <img
                                src="../images/darklogo.webp"
                                className="w-[200px]"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserKYCView;
