import {
    OTPService,
    FirebaseAuthService,
    UserService,
    LocalStorageService,
} from "../../services";
import SignInView from "./SignIn.view";
import { useState, useContext, useEffect } from "react";
import { Context } from "../../context/Context";
import { useNavigate } from "react-router-dom";

function SignInContainer(props) {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [countryCodes, setCountryCodes] = useState(null);
    const [phoneCode, setPhoneCode] = useState("+91");
    const [country, setCountry] = useState("IN");
    const [authType, setAuthType] = useState("Email and Password");
    const [searchTerm, setSearchTerm] = useState("");
    const [enterPhoneOtp, setEnterPhoneOtp] = useState(false);
    const [enterEmailOtp, setEnterEmailOtp] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneOTP, setPhoneOTP] = useState("");
    const [emailOTP, setEmailOTP] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const toogleForgotPassword = () => {
        setForgotPassword(!forgotPassword);
    };
    const { setUser } = useContext(Context);
    const [timer, setTimer] = useState(40); // => SA || 120 seconds for 2 minutes, this might differ in accordance per time prescribed by the sir.
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        let interval = null;
        if (isDisabled) {
            interval = setInterval(() => {
                setTimer((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isDisabled]);

    useEffect(() => {
        if (timer === 0) {
            setIsDisabled(false);
        }
    }, [timer]);

    const messageController = () => setShowMessage(!showMessage);

    const togglePhone = (i) => {
        if (i) setPhoneCode(i);
    };
    const toggleCountry = (i) => {
        if (i) setCountry(i);
    };
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        setSearchTerm("");
    };

    const handleSearchInput = (e) => setSearchTerm(e.currentTarget.value);
    const toggleAuthType = (i) => {
        if (i) setAuthType(i);
    };
    const toggleEnterEmailOtp = async (event, i, requestType) => {
        if (event.key && event.key !== "Enter") return;
        if (i) {
            setLoading(true);
            if (!email?.includes("@") || !email?.includes(".")) {
                setShowMessage(true);
                setMessageClass("error");
                setMessageText("Enter a valid Email!");
            } else {
                const data = await OTPService.requestOTP(
                    "email",
                    email,
                    requestType,
                    "signin"
                );
                setShowMessage(true);
                setMessageClass(data?.success ? "Success" : "error");
                setMessageText(data?.message);
                if (requestType === "resend" && data?.success) {
                    setTimer(40);
                    setIsDisabled(true);
                }
                setEnterEmailOtp(i);
            }
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        if (e.target.name === "phoneNumber") setPhoneNumber(e.target.value);
        if (e.target.name === "phone-otp") setPhoneOTP(e.target.value);
        if (e.target.name === "email-otp") setEmailOTP(e.target.value);
        if (e.target.name === "email") setEmail(e.target.value);
        if (e.target.name === "password") setPassword(e.target.value);
    };

    const toggleEnterPhoneOtp = async (event, i, requestType) => {
        if (event.key && event.key !== "Enter") return;
        let data;
        setLoading(true);
        if (phoneNumber?.length !== 10) {
            setShowMessage(true);
            setMessageClass("error");
            setMessageText("Enter a valid phone number!");
        } else {
            data = await OTPService.requestOTP(
                "phone",
                phoneNumber,
                requestType,
                "signin"
            );
            setShowMessage(true);
            setMessageClass(data?.success ? "Success" : "error");
            setMessageText(data?.message);
            if (requestType === "resend" && data?.success) {
                setTimer(40);
                setIsDisabled(true);
            }
            setEnterPhoneOtp(i);
        }
        setLoading(false);
    };
    const loginWithGoogle = async () => {
        try {
            const data = await FirebaseAuthService.signinWithGoogle();
            return data?.user?.accessToken;
        } catch (error) {
            console.error(error);
        }
    };
    const login = async (event, type) => {
        if (event.key && event.key !== "Enter") return;
        else {
            setLoading(true);
            let loginParams = {};
            switch (type) {
                case "email":
                    if (!email?.includes("@") || !email?.includes(".")) {
                        setShowMessage(true);
                        setMessageClass("error");
                        setMessageText("Enter a valid Email!");
                        setLoading(false);
                        return;
                    }
                    if (!password) {
                        setShowMessage(true);
                        setMessageClass("error");
                        setMessageText("Enter Password to proceed!");
                        setLoading(false);
                        return;
                    }
                    loginParams = { email, password };
                    break;
                case "phone":
                    loginParams = { phoneNumber, otp: phoneOTP };
                    break;
                case "google":
                    setLoading(false);
                    loginParams = { token: await loginWithGoogle() };
                    break;
                case "email-otp":
                    loginParams = { email, otp: emailOTP };
                    break;
                default:
                    break;
            }
            const data = await UserService.login(loginParams, type);
            setShowMessage(true);
            setMessageClass(data?.success ? "Success" : "error");
            setMessageText(data?.message);
            setLoading(false);
            if (data?.success) {
                // LocalStorageService.set(
                //     "user",
                //     JSON.stringify(data?.data?.user)
                // );
                setUser(data?.data?.user);
                LocalStorageService.set("accessToken", data?.data?.accessToken);
                LocalStorageService.set(
                    "refreshToken",
                    data?.data?.refreshToken
                );
                setTimeout(function () {
                    navigate("/ai-voice-cloning");
                }, 1000);
            }
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await UserService.getCountries();
                //    console.log(data?.data?.countries);
                setCountryCodes(data?.data?.countries);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <SignInView
            searchTerm={searchTerm}
            togglePhone={togglePhone}
            toggleCountry={toggleCountry}
            toggleDropdown={toggleDropdown}
            phoneCode={phoneCode}
            country={country}
            isOpen={isOpen}
            authType={authType}
            toggleAuthType={toggleAuthType}
            countryCodes={countryCodes}
            handleSearchInput={handleSearchInput}
            toggleEnterEmailOtp={toggleEnterEmailOtp}
            enterEmailOtp={enterEmailOtp}
            enterPhoneOtp={enterPhoneOtp}
            handleInputChange={handleInputChange}
            toggleEnterPhoneOtp={toggleEnterPhoneOtp}
            login={login}
            showMessage={showMessage}
            messageText={messageText}
            messageClass={messageClass}
            messageController={messageController}
            loading={loading}
            isDisabled={isDisabled}
            timer={timer}
            toogleForgotPassword={toogleForgotPassword}
            forgotPassword={forgotPassword}
            passwordVisible={passwordVisible}
            togglePasswordVisibility={togglePasswordVisibility}
        />
    );
}

export default SignInContainer;
