function TwoButtonPopupView(props) {
    return (
        <>
            <div className="absolute left-0 top-0 w-full h-screen grid justify-items-center items-center font-['barlow']">
                <div
                    className="absolute left-0 top-0 bg-[#00000060] z-[999] w-full h-screen"
                    data-testid="background"
                    onClick={props.togglePopup}
                ></div>
                <div className="text-center fixed h-[400px] z-[9999999999] bg-white rounded-lg p-8 w-[600px] max-[1200px]:w-[400px] max-[1200px]:p-4 max-[400px]:w-full max-md:p-2 text-center grid grid-cols-1 justify-items-center">
                    {!props.loading ? (
                        <div className="flex flex-col items-center justify-center">
                            <img alt="" src={props.content.imageUrl} />
                            <h5 className="text-[#1C2347] text-[22px] font-[500] leading-[26px] my-3">
                                {props.content.heading}
                            </h5>
                            <p className="text-[14px] font-[400] leading-[21px] text-[#626262] pb-2">
                                {props.content.paragraph}
                            </p>
                            <div>
                                <button
                                    onClick={props.leftbuttonfunction}
                                    className="py-3 px-5 my-3 font-[600] text-[14px] transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 text-[#489BF6] border-[2px] border-[#489BF6]"
                                >
                                    {props.content.leftButtonText}
                                </button>
                                <button
                                    onClick={props.rightbuttonfunction}
                                    disabled={props.loading}
                                    className={`bg-gradient-to-r text-[14px] from-blue-500 to-purple-600 transition duration-300 ease-in-out hover:scale-[1.05] py-3.5 px-12 my-3 font-[600] text-white  rounded-lg mr-4 hover:bg-gradient-to-l`}
                                >
                                    {props.content.rightButtonText}
                                </button>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="flex flex-col items-center justify-center">
                                <div className="h-[200px]">
                                    <lottie-player
                                        src="https://lottie.host/13688d4e-23ce-4f64-bb50-3064036c4eff/BObk8wBNDE.json"
                                        background="transparent"
                                        speed="1"
                                        direction="1"
                                        mode="normal"
                                        loop
                                        autoplay
                                    ></lottie-player>
                                </div>
                                <p className="text-[#1C2347] text-[18px] leading-[21px]">
                                    {props.content.loadingtext}
                                </p>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default TwoButtonPopupView;
