import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import { Suspense } from "react";
import { LocalStorageService } from "./services";
import {
    AuthPage,
    DashboardPage,
    AIVoiceCloningPage,
    APIKeysPage,
    MyAccountPage,
    ARProductPage,
    DomainVerificationPage,
} from "./pages";
import { Loader, NotFound } from "./components";

function App() {
    const DashboardPrivateRouteWrapper = ({ element }) => {
        const isUserAuthenticated = () => {
            if (LocalStorageService.get("accessToken") != null) {
                return true;
            } else {
                return false;
            }
        };
        if (!isUserAuthenticated()) {
            return <Navigate to="/auth" />;
        }
        return element;
    };
    const DashboardPublicRouteWrapper = ({ element }) => {
        const isUserAuthenticated = () => {
            if (LocalStorageService.get("accessToken") != null) {
                return true;
            } else {
                return false;
            }
        };
        if (isUserAuthenticated()) {
            return <Navigate to="/" />;
        }
        return element;
    };
    return (
        <div className="App">
            <Routes>
                <Route
                    path="/"
                    element={
                        <Page
                            page={
                                <DashboardPrivateRouteWrapper
                                    element={<DashboardPage />}
                                />
                            }
                        />
                    }
                />
                <Route
                    path="/auth"
                    element={
                        <Page
                            page={
                                <DashboardPublicRouteWrapper
                                    element={<AuthPage />}
                                />
                            }
                        />
                    }
                />
                <Route
                    path="/domain-verification"
                    element={<Page page={<DomainVerificationPage />} />}
                />
                <Route
                    path="/api-keys"
                    element={
                        <Page
                            page={
                                <DashboardPrivateRouteWrapper
                                    element={<APIKeysPage />}
                                />
                            }
                        />
                    }
                />

                <Route
                    path="/ai-voice-cloning"
                    element={
                        <Page
                            page={
                                <DashboardPrivateRouteWrapper
                                    element={<AIVoiceCloningPage />}
                                />
                            }
                        />
                    }
                />
                <Route
                    path="/ar-products"
                    element={
                        <Page
                            page={
                                <DashboardPrivateRouteWrapper
                                    element={<ARProductPage />}
                                />
                            }
                        />
                    }
                />
                <Route
                    path="/my-account"
                    element={
                        <Page
                            page={
                                <DashboardPrivateRouteWrapper
                                    element={<MyAccountPage />}
                                />
                            }
                        />
                    }
                />
                <Route path="*" element={<Page page={<NotFound />} />} />
            </Routes>
        </div>
    );
}

function Page(props) {
    return (
        <Suspense
            fallback={
                <div className="loaderCntMain">
                    <div className="loaderCnt">
                        <div className="leftDiv"></div>
                        <div className="rightDiv">
                            <div className="rightHeader">
                                {/* <div className="headerContent large"></div> */}
                                {/* <div className="headerContent large"></div> */}
                                <div className="headerContent large rounded-r-full rounded-l-full"></div>
                                <div className="headerContent small rounded-full"></div>
                                {/* <div className="headerContent small"></div> */}
                            </div>
                            <div className="rightMain p-4">
                                {/* <div className="rightUpper"></div> */}
                                <div className="rightLower p-8">
                                    {/* <div>
                                    <div className="h-2 bg-white rounded col-span-2 mb-2.5"></div>
                                    <div className="h-2 bg-white rounded col-span-2 mb-2.5 w-4/6"></div>
                                    <div className="h-2 bg-white rounded col-span-2 mb-2.5 w-5/6"></div>
                                    <div className="h-2 bg-white rounded col-span-2 mb-2.5 w-3/6"></div>
                                    <div className="h-2 bg-white rounded col-span-2 mb-2.5 w-4/6"></div>
                                    <div className="h-2 bg-white rounded col-span-2 mb-2.5 w-2/6"></div>
                                    <div className="h-2 bg-white rounded col-span-2 mb-2.5 w-5/6"></div>
                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="sr-only">Loading...</span>
                </div>
            }
        >
            {props.page}
        </Suspense>
    );
}

export default App;
