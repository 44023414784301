import api from "./Axios.service";

const ARService = {
    AddAr: async (name, description, s3ObjectKey) => {
        try {
            const response = await api.post(
                `${process.env.REACT_APP_BASE_URL}/ar`,
                {
                    name: name,
                    description: description,
                    s3ObjectKey: s3ObjectKey,
                }
            );
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
    FetchAr: async () => {
        try {
            const response = await api.get(
                `${process.env.REACT_APP_BASE_URL}/ar`
            );
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
    UpdateAr: async (_id, name, isActive, s3ObjectKey) => {
        try {
            const response = await api.put(
                `${process.env.REACT_APP_BASE_URL}/ar`,
                {
                    _id,
                    name,
                    isActive,
                    s3ObjectKey,
                }
            );
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
};
export default ARService;
