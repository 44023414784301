import { useState, useEffect, useContext } from "react";
import VoiceSettingsView from "./VoiceSettings.view";
import { VoiceService } from "../../services";
import { Context } from "../../context/Context";

export default function VoiceSettingsContainer(props) {
    const {
        model,
        setModel,
        models,
        setModels,
        similarity,
        setSimilarity,
        stability,
        setStability,
        style,
        setStyle,
        userSpeedBoost,
        setUserSpeedBoost,
    } = useContext(Context);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(model);

    const handleChange = (e) => {
        if (e.target.name === "Similarity") {
            setSimilarity(e.target.value);
        }
        if (e.target.name === "Style Exaggeration") {
            setStyle(e.target.value);
        }
        if (e.target.name === "Stability") {
            setStability(e.target.value);
        }

        if (e.target.name === "userSpeedBoost") {
            setUserSpeedBoost(!userSpeedBoost);
        }

        if (e.target.dataset.name === "toogledropdown") {
            setIsOpen(!isOpen);
        }
    };

    const handleSelectOption = (option) => {
        setSelectedOption(option);
        setModel(option);
        setIsOpen(false);
    };

    useEffect(() => {
        async function fetchModelsFunction() {
            const data = await VoiceService.fetchModels();
            if (data?.success) {
                setModels(data?.data);
                if (!model) {
                    setSelectedOption(data?.data[0]);
                    setModel(data?.data[0]);
                }
            } else {
                setSelectedOption(model);
            }
        }
        if (!models) {
            fetchModelsFunction();
        }
    }, []);
    return (
        <>
            <VoiceSettingsView
                isOpen={isOpen}
                selectedOption={selectedOption}
                handleSelectOption={handleSelectOption}
                models={models}
                voiceController={props.voiceController}
                similarity={similarity}
                stability={stability}
                handleChange={handleChange}
                style={style}
                userSpeedBoost={userSpeedBoost}
            />
        </>
    );
}
