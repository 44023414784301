import AddThreeDModel from "../AddThreeDModel";
import { Link } from "react-router-dom";
function CreateARView(props) {
    return (
        <>
            {props.showPopup && (
                <AddThreeDModel
                    popupController={props.popupController}
                    changeDisplayOptions={props.changeDisplayOptions}
                    toggleModelKey={props.toggleModelKey}
                    toggleModelId={props.toggleModelId}
                />
            )}
            {props.showHostPopup && (
                <div className="absolute left-0 top-0 w-full h-screen grid justify-items-center items-center font-['barlow']">
                    <div
                        className="absolute left-0 top-0 bg-[#00000060] z-[999] w-full h-screen"
                        data-testid="background"
                        onClick={props.createHostKeyController}
                    ></div>
                    <div className="text-center fixed z-[9999999999] bg-white rounded-lg p-8 w-[600px] max-[1200px]:w-[400px] max-[1200px]:p-4 max-[400px]:w-full max-md:p-2 text-center grid grid-cols-1 justify-items-center">
                        <div className="flex flex-col items-center">
                            <img
                                alt=""
                                className="w-4/6"
                                src="../images/generatekeys/header-hostkey.webp"
                            />
                            <h5 className="text-[#1C2347] text-[22px] font-[500] leading-[26px] my-5">
                                You dont have a Host Key!
                            </h5>
                            <div>
                                <div
                                    onClick={() =>
                                        props.changeNavOptions("HostKeys")
                                    }
                                    className="cursor-pointer py-3 px-10 my-3 font-[600] transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 text-[#489BF6] border-[2px] border-[#489BF6]"
                                >
                                    Create Host Key
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="text-left font-['barlow'] p-4 overflow-y-auto height-of-inner-div thin-scrollbar">
                {/* <h5 className="my-4 text-[#1C2347] text-[22px] font-[600] leading-[25px]">
                    AR products
                </h5> */}
                <div className="bg-gradient-to-br from-[#7B49A9] via-[#489BF6] to-black rounded-lg h-[calc(100vh-250px)] grid grid-cols-[1fr_1fr] max-md:block items-center p-1">
                    <div className="flex flex-col items-center px-10 max-md:hidden">
                        <img
                            alt=""
                            src="../images/arproduct/landingpage.webp"
                            className="max-md:h-[200px] max-md:my-10"
                        />
                    </div>
                    <div className="flex flex-col justify-center w-full text-center items-center h-full">
                        <img
                            alt=""
                            src="../images/arproduct/AR.webp"
                            className="max-md:px-4"
                        />
                        <h5 className="text-[#FFFFFF] text-[25px] leading-[30px] font-[600] mt-7 mb-3 max-md:mt-2 max-md:mb-1">
                            New Way of Experience Products
                        </h5>
                        <p className="font-[300] text-[16px] leading-[18px] text-[#FFFFFF] px-28 max-xl:px-12 max-lg:px-10 max-md:px-4">
                            AR product experience, blending the virtual with the
                            real for unparalleled interaction. Explore the
                            future of technology with every use."
                        </p>
                        <button
                            onClick={
                                props.apiKeysLength > 0
                                    ? () => props.popupController()
                                    : () => props.createHostKeyController()
                            }
                            className="py-3 px-10 mt-7 mb-3 max-md:mt-2 max-md:w-full max-md:mb-1 font-[600] w-[250px] transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg max-md:mx-2 text-[#FFFFFF] border-[2px] border-[#FFFFFF] "
                        >
                            Create
                        </button>
                        <p className="underline  text-[#FFFFFF] text-[14px] cursor-pointer duration-300 ease-in-out hover:scale-[1.05]">
                            Recently Generated Links
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateARView;
