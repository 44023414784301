import axios from "axios";

const UploadS3Service = {
    uploadFileToS3URL: async (url, file, contentType, onUploadProgress) => {
        try {
            const response = await axios.put(url, file, {
                headers: {
                    "Content-Type": contentType,
                },
                onUploadProgress: onUploadProgress,
            });
            return response;
        } catch (error) {
            console.error(error);
            return { success: false };
        }
    },
};
export default UploadS3Service;
