import axios from "axios";
import api from "./Axios.service";
const UserService = {
    login: async (loginParams, type) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/user`,
                { ...loginParams, type: type }
            );
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
    signup: async (userData) => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/user`,
                {
                    name: userData?.name,
                    email: userData?.email,
                    password: userData?.password,
                    phoneNumber: userData?.phoneNumber,
                    country: userData?.country,
                }
            );
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
    getCountries: async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/user/countries`
            );
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
    create: async () => {
        try {
            const response = await api.put(
                `${process.env.REACT_APP_BASE_URL}/user/key`
            );
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
    fetch: async () => {
        try {
            const response = await api.get(
                `${process.env.REACT_APP_BASE_URL}/user/keys`
            );
            return response?.data?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
    delete: async (privateKeyId) => {
        try {
            const response = await api.delete(
                `${process.env.REACT_APP_BASE_URL}/user/key/${privateKeyId}`
            );
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
    getUser: async () => {
        try {
            const response = await api.get(
                `${process.env.REACT_APP_BASE_URL}/user`
            );
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
    setKYC: async (kycType) => {
        try {
            const response = await api.post(
                `${process.env.REACT_APP_BASE_URL}/user/kyc`,
                {
                    kycType,
                }
            );
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
    aadharKYC: async (aadharNumber) => {
        try {
            const response = await api.post(
                `${process.env.REACT_APP_BASE_URL}/user/aadhar`,
                { aadharNumber }
            );
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
    panCardKYC: async (panNumber) => {
        try {
            const response = await api.post(
                `${process.env.REACT_APP_BASE_URL}/user/pan`,
                { panNumber }
            );
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
    createHostKey: async (email, code = "", action) => {
        try {
            const response = await api.post(
                `${process.env.REACT_APP_BASE_URL}/user/host_key`,
                { email, code, action }
            );

            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
    fetchHostKey: async () => {
        try {
            const response = await api.get(
                `${process.env.REACT_APP_BASE_URL}/user/host_key`
            );
            return response?.data?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
    deleteHostKey: async (_id) => {
        try {
            const response = await api.put(
                `${process.env.REACT_APP_BASE_URL}/user/host_key`,
                { _id }
            );
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
    presignedURL: async (method, bucket, key, contentType = "") => {
        try {
            const response = await api.get(
                `${process.env.REACT_APP_BASE_URL}/user/presigned_url?method=${method}&bucket=${bucket}&key=${key}&contentType=${contentType}`,
                {}
            );
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
    forgotPassword: async (
        commType,
        destination,
        otp,
        newPassword1,
        newPassword2,
        type
    ) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/user/forgot_password/${type}`,
                { commType, destination, otp, newPassword1, newPassword2 }
            );
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
};

export default UserService;
