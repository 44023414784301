// utils.js

export const sortBySearchTerm = (items, searchTerm) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return items.sort((a, b) => {
        const aStartsWith = a.name
            .toLowerCase()
            .startsWith(lowerCaseSearchTerm);
        const bStartsWith = b.name
            .toLowerCase()
            .startsWith(lowerCaseSearchTerm);

        if (aStartsWith && !bStartsWith) {
            return -1;
        }
        if (!aStartsWith && bStartsWith) {
            return 1;
        }
        return 0;
    });
};
