import { Loader, Message, PopupModal, Spinner } from "../";
function GenerateKeysView(props) {
    return (
        <>
            {props.showPopUp && (
                <>
                    <div className="fixed left-0 top-0 w-full h-screen grid justify-items-center items-center font-['barlow']">
                        <div
                            className="absolute left-0 top-0 bg-[#00000060] z-[999] w-full h-screen"
                            data-testid="background"
                            onClick={props.togglePopup}
                        ></div>
                        <div className="text-center fixed z-[9999999999] bg-white rounded-lg p-8 w-[600px] max-[1200px]:w-[400px] max-[1200px]:p-4 max-[400px]:w-full max-md:p-2 text-center grid grid-cols-1 justify-items-center">
                            <div className="flex flex-col items-center">
                                <img
                                    alt=""
                                    src="../images/generatekeys/header.webp"
                                />
                                <h5 className="text-[#1C2347] text-[22px] font-[500] leading-[26px] my-5">
                                    Are you sure you want to delete the keys?
                                </h5>
                                <div>
                                    <button
                                        onClick={props.togglePopup}
                                        className="py-3 px-10 my-3 font-[600] transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 text-[#489BF6] border-[2px] border-[#489BF6]"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={props.deleteApiKeyConfirmed}
                                        className={`bg-gradient-to-r from-blue-500 to-purple-600 transition duration-300 ease-in-out hover:scale-[1.05] py-3.5 px-10 my-3 font-[600] text-white  rounded-lg mr-4 hover:bg-gradient-to-l`}
                                    >
                                        {props.loading ? (
                                            <Spinner />
                                        ) : (
                                            "Confirm"
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                </>
            )}
            {props.showMessage && (
                <Message
                    messageText={props.messageText}
                    messageClass={props.messageClass}
                    messageController={props.messageController}
                />
            )}
            <div className="font-['barlow'] p-4 overflow-y-auto thin-scrollbar height-of-inner-div">
                {!props.loading ? (
                    <div
                        onClick={
                            props.apiKeys.length < 10
                                ? props.apiKeyGeneration
                                : props.maxAPIKeysLimitMessageToggle
                        }
                        className="border-[1px] border-[#000000] border-dashed py-4 rounded-md grid justify-items-center group hover:bg-[#489BF636] duration-300 cursor-pointer hover:border-white hover:shadow-xl"
                    >
                        <img
                            src="../images/generatekeys/generatekey.webp"
                            alt=""
                            className="py-2"
                        />
                        <h5 className="font-[600] text-[22px] leading-[26px] py-2 text-[#489BF6] underline">
                            Generate key
                        </h5>
                        <p className="text-[#646464] leading-[22px] text-[14px] font-[400] py-2 px-8 text-center">
                            Note: you can only generate maximum&nbsp;
                            <span className="font-[800]">
                                10 keys, {10 - props.apiKeys.length} left
                            </span>
                        </p>
                    </div>
                ) : (
                    <>
                        <div className="border-[1px] border-[#000000] border-dashed py-4 rounded-md grid justify-items-center group hover:bg-[#489BF636] duration-300 cursor-pointer hover:border-white hover:shadow-xl">
                            <div className="border-gray-300 h-[50px] w-[50px] animate-spin rounded-full border-4 border-t-yellow-500" />
                        </div>
                    </>
                )}
                <div className="generate-key-header">
                    <h5>Sno.</h5>
                    <h5>Generated On</h5>
                    <h5>Client ID</h5>
                    <h5>Secret Key</h5>
                    <div></div>
                </div>
                {!props.fetchLoading ? (
                    props?.apiKeys?.length > 0 ? (
                        <>
                            {props.apiKeys.map((value, index) => (
                                <div className="generate-key-table" key={index}>
                                    <span>Sno.</span>
                                    <p>#{index + 1}</p>
                                    <span>Generated:</span>
                                    <p>
                                        {new Date(
                                            value.createdAt
                                        ).toLocaleString()}
                                    </p>
                                    <span>Client Id:</span>
                                    <p
                                        id="textToCopy"
                                        className="text-ellipsis overflow-hidden"
                                    >
                                        {value.clientId}
                                        <i
                                            id="copyIcon"
                                            className="fa fa-files-o text-[14px] cursor-pointer text-[#489BF6] font-[800]"
                                            onClick={() =>
                                                props.copyTextToClipboard(
                                                    value.clientId
                                                )
                                            }
                                        ></i>
                                    </p>
                                    <span>Secret Key:</span>
                                    <p
                                        id="textToCopy"
                                        className="text-ellipsis overflow-hidden"
                                    >
                                        {value.secretKey}
                                        <i
                                            id="copyIcon"
                                            className="fa fa-files-o text-[14px] cursor-pointer text-[#489BF6] font-[800]"
                                            onClick={() =>
                                                props.copyTextToClipboard(
                                                    value.secretKey
                                                )
                                            }
                                        ></i>
                                    </p>
                                    <span>Delete:</span>
                                    <i
                                        className="fa fa-trash text-[#C2C2C2] text-[24px] hover:text-red-500 max-md:text-red-500 cursor-pointer"
                                        onClick={() =>
                                            props.deleteApiKey(value._id)
                                        }
                                    ></i>
                                </div>
                            ))}
                        </>
                    ) : (
                        <>
                            <div className="font-[600] text-[#969696]">
                                ---- No data Found!!----{" "}
                            </div>
                        </>
                    )
                ) : (
                    <>
                        <Loader />
                    </>
                )}
            </div>
        </>
    );
}

export default GenerateKeysView;
