import { DragandDropModel, Message, Spinner } from "..";
function AddThreeDModelView(props) {
    return (
        <>
            {props.showMessage && (
                <Message
                    messageText={props.messageText}
                    messageClass={props.messageClass}
                    messageController={props.messageController}
                />
            )}
            <div className="absolute left-0 top-0 w-full h-screen grid justify-items-center items-center font-['barlow']">
                <div
                    className="absolute left-0 top-0 bg-[#00000060] z-[999] w-full h-screen"
                    data-testid="background"
                    onClick={() => props.popupController()}
                ></div>
                <div className="text-center fixed z-[9999999999] bg-[#F5F5F5] w-[1000px] rounded-md  max-[1200px]:w-[700px] max-md:w-full text-center grid grid-cols-1 justify-items-center">
                    <div className="flex justify-between w-full bg-white p-4 rounded-md">
                        <h5 className="text-[#1C2347] font-[600] text-[24px] leading-[29px]">
                            Upload
                        </h5>
                        <i
                            onClick={() => props.popupController()}
                            className="fa fa-times-circle text-[32px] text-[#C7C7C7] cursor-pointer"
                        ></i>
                    </div>
                    <div className="p-4 grid grid-cols-2 items-center max-md:block w-full">
                        <div className="p-4">
                            {/* <label
                                htmlFor="dropzone-file"
                                className={`flex flex-col items-center justify-center w-full h-full my-2 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer ${props.fileName ? `bg-[#E9F3FF]` : `bg-gray-50`}   ${props.fileName ? `` : `hover:bg-gray-100`}`}
                            >
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <img
                                        src={`../images/addArProduct/drop.webp`}
                                        className="p-2"
                                        alt=""
                                    />
                                    <p className="mb-2 text-sm text-gray-500">
                                        <span className="font-semibold">
                                            Click to upload
                                        </span>{" "}
                                        or drag and drop
                                    </p>
                                    {!props.fileName && (
                                        <>
                                            <p className="text-xs text-gray-500">
                                                GLB Files only
                                            </p>
                                        </>
                                    )}
                                    {props.fileName && (
                                        <p className="text-xs text-gray-500 dark:text-gray-400">
                                            {props.fileName}
                                        </p>
                                    )}
                                </div>
                                <input
                                    id="dropzone-file"
                                    type="file"
                                    className="hidden"
                                    accept=".glb"
                                    onChange={props.handleFileChange}
                                />
                            </label> */}
                            <>
                                {props.loader ===
                                    props.lodingStates.notInitializedstate && (
                                    <DragandDropModel
                                        handleFileUploaded={
                                            props.handleFileUploaded
                                        }
                                    />
                                )}
                                {props.loader ===
                                    props.lodingStates.loadingstate && (
                                    <>
                                        <div className="px-5 py-5 border-2 border-dashed border-[#489BF6] rounded-lg bg-[#EDF6FF]">
                                            <p className="text-[18px] font-[600] font-['barlow'] text-[#489BF6]">
                                                Uploading !!!
                                            </p>
                                            <div>
                                                <div className="inline-block mb-2 ms-[calc(100%-1.25rem)] py-0.5 px-1.5 bg-blue-50 border border-blue-200 text-xs font-medium text-blue-600 rounded-lg dark:bg-blue-800/30 dark:border-blue-800 dark:text-blue-500">
                                                    {props.progress + "%"}
                                                </div>
                                                <div
                                                    className="flex w-full h-[5px] bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700"
                                                    role="progressbar"
                                                >
                                                    <div
                                                        style={{
                                                            width: `${props.progress}%`,
                                                        }}
                                                        className={`flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap transition duration-500`}
                                                    ></div>
                                                </div>
                                            </div>
                                            <div className="h-[164px]">
                                                <lottie-player
                                                    src="https://lottie.host/e3356e3b-2af6-4411-b6f9-6295887b50d2/Y5WMkZESRg.json"
                                                    background="transparent"
                                                    speed="1"
                                                    direction="1"
                                                    mode="normal"
                                                    loop
                                                    autoplay
                                                ></lottie-player>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {props.loader ===
                                    props.lodingStates.intializedstate && (
                                    <div className="px-5 py-5 border-2 border-dashed border-[#489BF6] rounded-lg bg-[#EDF6FF]">
                                        <p className="text-[18px] font-[600] font-['barlow'] text-[#489BF6]">
                                            Verified!!
                                        </p>
                                        <div className="h-[200px]">
                                            <lottie-player
                                                src="https://lottie.host/763172f8-d486-4274-bf54-db46f0b473f5/bQpJdD0BOg.json"
                                                background="transparent"
                                                speed="1"
                                                loop
                                                autoplay
                                                direction="1"
                                                mode="normal"
                                            ></lottie-player>
                                        </div>
                                    </div>
                                )}
                            </>
                        </div>
                        <div>
                            <div className="grid justify-items-start">
                                <label>
                                    Name<span className="text-red-500">*</span>
                                </label>
                                <input
                                    disabled={!props.file}
                                    placeholder="Enter Name"
                                    onChange={props.handleNameChange}
                                    className={`${props.file ? "bg-white" : "bg-none"} w-full text-grey focus:text-[#1C2347] py-3 px-4 text-[16px]  max-md:px-2 border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1`}
                                ></input>
                                <label>
                                    Description
                                    <span className="text-red-500">*</span>
                                </label>
                                <textarea
                                    disabled={!props.file}
                                    onChange={props.handleDescriptionChange}
                                    placeholder="Enter Description"
                                    className={`${props.file ? "bg-white" : "bg-none"} block p-2.5 w-full text-[14px] text-[#959595] rounded-lg border-[1px] border-[#C1C3C0] outline-1 focus:outline-[#489BF6] my-2`}
                                ></textarea>
                                <button
                                    disabled={!props.file || props.loading}
                                    onClick={props.uploadToAR}
                                    className={`${props.file ? "cursor-pointer hover:scale-[1.05] duration-300 hover:bg-gradient-to-t" : ""}  bg-gradient-to-br from-blue-500 to-purple-700 my-2 max-md:w-full w-[180px] flex justify-center  py-2.5 rounded-md text-white font-[600]`}
                                >
                                    {props.loading ? <Spinner /> : "Create"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddThreeDModelView;
