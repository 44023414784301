import { useEffect } from "react";
import MessageView from "./Message.view";

export default function MessageContainer(props) {
    useEffect(() => {
        if (props.messageText) {
            const timeoutId = setTimeout(() => {
                props.messageController();
            }, 2000);

            return () => clearTimeout(timeoutId);
        }
    }, [props.messageText, props.messageController]);
    return (
        <>
            <MessageView
                messageController={props.messageController}
                messageText={props.messageText}
                messageClass={props.messageClass}
            />
        </>
    );
}
