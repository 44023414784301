import { Message, Spinner, Loader } from "..";
function AddVoiceView(props) {
    document.addEventListener("DOMContentLoaded", function () {
        // Select the elements
        const textToCopy = document.getElementById("textToCopy");
        const copyIcon = document.getElementById("copyIcon");
        const copyMessage = document.getElementById("copyMessage");

        // Add click event listener to the icon
        copyIcon.addEventListener("click", function () {
            // Create a temporary textarea element
            const textarea = document.createElement("textarea");
            textarea.value = textToCopy.innerText; // Set the value to the text inside <p>
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand("copy"); // Copy the selected text
            document.body.removeChild(textarea); // Remove the textarea

            // Show copy message
            copyMessage.classList.remove("hidden");
            setTimeout(function () {
                copyMessage.classList.add("hidden");
            }, 1500); // Hide message after 1.5 seconds
        });
    });
    return (
        <>
            {props.showMessage && (
                <Message
                    messageText={props.messageText}
                    messageClass={props.messageClass}
                    messageController={props.messageController}
                />
            )}
            {props.showPopUp && (
                <>
                    <div className="absolute left-0 top-0 w-full h-screen grid justify-items-center items-center font-['barlow']">
                        <div
                            className="absolute left-0 top-0 bg-[#00000060] z-[999] w-full h-screen"
                            data-testid="background"
                            onClick={props.togglePopup}
                        ></div>
                        <div className="text-center fixed z-[9999999999] bg-white rounded-lg p-8 w-[600px] max-[1200px]:w-[400px] max-[1200px]:p-4 max-[400px]:w-full max-md:p-2 text-center grid grid-cols-1 justify-items-center">
                            <div className="flex flex-col items-center">
                                <img
                                    alt=""
                                    src="../images/generatekeys/header.webp"
                                />
                                <h5 className="text-[#1C2347] text-[22px] font-[500] leading-[26px] my-5">
                                    Are Sure you want to delete ?
                                </h5>
                                <div>
                                    <button
                                        onClick={props.togglePopup}
                                        className="py-3 px-10 my-3 font-[600] transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 text-[#489BF6] border-[2px] border-[#489BF6]"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={props.deleteVoiceFunction}
                                        disabled={props.loading}
                                        className={`bg-gradient-to-r from-blue-500 to-purple-600 transition duration-300 ease-in-out hover:scale-[1.05] py-3.5 px-10 my-3 font-[600] text-white  rounded-lg mr-4 hover:bg-gradient-to-l`}
                                    >
                                        {props.loading ? (
                                            <Spinner />
                                        ) : (
                                            "Confirm"
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                </>
            )}
            <div className="font-['barlow']">
                <div
                    onClick={props.addVoiceController}
                    className="border-[1px] border-[#000000] border-dashed py-4 rounded-md grid justify-items-center group hover:bg-[#489BF636] duration-300 cursor-pointer hover:border-white hover:shadow-xl"
                >
                    <img
                        src="../images/addvoice/addvoice.webp"
                        alt=""
                        className="py-2"
                    />
                    <h5 className="font-[600] text-[22px] leading-[26px] py-2">
                        Add instant voice
                    </h5>
                    <p className="text-[#646464] leading-[22px] text-[14px] font-[400] py-2 px-8 text-center">
                        Experience the future of digital interaction with our AI
                        voice cloning technology—where every word brings your
                        ideas to life in over 29 languages. Transform your
                        communications with lifelike speech that captivates and
                        engages audiences worldwide.
                    </p>
                </div>
                <h5 className="text-[20px] leading-[24px] text-[#1C2347] py-3 mt-4 font-[500]">
                    Recentely added
                    <span className="text-[#959595] pl-5">
                        {props.voices?.data?.voices
                            ? props.voices?.data?.voices?.length
                            : ".."}
                        /30
                    </span>
                </h5>
                {props.loading && <Loader />}
                {!props.loading && (
                    <>
                        <div className="border-[1px] border-[#C5C6C5] bg-[#F9F9F9] rounded-md shadow-inset-lg overflow-y-scroll thin-scrollbar h-[300px] p-2">
                            <div className="grid grid-cols-3 gap-3 max-[1660px]:grid-cols-2  max-[1660px]:justify-items-center max-[1260px]:grid-cols-1 my-2">
                                {props.voices?.data?.voices?.map((voice, i) => (
                                    <div
                                        className="w-[400px] max-md:w-[250px] bg-white rounded-md border-[1px] border-[#D6E8FD] p-2"
                                        key={i}
                                    >
                                        <div className="flex items-center pt-2">
                                            <div>
                                                <i className="fa fa-user-circle-o text-[#1C2347] text-[24px]"></i>
                                            </div>
                                            <div className="pl-2">
                                                <h5 className="text-[#1C2347] font-[500] text-[16px] leading-[18px]">
                                                    {voice?.name}
                                                </h5>
                                                <div className="flex items-center pt-1">
                                                    <p className="text-[#C2C2BC] text-[12px] font-[400] pr-2">
                                                        ID :{" "}
                                                        <span id="textToCopy">
                                                            {voice?.voiceId}
                                                        </span>
                                                    </p>
                                                    <i
                                                        id="copyIcon"
                                                        className="fa fa-files-o text-[14px] cursor-pointer text-[#489BF6] font-[800]"
                                                        onClick={() =>
                                                            props.copyTextToClipboard(
                                                                voice?.voiceId
                                                            )
                                                        }
                                                    ></i>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="text-[12px] font-[400] py-2 text-[#646464]">
                                            {voice?.description}
                                            {/* <span className="text-[#489BF6] text-[14px] font-[400] cursor-pointer pl-28">
                                                .....more
                                            </span>{" "} */}
                                        </p>
                                        <div className="flex justify-between my-2">
                                            <button
                                                onClick={() =>
                                                    props.toggleSetVoice(
                                                        voice?.voiceId
                                                    )
                                                }
                                                className="rounded-l-[31px] rounded-r-[31px] bg-[#489BF626] flex items-center text-[14px] font-[400] text-[#489BF6] w-[80px] px-1 hover:scale-[1.05] duration-300"
                                            >
                                                <img
                                                    src="../images/addvoice/use.webp"
                                                    className="pr-2"
                                                    alt=""
                                                />
                                                USE
                                            </button>
                                            <div className="p-2 flex">
                                                <img
                                                    onClick={() =>
                                                        props.downloadSpeechFunction(
                                                            voice.s3ObjectKey,
                                                            voice.name
                                                        )
                                                    }
                                                    src="../images/addvoice/arrow-down.webp"
                                                    className="pr-2 cursor-pointer"
                                                    alt=""
                                                />
                                                <img
                                                    src="../images/addvoice/trash.webp"
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                        props.deleteSpeech(
                                                            voice?._id
                                                        )
                                                    }
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {props?.voices?.data?.voices?.length === 0 && (
                                <>
                                    <div className="text-[14px] font-['barlow'] text-red-500 font-[600] text-center">
                                        No data available!
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default AddVoiceView;
