import { Slider } from "..";

export default function VoiceSettingsView(props) {
    return (
        <>
            <div className="fixed z-[999] left-0 top-0 w-full h-screen grid  justify-items-center items-center font-['barlow']">
                <div
                    className="absolute left-0 top-0 bg-[#00000060] z-[999] w-full h-screen"
                    data-testid="background"
                    onClick={props.voiceController}
                ></div>
                <div className="text-center rounded-lg fixed z-[9999999999] bg-white p-8 w-[1000px] max-[1200px]:w-[700px] max-[1200px]:p-4 max-md:w-full max-md:p-2 text-center grid grid-cols-1 justify-items-center">
                    <div className="flex justify-between w-full">
                        <h5 className="text-[#1C2347] font-[600] text-[24px] leading-[29px]">
                            Select Model
                        </h5>
                        <i
                            onClick={props.voiceController}
                            className="fa fa-times-circle text-[32px] text-[#C7C7C7] cursor-pointer"
                        ></i>
                    </div>
                    <div className="w-full">
                        <div className="custom-dropdown">
                            <div
                                className={`${props.isOpen ? "bg-[#ebecf0]" : "text-white"} dropdown-header flex justify-between items-center`}
                                data-name="toogledropdown"
                                onClick={(e) => props.handleChange(e)}
                            >
                                {props.selectedOption ? (
                                    <>
                                        <div>
                                            <div className="option-heading">
                                                {props.selectedOption.name}
                                            </div>
                                            <div className="option-description">
                                                {
                                                    props.selectedOption
                                                        .description
                                                }
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    "Select an option"
                                )}
                                <i
                                    className={`fa fa-caret-${props.isOpen ? "up" : "down"}`}
                                />
                            </div>
                            {props.isOpen && (
                                <div className="dropdown-list">
                                    {props.models?.map((option, index) => (
                                        <div
                                            key={index}
                                            className="dropdown-option"
                                            onClick={() =>
                                                props.handleSelectOption(option)
                                            }
                                        >
                                            <div className="option-heading">
                                                {option.name}
                                            </div>
                                            <div className="option-description">
                                                {option.description}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="w-full text-left py-3">
                        <Slider
                            title="Stability"
                            minLabel="More Variable"
                            maxLabel="More Stable"
                            preseletedValue={props.stability}
                            changefunction={props.handleChange}
                            warningMessage={
                                props.stability <= 30
                                    ? "Under 30% may lead to instability"
                                    : ""
                            }
                        />
                        <Slider
                            title="Similarity"
                            minLabel="Low"
                            maxLabel="High"
                            preseletedValue={props.similarity}
                            changefunction={props.handleChange}
                        />
                        <Slider
                            title="Style Exaggeration"
                            minLabel="None"
                            maxLabel="Exaggerated"
                            preseletedValue={props.style}
                            changefunction={props.handleChange}
                            warningMessage={
                                props.style >= 50
                                    ? "Over 50% may lead to instability"
                                    : ""
                            }
                        />

                        <label className="inline-flex items-center cursor-pointer">
                            <input
                                type="checkbox"
                                className="sr-only peer"
                                checked={props.userSpeedBoost}
                                onChange={props.handleChange}
                                name={"userSpeedBoost"}
                            />
                            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            <span className="ml-1 text-[14px] font-medium text-[#1C2347]">
                                Speaker Boost
                            </span>
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
}
