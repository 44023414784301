import ForgotPassword from "../ForgotPassword";

export default function MyAccountView(props) {
    return (
        <>
            <div className="pt-7 px-10 font-['barlow'] text-left overflow-y-auto height-of-inner-div thin-scrollbar">
                {/* <h5 className="text-[#1C2347] font-[600] text-[22px] leading-[26px] my-2">
                    My Account
                </h5> */}
                <div className="grid grid-cols-[200px_1fr] my-5 max-lg:block">
                    <div className="max-lg:flex max-lg:justify-between">
                        <div
                            className={`${props.navOptions === "Basic Details" ? `bg-[#EDF6FF] text-[#489BF6]` : `text-[#1C2347]`} flex justify-between items-center p-3 my-2 hover:bg-[#EDF6FF] hover:text-[#489BF6] cursor-pointer`}
                            onClick={() =>
                                props.changeNavOptions("Basic Details")
                            }
                        >
                            <div className="flex items-center">
                                <img
                                    src={`../images/myaccount/basicdetails${props.navOptions === "Basic Details" ? `light` : `dark`}.webp`}
                                    alt=""
                                    className="pr-2"
                                />
                                <h5
                                    className={`text-[16px] font-[500] leading-[26px]`}
                                >
                                    Basic Details
                                </h5>
                            </div>
                            <i className="fa fa-caret-right max-lg:hidden"></i>
                        </div>
                        <div
                            className={`${props.navOptions === "KYC details" ? `bg-[#EDF6FF] text-[#489BF6]` : `text-[#1C2347]`} flex justify-between items-center p-3 my-2 hover:bg-[#EDF6FF] hover:text-[#489BF6] cursor-pointer`}
                            onClick={() =>
                                props.changeNavOptions("KYC details")
                            }
                        >
                            <div className="flex items-center">
                                <img
                                    src={`../images/myaccount/kycdetails${props.navOptions === "KYC details" ? `light` : `dark`}.webp`}
                                    alt=""
                                    className="pr-2"
                                />
                                <h5
                                    className={`text-[16px] font-[500] leading-[26px]`}
                                >
                                    KYC Details
                                </h5>
                            </div>
                            <i className="fa fa-caret-right max-lg:hidden"></i>
                        </div>
                        <div
                            className={`${props.navOptions === "Reset password" ? `bg-[#EDF6FF] text-[#489BF6]` : `text-[#1C2347]`} flex justify-between items-center p-3 my-2 hover:bg-[#EDF6FF] hover:text-[#489BF6]  cursor-pointer`}
                            onClick={() =>
                                props.changeNavOptions("Reset password")
                            }
                        >
                            <div className="flex items-center">
                                <img
                                    src={`../images/myaccount/resetpassword${props.navOptions === "Reset password" ? `light` : `dark`}.webp`}
                                    alt=""
                                    className="pr-2"
                                />
                                <h5
                                    className={`text-[16px] font-[500] leading-[26px]`}
                                >
                                    Reset Password
                                </h5>
                            </div>
                            <i className="fa fa-caret-right max-lg:hidden"></i>
                        </div>
                    </div>
                    <div className="px-4 w-full">
                        <div
                            className="bg-cover rounded-lg h-[150px] w-full"
                            style={{
                                backgroundImage: `url('../images/myaccount/basicdetailsheader.webp')`,
                            }}
                        ></div>
                        <div className="flex justify-between max-[1250px]:block">
                            <div className="flex max-[1250px]:block">
                                <div
                                    className="bg-cover rounded-full h-[150px] w-[150px] border-[12px] border-white -mt-24 ml-5"
                                    style={{
                                        backgroundImage: `url('../images/myaccount/tempuse.webp')`,
                                    }}
                                ></div>
                                <div className="my-2">
                                    <h5 className="text-[#1C2347] text-[18px] leading-[21px] font-[600]">
                                        {props?.user?.name}
                                    </h5>
                                    <p className="text-[#595959] font-[400] text-[14px] mt-1">
                                        {props?.user?.email}
                                    </p>
                                </div>
                            </div>

                            {/* <p className="text-[#1C2347] font-[400] leading-[16px] text-[14px] my-2">
                                Customer ID :
                                <span className="text-[#595959]">
                                    {props.idUser}
                                </span>
                            </p> */}
                        </div>
                        <div>
                            {props.navOptions === "Basic Details" && (
                                <>
                                    <div className="my-5">
                                        <h5 className="text-[#1C2347] text-[18px] font-[500] leading-[21px] my-5">
                                            Basic Details
                                        </h5>
                                        <div className="grid">
                                            <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3">
                                                Name
                                            </label>
                                            <input
                                                className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-transparent border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                                type="text"
                                                value={props?.user?.name}
                                                disabled
                                                required
                                            />
                                        </div>
                                        <div className="grid grid-cols-2 max-md:block items-center my-2">
                                            <div className="grid mr-2">
                                                <div className="relative flex items-center border-[1px] border-[#C1C3C0] text-sm font-normal  rounded-lg">
                                                    <div className="flex items-center p-3 cursor-pointer w-[120px] bg-[#489BF638] group hover:rounded-lg">
                                                        <img
                                                            src={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${props.country}.svg`}
                                                            alt={props.country}
                                                            className="w-[30px]"
                                                        />

                                                        <p className="font-[600] text-gray-700 pl-1">
                                                            {props.phoneCode}
                                                        </p>

                                                        <i className="fa fa-angle-down pl-1"></i>
                                                    </div>
                                                    <input
                                                        className="w-full text-grey px-4 max-md:px-2 bg-[#F3F3F3] input-number py-4 focus:outline-none text-[16px]"
                                                        type="number"
                                                        placeholder="Enter phone no."
                                                        name="phoneNumber"
                                                        value={
                                                            props?.user
                                                                ?.phoneNumber
                                                        }
                                                        disabled
                                                    />
                                                </div>
                                                <span className="underline text-[#489BF6] text-[14px] font-[400] p-2 cursor-pointer">
                                                    <i className="fa fa-info-circle underline"></i>
                                                    &nbsp; Change phone number !
                                                </span>
                                            </div>
                                            <div className="grid ml-2">
                                                <label className="text-[#444444] text-[12px] font-[400] -mb-16 pl-4 pt-3 z-[999]">
                                                    Email
                                                </label>
                                                <input
                                                    className="w-full text-grey focus:text-[#1C2347] pt-6 pb-1 px-4 text-[16px]  max-md:px-2 bg-[#F3F3F3] border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                                    type="text"
                                                    value={props?.user?.email}
                                                    disabled
                                                    required
                                                />
                                                <span className="underline text-[#489BF6] text-[14px] font-[400] p-2 cursor-pointer">
                                                    <i className="fa fa-info-circle underline"></i>
                                                    &nbsp; Change email !
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {props.navOptions === "KYC details" && (
                                <>
                                    {props?.user?.isKycVerified && (
                                        <div className="my-5">
                                            <div className="flex justify-between">
                                                <h5 className="text-[#1C2347] text-[18px] font-[500] leading-[21px] my-3">
                                                    KYC Details
                                                </h5>
                                                {/* <p className="underline text-[#489BF6] font-[600] mr-2">
                                                    Reset KYC
                                                </p> */}
                                            </div>
                                            <div className="grid">
                                                <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3 z-[999]">
                                                    Selected region
                                                </label>
                                                <input
                                                    className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-[#F3F3F3] border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                                    type="text"
                                                    value={
                                                        props?.user?.kycSteps[0]
                                                            ?.country
                                                    }
                                                    required
                                                    disabled
                                                />
                                            </div>
                                            <h5 className="text-[#1C2347] text-[18px] font-[500] leading-[21px] my-3">
                                                PAN Details
                                            </h5>
                                            <div className="grid grid-cols-2">
                                                <div className="grid mr-2">
                                                    <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3 z-[999]">
                                                        Name on PAN card
                                                    </label>
                                                    <input
                                                        className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-[#F3F3F3] border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                                        type="text"
                                                        value={
                                                            props?.user
                                                                ?.kycSteps[2]
                                                                ?.panVerification
                                                                ?.result?.data
                                                                ?.full_name
                                                        }
                                                        required
                                                        disabled
                                                    />
                                                </div>
                                                <div className="grid ml-2">
                                                    <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3 z-[999]">
                                                        PAN Number
                                                    </label>
                                                    <input
                                                        className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-[#F3F3F3] border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                                        type="text"
                                                        value={
                                                            props?.user
                                                                ?.kycSteps[2]
                                                                ?.panVerification
                                                                ?.result?.data
                                                                ?.pan_number
                                                        }
                                                        required
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <h5 className="text-[#1C2347] text-[18px] font-[500] leading-[21px] my-3">
                                                Aadhaar Details
                                            </h5>
                                            <div className="grid grid-cols-2">
                                                <div className="grid mr-2">
                                                    <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3 z-[999]">
                                                        Full Name
                                                    </label>
                                                    <input
                                                        className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-[#F3F3F3] border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                                        type="text"
                                                        value={
                                                            props?.user
                                                                ?.kycSteps[3]
                                                                ?.aadharVerification
                                                                ?.result?.data
                                                                ?.name
                                                        }
                                                        required
                                                        disabled
                                                    />
                                                </div>
                                                <div className="grid ml-2">
                                                    <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3 z-[999]">
                                                        Aadhaar Number
                                                    </label>
                                                    <input
                                                        className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-[#F3F3F3] border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                                        type="text"
                                                        value={
                                                            props?.user
                                                                ?.kycSteps[3]
                                                                ?.aadharVerification
                                                                ?.result?.data
                                                                ?.aadhaar_number
                                                        }
                                                        required
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-2">
                                                <div className="grid mr-2">
                                                    <label className="text-[#444444] text-[12px] font-[400] -mb-7 pl-4 pt-3 z-[999]">
                                                        Date of birth
                                                    </label>
                                                    <input
                                                        className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-[#F3F3F3] border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                                        type="text"
                                                        value={
                                                            props?.user
                                                                ?.kycSteps[3]
                                                                ?.aadharVerification
                                                                ?.result?.data
                                                                ?.dob
                                                        }
                                                        required
                                                        disabled
                                                    />
                                                </div>
                                                <div className="grid ml-2">
                                                    <label className="text-[#444444] text-[12px] font-[400] -mb-7 pl-4 pt-3 z-[999]">
                                                        Gender
                                                    </label>
                                                    <input
                                                        className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-[#F3F3F3] border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                                        type="text"
                                                        value={
                                                            props?.user
                                                                ?.kycSteps[3]
                                                                ?.aadharVerification
                                                                ?.result?.data
                                                                ?.gender
                                                        }
                                                        required
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="grid">
                                                <label className="text-[#444444] text-[12px] font-[400] -mb-7 pl-4 pt-3 z-[999]">
                                                    Address
                                                </label>
                                                <input
                                                    className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-[#F3F3F3] border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                                    type="text"
                                                    value={
                                                        props?.user?.kycSteps[3]
                                                            ?.aadharVerification
                                                            ?.result?.data
                                                            ?.state
                                                    }
                                                    required
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {!props?.user?.isKycVerified && (
                                        <div className="bg-[#EBF4FF] p-4">
                                            <div className="flex flex-col items-center">
                                                <img
                                                    src="../images/myaccount/userKycnotdone.webp"
                                                    alt=""
                                                />
                                                <h5 className="text-[#1C2347] font-[500] text-[18px] leading-[21px] my-5">
                                                    Please complete your KYC to
                                                    access all features of the
                                                    product!
                                                </h5>
                                                <button
                                                    onClick={
                                                        props.popupController
                                                    }
                                                    className="py-4 px-16 my-3 font-[600] text-white transition duration-300 ease-in-out  rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600"
                                                >
                                                    Complete KYC
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            {props.navOptions === "Reset password" && (
                                <>
                                    <div className="my-5">
                                        <h5 className="text-[#1C2347] text-[18px] font-[500] leading-[21px] my-4">
                                            Reset Password
                                        </h5>
                                        <ForgotPassword />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
