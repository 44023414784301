import Spinner from "../Spinner";

function AudioPlayerView(props) {
    return (
        <>
            <div className="fixed left-0 top-0 w-full h-screen grid justify-items-center items-end font-['barlow']">
                <div
                    className="absolute left-0 top-0 bg-[#00000060] z-[999] w-full h-screen"
                    onClick={() => props.audioPopupController()}
                ></div>
                <div className="bg-gradient-to-br from-blue-500 to-purple-700 h-[120px] flex items-center justify-between max-md:justify-center font-['barlow'] px-4 max-md:px-1 z-[9999999999]">
                    <div className="flex items-center text-[54px] max-md:text-[20px] px-10 max-md:px-1">
                        <i className="fa fa-user-circle text-white pr-2"></i>
                        <div className="text-left">
                            <h5 className="text-[#1C2347] font-[600] text-[18px] max-md:text-[12px] leading-[22px]">
                                {props?.playerSpeech?.voiceName}
                            </h5>
                            <p className="text-[#DEDEDC] text-[14px] max-md:text-[8px] font-[400]">
                                {new Date(
                                    props?.playerSpeech?.createdAt
                                ).toLocaleString()}
                            </p>
                        </div>
                    </div>
                    <div className="text-white flex max-md:flex-col items-center px-10 max-md:px-1">
                        <div className="flex items-center max-md:pb-2">
                            <i
                                className="fa fa-undo text-[20px] max-md:text-[12px] px-2 cursor-pointer"
                                onClick={props.handleBackward}
                            ></i>
                            {props.isLoading ? (
                                <Spinner />
                            ) : (
                                <i
                                    className={`${props.isPlaying ? "fa fa-pause-circle" : "fa fa-play-circle "}  text-[54px] max-md:text-[32px] px-2 cursor-pointer`}
                                    onClick={() =>
                                        props.togglePlay(
                                            props?.playerSpeech?.s3ObjectKey
                                        )
                                    }
                                ></i>
                            )}
                            <i
                                className="fa fa-repeat text-[20px] max-md:text-[12px]  px-2 cursor-pointer"
                                onClick={props.handleForward}
                            ></i>
                        </div>
                        <div className="w-[500px] max-xl:w-[300px] max-md:w-[200px] bg-[#DEDEDC] rounded-full h-2.5 ml-2">
                            <div
                                className={`bg-[#1C2347] h-2.5 max-md:h-[10px] rounded-full`}
                                style={{
                                    width: `${props.progress}%`,
                                }}
                            ></div>
                        </div>
                    </div>
                    <div className="px-10 max-md:px-2">
                        <i
                            className="fa fa-arrow-circle-down text-[30px] max-md:text-[16px]  px-2 text-white cursor-pointer"
                            onClick={() =>
                                props.downloadSpeechFunction(
                                    props?.playerSpeech?.s3ObjectKey,
                                    props?.playerSpeech?.voiceName
                                )
                            }
                        ></i>
                        <i
                            className="fa fa-times-circle  text-[30px] max-md:text-[16px] px-2 text-white cursor-pointer"
                            onClick={() => props.audioPopupController()}
                        ></i>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AudioPlayerView;
