import { useState, useEffect } from "react";
import HostKeysView from "./HostKeys.view";
import { UserService } from "../../services";
function HostKeysContainer(props) {
    const [showPopUp, setShowPopup] = useState(false);
    const [showPopUpAddition, setShowPopUpAddition] = useState(false);
    const [privateKeyId, setPrivateKeyId] = useState("");
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [action, setAction] = useState("request");
    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [apiKeys, setApiKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(false);

    const messageController = () => setShowMessage(!showMessage);
    function copyTextToClipboard(textToCopy) {
        const textarea = document.createElement("textarea");
        textarea.value = textToCopy;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        setShowMessage(true);
        setMessageClass("Success");
        setMessageText("Copied successfully!!");
    }

    const apiKeyGeneration = async (action) => {
        try {
            setLoading(true);
            if (!email) throw new Error("Email ID is Missing !!");
            const data = await UserService.createHostKey(email, code, action);
            setShowMessage(true);
            setMessageClass(data?.success ? "Success" : "error");
            setMessageText(data?.message);
            if (data?.success) {
                fetchAPIKeys();
            }
            setLoading(false);
            if (data.success && action === "request") {
                setAction("verify");
            }
            if ((data.success && action === "verify") || !data.success) {
                setAction("request");
                setEmail("");
                setCode("");
                togglePopupAddition();
            }
        } catch (err) {
            console.error(err);
        }
    };

    const maxAPIKeysLimitMessageToggle = () => {
        setShowMessage(true);
        setMessageClass("error");
        setMessageText("Maximum limit of the API Key Generation Reached!!");
    };
    const handleInputChange = (e) => {
        const inputType = e.target.name;

        switch (inputType) {
            case "email":
                setEmail(e.target.value);
                break;
            case "code":
                setCode(e.target.value);
                break;
            default:
                break;
        }
    };

    const deleteApiKeyConfirmed = async () => {
        try {
            setLoading(true);
            if (!privateKeyId) throw new Error("HostKey Object ID missing!");
            const data = await UserService.deleteHostKey(privateKeyId);
            setShowMessage(true);
            setMessageClass(data?.success ? "Success" : "error");
            setMessageText(data?.message);
            if (data?.success) {
                fetchAPIKeys();
                setShowPopup(false);
            }
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };
    const deleteApiKey = (id) => {
        setPrivateKeyId(id);
        setShowPopup(true);
    };
    const togglePopup = () => {
        setShowPopup(!showPopUp);
    };
    const togglePopupAddition = () => {
        setShowPopUpAddition(!showPopUpAddition);
    };
    const fetchAPIKeys = async () => {
        try {
            setFetchLoading(true);
            const data = await UserService.fetchHostKey();
            setApiKeys(data?.hostKeys ? data.hostKeys : []);
            setFetchLoading(false);
        } catch (err) {
            console.error(err);
        }
    };
    useEffect(() => {
        fetchAPIKeys();
    }, []);

    return (
        <HostKeysView
            copyTextToClipboard={copyTextToClipboard}
            messageText={messageText}
            messageClass={messageClass}
            showMessage={showMessage}
            messageController={messageController}
            apiKeys={apiKeys}
            apiKeyGeneration={apiKeyGeneration}
            maxAPIKeysLimitMessageToggle={maxAPIKeysLimitMessageToggle}
            deleteApiKey={deleteApiKey}
            togglePopup={togglePopup}
            showPopUp={showPopUp}
            deleteApiKeyConfirmed={deleteApiKeyConfirmed}
            loading={loading}
            fetchLoading={fetchLoading}
            showPopUpAddition={showPopUpAddition}
            togglePopupAddition={togglePopupAddition}
            handleInputChange={handleInputChange}
            email={email}
            action={action}
            code={code}
        />
    );
}

export default HostKeysContainer;
