import { Message, Spinner, TwoButtonPopup, VoiceSettings } from "..";
function TextToSpeechView(props) {
    return (
        <>
            {props.showMessage && (
                <Message
                    messageText={props.messageText}
                    messageClass={props.messageClass}
                    messageController={props.messageController}
                />
            )}
            {props.showVoiceSettings && (
                <VoiceSettings voiceController={props.voiceController} />
            )}
            {props.showPopUp && (
                <TwoButtonPopup
                    togglePopup={props.togglePopup}
                    leftbuttonfunction={props.leftbuttonfunction}
                    loading={props.loading}
                    rightbuttonfunction={props.rightbuttonfunction}
                    content={props.content}
                />
            )}

            <div className="bg-white font-['barlow'] border-x-[1px] border-[#489BF6] border-b-[1px] px-4 leading-[22px] font-[500]">
                <div className="flex items-center justify-between">
                    <div>
                        <h5 className="text-[#1C2347] text-[18px] pt-5">
                            AI Language model
                        </h5>
                        <p className="text-[14px] font-[400] text-[#595959] py-2">
                            Our cutting-edge multilingual speech model can
                            produce lifelike speech across 29 languages.
                        </p>
                    </div>
                    <div
                        className="flex items-center  text-[#489BF6] cursor-pointer"
                        onClick={props.voiceController}
                    >
                        <i className="fa fa-cog pr-1" aria-hidden="true"></i>
                        <p className="text-[16px] font-[500]">Voice Settings</p>
                    </div>
                </div>
                {props.voices?.data?.voices?.length < 1 ? (
                    <>
                        <div className="w-full py-10 text-[#489BF6] text-[18px] font-[600]">
                            No voices available, clone a voice!!
                        </div>
                        <button
                            onClick={() => props.changeNavOptions("AddVoice")}
                            className=" mb-10 bg-gradient-to-br from-blue-500 to-purple-700 hover:bg-gradient-to-t hover:scale-[1.05] duration-300 max-md:w-full w-[180px] py-2.5 rounded-md text-white font-[600] flex justify-center"
                        >
                            {" "}
                            Clone Voice
                        </button>
                    </>
                ) : (
                    <>
                        <div>
                            <h5 className="text-[#1C2347] text-[18px] pt-5">
                                Select voice
                                <span className="text-red-500">*</span>
                            </h5>
                            <div className="py-2">
                                <select
                                    className="flex justify-between text-[#595959] border-[1px] border-[#1C2347] hover:bg-[#489BF636] w-full focus:ring-[1px] focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-4 items-center"
                                    onChange={props.handleChangeVoice}
                                    value={props.selectedVoice}
                                    disabled={
                                        props.voices?.data?.voices?.length < 1
                                    }
                                >
                                    {props.voices?.data?.voices?.map(
                                        (voice, i) => (
                                            <option
                                                value={voice?.voiceId}
                                                className="block px-4 py-2 hover:bg-[#489BF636] hover:cursor-pointer"
                                                key={i}
                                            >
                                                {voice?.name}
                                            </option>
                                        )
                                    )}
                                    {props.voices?.data?.voices?.length < 1 && (
                                        <>
                                            <option value="">
                                                No voices available, clone a
                                                voice!!
                                            </option>
                                        </>
                                    )}
                                </select>
                            </div>
                        </div>
                        {/* <div>
                    <div className="flex">
                        <input
                            type="checkbox"
                            id="custom-checkbox"
                            className="my-1"
                            onChange={props.handleCheckboxChange}
                        />
                        <label
                            htmlFor="custom-checkbox"
                            className="text-[#1C2347] text-[18px] pl-4 py-2"
                        >
                            Translate Voice
                        </label>
                    </div>
                    {props.isChecked && (
                        <div className="relative">
                            <button
                                className="flex mb-4 justify-between text-[#595959] border-[1px] border-[#1C2347] hover:bg-[#489BF636] w-full focus:ring-[1px] focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center"
                                type="button"
                                onClick={props.toggleShowDropdown2}
                            >
                                --- Select Language---{" "}
                                <i className="fa fa-angle-down"></i>
                            </button>

                            {props.showDropdown2 && (
                                <div
                                    id="dropdown"
                                    className="bg-white absolute rounded-lg shadow-xl w-full"
                                >
                                    <ul className="py-2 text-sm text-gray-700 ">
                                        <li
                                            className="block px-4 py-2 hover:bg-[#489BF636] hover:cursor-pointer"
                                            onClick={props.toggleShowDropdown2}
                                        >
                                            Language 1
                                        </li>
                                        <li
                                            className="block px-4 py-2 hover:bg-[#489BF636] hover:cursor-pointer"
                                            onClick={props.toggleShowDropdown2}
                                        >
                                            Language 2
                                        </li>
                                        <li
                                            className="block px-4 py-2 hover:bg-[#489BF636] hover:cursor-pointer"
                                            onClick={props.toggleShowDropdown2}
                                        >
                                            Language 3
                                        </li>
                                        <li
                                            className="block px-4 py-2 hover:bg-[#489BF636] hover:cursor-pointer"
                                            onClick={props.toggleShowDropdown2}
                                        >
                                            Language 4
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    )}
                </div> */}
                        <div>
                            <h5 className="text-[#1C2347] text-[18px] my-3">
                                Text<span className="text-red-500">*</span>
                            </h5>
                            <textarea
                                id="message"
                                rows="4"
                                className="block p-2.5 w-full text-[14px] text-[#959595] bg-white rounded-lg border-[1px] border-[#C1C3C0] outline-0 focus:border-[#489BF6] mt-2"
                                placeholder="Input your text in this field, the model performs optimally with longer segments."
                                onChange={props.handleText}
                                value={props.textInput}
                                maxLength={props.maxCharacters}
                            ></textarea>
                        </div>
                        <div className="flex justify-end">
                            <div className="text-[#959595] font-[400] text-[12px] leading-[20px]">
                                <p>
                                    Characters written: {props.characterCount}/
                                    {props.maxCharacters}
                                </p>
                                <p>
                                    Total characters remaining:{" "}
                                    {props.maxCharacters - props.characterCount}
                                </p>
                            </div>
                        </div>

                        <div className="flex w-full my-2">
                            <button
                                disabled={!props.textInput || props.loading}
                                onClick={props.textToSpeechConversion}
                                className={`${!props.textInput ? "cursor-not-allowed" : "hover:scale-[1.05] duration-300 hover:bg-gradient-to-t"} bg-gradient-to-br from-blue-500 to-purple-700  max-md:w-full w-[180px] py-2.5 rounded-md text-white font-[600] flex justify-center`}
                            >
                                {props.loading ? <Spinner /> : "Create Audio"}
                            </button>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default TextToSpeechView;
