import { useState } from "react";
import { VoiceService, UploadS3Service, UserService } from "../../services";
import AddInstantVoiceView from "./AddInstantVoice.view";
function AddInstantVoiceContainer(props) {
    const [fileName, setFileName] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [s3Data, setS3Data] = useState();
    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [s3ObjectKey, setS3ObjectKey] = useState("");
    const metaBucket = process.env.REACT_APP_META_BUCKET_NAME;
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const messageController = () => setShowMessage(!showMessage);
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };
    const handleNameChange = (event) => {
        if (event) {
            setName(event.target.value);
        }
    };
    const handleDescriptionChange = (event) => {
        if (event) {
            setDescription(event.target.value);
        }
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
            preparingToAddVoice(file, "audio/mpeg");
        }
    };
    const preparingToAddVoice = async (file, contentType) => {
        try {
            if (!contentType) throw new Error("Conent Type missing!");
            const uniqueName = name + new Date().getTime() + ".mp3";
            setS3ObjectKey(uniqueName);
            const data = await UserService.presignedURL(
                "PUT",
                metaBucket,
                uniqueName,
                contentType
            );
            uploadToS3(data?.data?.presigned_url, file, contentType);
        } catch (err) {
            console.error(err);
        }
    };
    const uploadToS3 = async (url, file, contentType) => {
        try {
            if (!url || !file || !contentType)
                throw new Error("Parameters is missing or invalid");
            const data = await UploadS3Service.uploadFileToS3URL(
                url,
                file,
                contentType
            );
            setS3Data(data);
        } catch (e) {
            console.error("Error while uploadeing to S3", e);
            throw e;
        }
    };
    const addVoiceFunction = async () => {
        if (s3Data.status === 200 && s3ObjectKey && description && isChecked) {
            setLoading(true);
            const data = await VoiceService.addVoice(
                name,
                s3ObjectKey,
                description
            );
            setShowMessage(true);
            setMessageClass(data.success ? "Success" : "error");
            setMessageText(data.message);
            if (data.success) {
                setTimeout(function () {
                    props.successAddVoiceController();
                    props.addVoiceController();
                    setLoading(false);
                }, 2000);
            }
        } else {
            setErrorMessage(true);
            setTimeout(() => {
                setErrorMessage(false);
            }, 1000);
            setLoading(false);
        }
    };
    return (
        <AddInstantVoiceView
            addVoiceController={props.addVoiceController}
            fileName={fileName}
            handleFileChange={handleFileChange}
            preparingToAddVoice={preparingToAddVoice}
            handleNameChange={handleNameChange}
            handleDescriptionChange={handleDescriptionChange}
            showMessage={showMessage}
            messageText={messageText}
            messageClass={messageClass}
            messageController={messageController}
            addVoiceFunction={addVoiceFunction}
            s3Data={s3Data}
            handleCheckboxChange={handleCheckboxChange}
            isChecked={isChecked}
            errorMessage={errorMessage}
            loading={loading}
            description={description}
            name={name}
        />
    );
}

export default AddInstantVoiceContainer;
