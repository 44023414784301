import { useState } from "react";
import AddThreeDModelView from "./AddThreeDModel.view";
import { UploadS3Service, ARService, UserService } from "../../services";
function AddThreeDModelContainer(props) {
    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [loader, setLoader] = useState(lodingStates.notInitializedstate);
    const [s3ObjectKey, setS3ObjectKey] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [s3Data, setS3Data] = useState();
    const [loading, setLoading] = useState(false);
    const metaBucket = process.env.REACT_APP_META_BUCKET_NAME;
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const messageController = () => setShowMessage(!showMessage);
    const handleFileUploaded = (value) => {
        setFile(value);
        setLoader(lodingStates.loadingstate);
        preparingToAddFile(value, "model/gltf-binary");
    };
    const handleNameChange = (event) => {
        if (event) {
            setName(event.target.value);
        }
    };
    const handleDescriptionChange = (event) => {
        if (event) {
            setDescription(event.target.value);
        }
    };
    const preparingToAddFile = async (file, contentType) => {
        try {
            if (!contentType) throw new Error("Conent Type missing!");
            const uniqueName = new Date().getTime() + file?.name;
            setS3ObjectKey(uniqueName);
            props.toggleModelKey(uniqueName);
            const data = await UserService.presignedURL(
                "PUT",
                metaBucket,
                uniqueName,
                contentType
            );
            uploadToS3(data?.data?.presigned_url, file, contentType);
        } catch (err) {
            console.error(err);
        }
    };
    const uploadToS3 = async (url, file, contentType) => {
        try {
            if (!url || !file || !contentType)
                throw new Error("Parameters is missing or invalid");
            const onUploadProgress = (progressEvent) => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setProgress(percentCompleted);
                // Update progress state if needed
                // For example: setProgress(percentCompleted);
            };
            const data = await UploadS3Service.uploadFileToS3URL(
                url,
                file,
                contentType,
                onUploadProgress
            );
            setS3Data(data);
            setLoader(lodingStates.intializedstate);
        } catch (e) {
            console.error("Error while uploadeing to S3", e);
            throw e;
        }
    };
    const uploadToAR = async () => {
        setLoading(true);
        try {
            if (!s3ObjectKey || !name || !description)
                throw new Error("Parameters is missing or invalid");
            const data = await ARService.AddAr(name, description, s3ObjectKey);
            setShowMessage(true);
            props.toggleModelId(data?.data);
            setMessageClass(data?.success ? "Success" : "error");
            setMessageText(data?.message);
            if (data?.success) {
                setTimeout(() => {
                    props.changeDisplayOptions("viewer");
                    setLoading(false);
                }, 2000);
            } else {
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            console.error("Error while uploadeing Adding AR", e);
            throw e;
        }
    };

    return (
        <AddThreeDModelView
            popupController={props.popupController}
            showMessage={showMessage}
            messageText={messageText}
            messageClass={messageClass}
            messageController={messageController}
            handleFileUploaded={handleFileUploaded}
            file={file}
            s3Data={s3Data}
            loader={loader}
            lodingStates={lodingStates}
            uploadToAR={uploadToAR}
            handleNameChange={handleNameChange}
            handleDescriptionChange={handleDescriptionChange}
            progress={progress}
            loading={loading}
        />
    );
}

export default AddThreeDModelContainer;

const lodingStates = {
    intializedstate: "intialized",
    notInitializedstate: "not initialized",
    loadingstate: "loading",
};
