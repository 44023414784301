function DomainVerificationView(props) {
    return (
        <>
            {props.verified ? (
                <div className="text-black font-['barlow'] grid justify-center h-screen w-full items-center">
                    <div className="max-w-[300px]">
                        <lottie-player
                            src="https://lottie.host/9460e545-5b92-4516-85f0-2cd96472d4ac/WgeoFPaA9L.json"
                            background="transparent"
                            speed="0.8"
                            direction="1"
                            mode="normal"
                            loop
                            autoplay
                        ></lottie-player>
                        <div className="text-black font-[600] text-[42px]">
                            Success
                        </div>
                        <a href="/auth">
                            <button className="bg-blue-100 my-5 text-white px-10 py-3 font-[600] hover:scale-[1.05] transition duration-300 ease-in-out hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600">
                                Login Page
                            </button>
                        </a>
                    </div>
                </div>
            ) : (
                <div className="h-screen w-full">
                    {/* <Metalabs
                        hostKey={
                            "1c06789cb71f867439812bfe239d0d2e:f7b7125b596b852f1545873848f2a2a9f0d60a443782df418c38d020f192fe61"
                        }
                        modelId={"664d8dfbc8c6b0043e8287ae"}
                    /> */}
                </div>
            )}
        </>
    );
}

export default DomainVerificationView;
