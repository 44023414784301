import TwoButtonPopupView from "./TwoButtonPopup.view";

function TwoButtonPopupContainer(props) {
    return (
        <TwoButtonPopupView
            togglePopup={props.togglePopup}
            leftbuttonfunction={props.leftbuttonfunction}
            loading={props.loading}
            rightbuttonfunction={props.rightbuttonfunction}
            content={props.content}
        />
    );
}

export default TwoButtonPopupContainer;
