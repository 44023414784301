import { useState, useEffect } from "react";
import CreateARView from "./CreateAR.view";
import { UserService } from "../../services";
function CreateARContainer(props) {
    const [showPopup, setShowPopup] = useState(false);
    const [showHostPopup, setShowHostPopup] = useState(false);
    const popupController = () => {
        setShowPopup(!showPopup);
    };
    const createHostKeyController = () => {
        setShowHostPopup(!showHostPopup);
    };
    const [apiKeysLength, setApiKeysLength] = useState(0);
    const fetchAPIKeys = async () => {
        try {
            const data = await UserService.fetchHostKey();
            setApiKeysLength(data?.hostKeys?.length);
        } catch (err) {
            console.error(err);
        }
    };
    useEffect(() => {
        fetchAPIKeys();
    }, []);
    return (
        <CreateARView
            showPopup={showPopup}
            popupController={popupController}
            changeDisplayOptions={props.changeDisplayOptions}
            toggleModelKey={props.toggleModelKey}
            apiKeysLength={apiKeysLength}
            createHostKeyController={createHostKeyController}
            showHostPopup={showHostPopup}
            toggleModelId={props.toggleModelId}
            changeNavOptions={props.changeNavOptions}
        />
    );
}

export default CreateARContainer;
