import Message from "../Message";
function DragandDropModelView(props) {
    return (
        <>
            {props.showMessage && (
                <Message
                    messageText={props.messageText}
                    messageClass={props.messageClass}
                    messageController={props.messageController}
                />
            )}
            <div
                className={`drop-zone ${props.isDragging ? "drag-over" : ""}`}
                style={{
                    display: props.fileLoaded ? "none" : "flex", // Hide after file is loaded
                    // Additional styles here
                }}
                onClick={props.handleClick}
            >
                <input
                    type="file"
                    ref={props.fileInputRef}
                    style={{ display: "none" }}
                    onChange={props.handleFileChange}
                />
                <div className="h-[100px]">
                    <lottie-player
                        src="https://lottie.host/179750b7-1f47-40ce-9274-0eff47e3885c/rlZg6Efq3f.json"
                        background="transparent"
                        speed="1"
                        // style={{ width: "300px", height: "300px" }}
                        loop
                        autoplay
                    ></lottie-player>
                </div>
                <p> Drop file to upload</p>
            </div>
        </>
    );
}

export default DragandDropModelView;
