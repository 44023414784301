import { ARService } from "../../services";
import ListARView from "./ListAR.view";
import { useEffect, useState } from "react";
function ListARContainer(props) {
    const [loading, setLoading] = useState(false);
    const [arModel, setARModel] = useState([]);
    const [showPopUp, setShowPopup] = useState(false);
    const [editShowPopup, setEditShowPopup] = useState(false);
    const [arDetails, setArDetails] = useState([]);
    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [showGeneratePopup, setShowGeneratePopup] = useState(false);
    const [modelId, setModelId] = useState(null);
    const toogleShowGenerate = () => {
        setShowGeneratePopup(!showGeneratePopup);
    };
    function copyTextToClipboard(textToCopy) {
        const textarea = document.createElement("textarea");
        textarea.value = textToCopy;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        setShowMessage(true);
        setMessageClass("Success");
        setMessageText("Copied successfully!!");
    }
    const modelIdFunction = (id) => {
        setModelId(id);
    };
    const messageController = () => setShowMessage(!showMessage);
    const deleteARToogle = (i) => {
        setArDetails(i);
        togglePopup();
    };
    const updateARToogle = (i) => {
        setArDetails(i);
        toggleEditPopup();
    };
    const handleInputChange = (event) => {
        setArDetails({ ...arDetails, [event.target.name]: event.target.value });
    };
    const togglePopup = () => {
        setShowPopup(!showPopUp);
    };
    const toggleEditPopup = () => {
        setEditShowPopup(!editShowPopup);
    };
    async function fetchARFunction() {
        setLoading(true);
        const data = await ARService.FetchAr();
        if (data?.success) setARModel(data?.data);
        setLoading(false);
    }
    const deleteARFunction = async () => {
        setLoading(true);
        try {
            if (!arDetails) throw new Error("Details missing!");
            const data = await ARService.UpdateAr(
                arDetails?._id,
                arDetails?.name,
                arDetails?.isActive,
                arDetails?.s3ObjectKey
            );
            setShowMessage(true);
            setMessageClass(data.success ? "Success" : "error");
            setMessageText(data.message);
            if (data.success) {
                togglePopup();
                fetchARFunction();
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    };
    const updateARFunction = async () => {
        setLoading(true);
        try {
            if (!arDetails) throw new Error("Details missing!");
            const data = await ARService.UpdateAr(
                arDetails?._id,
                arDetails?.name,
                arDetails?.isActive,
                arDetails?.s3ObjectKey
            );
            setShowMessage(true);
            setMessageClass(data.success ? "Success" : "error");
            setMessageText(data.message);
            if (data.success) {
                toggleEditPopup();
                fetchARFunction();
            }
            setLoading(false);
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchARFunction();
    }, []);
    return (
        <ListARView
            loading={loading}
            arModel={arModel}
            deleteARToogle={deleteARToogle}
            showPopUp={showPopUp}
            togglePopup={togglePopup}
            deleteARFunction={deleteARFunction}
            showMessage={showMessage}
            messageText={messageText}
            messageClass={messageClass}
            messageController={messageController}
            toogleShowGenerate={toogleShowGenerate}
            showGeneratePopup={showGeneratePopup}
            modelIdFunction={modelIdFunction}
            modelId={modelId}
            toggleEditPopup={toggleEditPopup}
            editShowPopup={editShowPopup}
            updateARToogle={updateARToogle}
            handleInputChange={handleInputChange}
            updateARFunction={updateARFunction}
            copyTextToClipboard={copyTextToClipboard}
            arDetails={arDetails}
        />
    );
}

export default ListARContainer;
