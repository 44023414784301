import SpeechToSpeech from "../SpeechToSpeech";
import TextToSpeech from "../TextToSpeech";

function SpeechView(props) {
    return (
        <>
            <div>
                {/* <h5 className="font-[600] text-[18px]  text-[#489BF6]">
                    {" "}
                    Create Audio{" "}
                </h5> */}
                <div className="grid grid-cols-2 justify-items-center pt-5">
                    <div
                        className={`${props.speechType === "Text-to-Speech" ? "speech-header-active" : "speech-header-inactive"}`}
                        // onClick={props.changeSpeechType} // => SA 2024-03-18 12:40:13 Will be uncommented once Speech-to-Speech functionality is implemented!
                    >
                        <div className=""></div>
                        <div>
                            <img
                                src="../images/speech/textToSpeech.webp"
                                alt=""
                            />
                            <p className="">Text to speech</p>
                        </div>
                    </div>
                    <div
                        className={`${props.speechType === "Speech-to-Speech" ? "speech-header-active" : "speech-header-inactive"}`}
                        // onClick={props.changeSpeechType} // => SA 2024-03-18 12:40:13 Will be uncommented once Speech-to-Speech functionality is implemented!
                    >
                        <div className=""></div>
                        <div className="">
                            <img
                                src="../images/speech/speechToSpeech.webp"
                                alt=""
                            />
                            <p className="">Speech to speech</p>
                            <i className="fa fa-lock pl-2"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                {props.speechType === "Text-to-Speech" && (
                    <TextToSpeech changeNavOptions={props.changeNavOptions} />
                )}
                {props.speechType === "Speech-to-Speech" && <SpeechToSpeech />}
            </div>
        </>
    );
}

export default SpeechView;
