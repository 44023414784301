import axios from "axios";
import { logoutFuntion } from "../utility/user";
import LocalStorageService from "./LocalStorageService.service";

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

let accessToken = null;
let refreshToken = null;

// Add request interceptor
api.interceptors.request.use(
    (config) => {
        accessToken = localStorage.getItem("accessToken");
        refreshToken = localStorage.getItem("refreshToken");
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        console.error("error in Axios Instance : ", error);
        throw error;
    }
);

// Add response interceptor
api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error?.response?.status === 401) {
            const callRefreshTokenApi = async () => {
                try {
                    const refreshTokenApi = async () => {
                        const response = await axios.post(
                            `${process.env.REACT_APP_BASE_URL}/user/refreshToken`,
                            {
                                accessToken,
                                refreshToken,
                            }
                        );
                        return response.data;
                    };
                    const refreshTokenData = await refreshTokenApi();
                    if (!refreshTokenData?.success) throw refreshTokenData;
                    LocalStorageService.set(
                        "accessToken",
                        refreshTokenData?.data?.accessToken
                    );
                    LocalStorageService.set(
                        "refreshToken",
                        refreshTokenData?.data?.refreshToken
                    );
                    const config = error?.response?.config;
                    config.headers.Authorization = `Bearer ${refreshTokenData?.data?.accessToken}`;
                    return await api(config);
                } catch (err) {
                    console.error(err);
                    logoutFuntion();
                }
            };
            return callRefreshTokenApi();
        } else {
            console.error("error in Axios Instance : ", error);
            throw error;
        }
    }
);

export default api;
