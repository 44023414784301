import SpeechView from "./Speech.view";
import { useState } from "react";
function SpeechContainer(props) {
    const [speechType, setSpeechType] = useState("Text-to-Speech");

    const changeSpeechType = () =>
        setSpeechType((_) =>
            _ === "Text-to-Speech" ? "Speech-to-Speech" : "Text-to-Speech"
        );
    return (
        <SpeechView
            changeSpeechType={changeSpeechType}
            speechType={speechType}
            changeNavOptions={props.changeNavOptions}
        />
    );
}

export default SpeechContainer;
