import React, { useState, useEffect, useRef } from "react";
import { validateModel } from "../../utility/loadModel";
import DragandDropModelView from "./DragandDropModel.view";

function DragandDropModelContainer({ handleFileUploaded }) {
    const [isDragging, setIsDragging] = useState(false);
    const [fileLoaded, setFileLoaded] = useState(false);
    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const cleanupInteractionRef = useRef(null);
    const fileInputRef = React.useRef(null);

    const handleClick = () => {
        fileInputRef.current.click();
    };
    const messageController = () => setShowMessage(!showMessage);
    let modelFile = null;
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            validateModel(file, (isValid, message) => {
                if (isValid) {
                    handleFileUploaded(file);
                    console.log("Validation successful:", message);

                    // Proceed to load the model into the scene if it is valid
                    // if (typeof onModelLoaded === "function") {
                    //     onModelLoaded();
                    // }
                } else {
                    console.error("Model validation failed:", message);
                    setShowMessage(true);
                    setMessageClass("error");
                    setMessageText(
                        "Model validation failed, upload valid Model!"
                    );
                    // Handle the error, e.g., alerting the user or logging the issue
                }
            });
        }
    };

    useEffect(() => {
        const handleDrop = (event) => {
            event.preventDefault();
            setIsDragging(false);
            const file = event.dataTransfer.files[0];
            if (file) {
                // loadModelFromFile(
                //     file,
                //     camera,
                //     scene,
                //     controls,
                //     (loadedModel) => {
                //         setFileLoaded(true);
                //         handleFileUploaded(file);
                //         modelFile = loadedModel;
                //     }
                // );
                validateModel(file, (isValid, message) => {
                    if (isValid) {
                        handleFileUploaded(file);
                        // console.log("Validation successful:", message);

                        // Proceed to load the model into the scene if it is valid
                        // if (typeof onModelLoaded === "function") {
                        //     onModelLoaded();
                        // }
                    } else {
                        // console.error("Model validation failed:", message);
                        setShowMessage(true);
                        setMessageClass("error");
                        setMessageText(
                            "Model validation failed, upload valid Model!"
                        );
                        // Handle the error, e.g., alerting the user or logging the issue
                    }
                });
                // if (typeof onModelLoaded === "function") {
                //     onModelLoaded();
                // }
            }
        };

        const handleDragOver = (event) => {
            event.preventDefault();
            setIsDragging(true);
        };

        const handleDragEnd = (event) => {
            event.preventDefault();
            setIsDragging(false); // Assuming you have an `isDragging` state
            setFileLoaded(true);
        };

        // Add event listeners to the document
        document.addEventListener("dragover", handleDragOver);
        document.addEventListener("drop", handleDrop);
        document.addEventListener("dragend", handleDragEnd);

        // Cleanup
        return () => {
            document.removeEventListener("dragover", handleDragOver);
            document.removeEventListener("drop", handleDrop);
            document.removeEventListener("dragend", handleDragEnd);
            if (cleanupInteractionRef.current) {
                cleanupInteractionRef.current();
            }
        };
    }, []);

    return (
        <DragandDropModelView
            isDragging={isDragging}
            fileLoaded={fileLoaded}
            showMessage={showMessage}
            messageText={messageText}
            messageClass={messageClass}
            messageController={messageController}
            handleFileChange={handleFileChange}
            fileInputRef={fileInputRef}
            handleClick={handleClick}
        />
    );
}

export default DragandDropModelContainer;
