import React, {
    useCallback,
    useContext,
    useReducer,
    useState,
    useEffect,
    useRef,
} from "react";
import UserKYCView from "./UserKYC.view";
import { UserService } from "../../services";
import { Context } from "../../context/Context";
// import { useDrag, useDrop } from "react-dnd";

function UserKYCContainer(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [countryName, setCountryName] = useState("INDIA");
    const [country, setCountry] = useState("IN");
    const [searchTerm, setSearchTerm] = useState("");
    const [verificationStep, setVerificationStep] = useState(1);
    const [identifcationStep, setIdentifcationStep] = useState(1);
    const [IDType, setIDType] = useState("");
    const [IDSubmissionType, setIDSubmissionType] = useState("");
    const [countrySelected, setCountrySelected] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { user, setUser } = useContext(Context);
    const messageController = () => setShowMessage(!showMessage);
    const [companykycVerificationStep, setCompanykycVerificationStep] =
        useState("Pan Card");
    const [companyKYCStep, setCompanyKYCStep] = useState(1);
    const [indvidualPanNumber, setIndvidualPanNumber] = useState("");
    const [indvidualAadharNumber, setIndvidualAadharNumber] = useState("");

    const [countryCodes, setCountryCodes] = useState(null);
    // const [ocrData, setOcrData] = useState([
    //     "John Doe",
    //     "john.doe@example.com",
    //     "1234 5678 9012",
    //     "Male",
    //     "ABCDE1234F",
    //     "30",
    //     "1993-04-15",
    // ]);
    // const inputReducer = (state, action) => {
    //     switch (action.type) {
    //         case "SET_FIELD":
    //             return { ...state, [action.field]: action.value };
    //         default:
    //             return state;
    //     }
    // };
    // const [formData, dispatch] = useReducer(inputReducer, {
    //     name: "",
    //     email: "",
    //     aadhar: "",
    //     gender: "",
    //     pan: "",
    //     age: "",
    //     dob: "",
    // });
    // const ItemType = {
    //     TILE: "tile",
    // };
    const togglecomapnyKYCStep = (i) => {
        setCompanyKYCStep(i);
    };
    const individualPanVerification = async () => {
        try {
            if (!indvidualPanNumber) {
                setShowMessage(true);
                setMessageClass("error");
                setMessageText("Please Enter Pan Number!");
                throw new Error("Pan Number missing!");
            }
            if (indvidualPanNumber.length !== 10) {
                setShowMessage(true);
                setMessageClass("error");
                setMessageText("Please Enter a valid Pan Number!");
                throw new Error("Invalid Pan Number!");
            }
            const data = await UserService.panCardKYC(indvidualPanNumber);
            if (data.success) {
                setShowMessage(true);
                setMessageClass("Success");
                setMessageText(data.message);
                fetchUserFunction();
                setIndvidualPanNumber("");
            } else {
                setShowMessage(true);
                setMessageClass("error");
                setMessageText(data.message);
            }
        } catch (err) {
            console.error(err);
        }
    };
    const individualAadharVerification = async () => {
        try {
            if (!indvidualAadharNumber) {
                setShowMessage(true);
                setMessageClass("error");
                setMessageText("Please Enter Aadhaar Number!");
                throw new Error("Aadhaar Number  missing!");
            }
            if (indvidualAadharNumber.length !== 12) {
                setShowMessage(true);
                setMessageClass("error");
                setMessageText("Please Enter a valid Aadhaar Number!");
                throw new Error("Invalid Aadhaar Number!");
            }
            const data = await UserService.aadharKYC(indvidualAadharNumber);
            if (data.success) {
                setShowMessage(true);
                setMessageClass("Success");
                setMessageText("Aadhaar Verified Successfully!!");
                fetchUserFunction();
                setIndvidualAadharNumber("");
            } else {
                setShowMessage(true);
                setMessageClass("error");
                setMessageText(data.message);
            }
        } catch (err) {
            console.error(err);
        }
    };
    const toggleCompanykycVerificationStep = () => {
        setCompanykycVerificationStep("CIN");
    };
    const toggleKYCMethod = async (i) => {
        try {
            if (!i) throw new Error("KYC Method not Choosen!");
            const data = await UserService.setKYC(i);
            if (data.success) {
                fetchUserFunction();
            }
        } catch (err) {
            console.error(err);
        }
    };
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleButtonClick = () => {
        if (isChecked) {
            toogleVerificationStep(2);
        } else {
            setErrorMessage("Please click on the checkbox before proceeding.");
            setTimeout(() => {
                setErrorMessage("");
            }, 1000);
        }
    };
    const toggleCountrySelected = () => setCountrySelected(true);
    const toggleDropdown = () => setIsOpen(!isOpen);
    const handleSearchInput = (e) => setSearchTerm(e.currentTarget.value);
    const toggleCountry = (i) => {
        if (i) setCountry(i);
    };
    const toggleCountryName = (i) => {
        if (i) setCountryName(i);
    };
    const toggleIDType = (i) => {
        setIDType(i);
    };
    const toggleIDSubmissionType = (i) => {
        setIDSubmissionType(i);
    };
    const toogleVerificationStep = (i) => {
        setVerificationStep(i);
    };
    const toggleIdentifcationStep = (i) => {
        setIdentifcationStep(i);
    };
    const captureFaceData = {
        heading: "Take a photo of your entire face",
        sentences: [
            "Display entire face.",
            "Avoid glare.",
            "Show consent note fitly.",
            "No photo from another image or device",
        ],
    };
    const captureDocumentData = {
        heading: "Take a photo of frontside of your document",
        sentences: [
            "Don’t fold document.",
            "Show entire document.",
            "Avoid glare.",
            "No paper - based document",
            "No photography or black & white docs.",
            "No image from another image or device",
        ],
    };
    const handleInputChange = (e) => {
        if (e.target.name === individualVerificationType.pan)
            setIndvidualPanNumber(e.target.value);
        if (e.target.name === individualVerificationType.aadhaar)
            setIndvidualAadharNumber(e.target.value);
    };

    const fetchUserFunction = async () => {
        try {
            const data = await UserService.getUser();
            if (data?.success) setUser(data?.data?.user);
        } catch (e) {
            console.error(e);
        }
    };
    // const Tile = ({ item }) => {
    //     const [{ isDragging }, drag] = useDrag(() => ({
    //         type: ItemType.TILE,
    //         item: { item },
    //         collect: (monitor) => ({
    //             isDragging: !!monitor.isDragging(),
    //         }),
    //     }));

    //     return (
    //         <div
    //             ref={drag}
    //             className="p-2 my-2 bg-white border-[1px] border-[#CACACA] rounded-lg border-l-[8px] border-l-[#25AE3B] flex items-center justify-between cursor-grab"
    //             style={{ opacity: isDragging ? 0.5 : 1 }}
    //         >
    //             <div>
    //                 <i className="fa fa-ellipsis-v pr-0.5 text-[#D9D9D9]"></i>
    //                 <i className="fa fa-ellipsis-v text-[#D9D9D9]"></i>{" "}
    //                 &nbsp;&nbsp;&nbsp;{item}
    //             </div>
    //             <i className="fa fa-arrows text-[#929191] cursor-move"></i>
    //         </div>
    //     );
    // };
    // const handleChange = (e) => {
    //     setFormData({
    //         ...formData,
    //         [e.target.name]: e.target.value,
    //     });
    // };
    // const setFieldValue = (field, value) => {
    //     setFormData({
    //         ...formData,
    //         [field]: value,
    //     });
    // };
    // // console.log(JSON.stringify(formData));
    // const handleChange = useCallback((e) => {
    //     dispatch({
    //         type: "SET_FIELD",
    //         field: e.target.name,
    //         value: e.target.value,
    //     });
    // }, []);

    // const handleDrop = useCallback((field, value) => {
    //     dispatch({ type: "SET_FIELD", field, value });
    // }, []);

    // const InputField = ({ name, value, onChange, type, setFieldValue }) => {
    //     const inputRef = useRef(null);

    //     const [, drop] = useDrop(() => ({
    //         accept: ItemType.TILE,
    //         drop: (droppedItem) => {
    //             setFieldValue(name, droppedItem.item);
    //             if (inputRef.current) {
    //                 inputRef.current.focus();
    //             }
    //         },
    //     }));

    //     const handleFocus = () => {
    //         inputRef.current.focus();
    //     };
    //     return (
    //         <div ref={drop} className="grid text-left" onClick={handleFocus}>
    //             <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-2">
    //                 {name}
    //             </label>
    //             <input
    //                 disabled
    //                 ref={inputRef}
    //                 name={name}
    //                 value={value}
    //                 onChange={onChange}
    //                 placeholder={name}
    //                 type={type}
    //                 className="w-full text-[#1C2347] focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px] max-md:px-2 bg-transparent border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md"
    //             />
    //         </div>
    //     );
    // };
    // console.log(JSON.stringify(formData));

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await UserService.getCountries();
                //    console.log(data?.data?.countries);
                setCountryCodes(data?.data?.countries);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <UserKYCView
            isOpen={isOpen}
            countryName={countryName}
            country={country}
            searchTerm={searchTerm}
            toggleDropdown={toggleDropdown}
            handleSearchInput={handleSearchInput}
            countryCodes={countryCodes}
            toggleCountry={toggleCountry}
            toggleCountryName={toggleCountryName}
            verificationStep={verificationStep}
            IDType={IDType}
            toggleIDType={toggleIDType}
            toggleIDSubmissionType={toggleIDSubmissionType}
            IDSubmissionType={IDSubmissionType}
            toogleVerificationStep={toogleVerificationStep}
            toggleIdentifcationStep={toggleIdentifcationStep}
            identifcationStep={identifcationStep}
            captureFaceData={captureFaceData}
            captureDocumentData={captureDocumentData}
            countrySelected={countrySelected}
            toggleCountrySelected={toggleCountrySelected}
            errorMessage={errorMessage}
            handleCheckboxChange={handleCheckboxChange}
            handleButtonClick={handleButtonClick}
            isChecked={isChecked}
            toggleKYCMethod={toggleKYCMethod}
            individualPanVerification={individualPanVerification}
            toggleCompanykycVerificationStep={toggleCompanykycVerificationStep}
            companykycVerificationStep={companykycVerificationStep}
            togglecomapnyKYCStep={togglecomapnyKYCStep}
            companyKYCStep={companyKYCStep}
            popupController={props.popupController}
            handleInputChange={handleInputChange}
            showMessage={showMessage}
            messageText={messageText}
            messageClass={messageClass}
            messageController={messageController}
            indvidualPanNumber={indvidualPanNumber}
            indvidualAadharNumber={indvidualAadharNumber}
            individualAadharVerification={individualAadharVerification}
            user={user}
            individualVerificationType={individualVerificationType}
            // ocrData={ocrData}
            // formData={formData}
            // Tile={Tile}
            // InputField={InputField}
            // handleChange={handleChange}
            // handleDrop={handleDrop}
        />
    );
}

export default UserKYCContainer;

const individualVerificationType = {
    pan: "panNumber",
    aadhaar: "aadhaarNumber",
};
