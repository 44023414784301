import ThreeViewerView from "./ThreeViewer.view";
import { useState, useEffect, useRef } from "react";
import { setupScene } from "../../utility/setupScene";
import { loadModel } from "../../utility/loadModel";
import { UserService } from "../../services";
function ThreeViewerContainer(props) {
    const mountRef = useRef(null);
    const metaBucket = process.env.REACT_APP_META_BUCKET_NAME;
    const [s3url, setS3url] = useState();
    const [showGeneratePopup, setShowGeneratePopup] = useState(false);
    const [loading, setLoading] = useState(false);

    const toogleShowGenerate = () => {
        setShowGeneratePopup(!showGeneratePopup);
    };
    useEffect(() => {
        const GetURL = async (s3ObjectKey, metaBucket) => {
            try {
                if (!s3ObjectKey) throw new Error("S3 Object Key missing!");
                const data = await UserService.presignedURL(
                    "GET",
                    metaBucket,
                    s3ObjectKey
                );
                setS3url(data?.data?.presigned_url);
            } catch (err) {
                console.error(err);
            }
        };
        GetURL(props.modelKey, metaBucket);
    }, []);
    const modelPath = s3url; // => SA 2024-05-10 13:11:58 url needs to be replaced from the fetch api request of the API call
    const [initialized, setInitialized] = useState(false);
    const [threeObjects, setThreeObjects] = useState({
        scene: null,
        camera: null,
        renderer: null,
        controls: null,
    });
    // function getClientHeight(element) {
    //     return element.clientHeight;
    // }

    // // Example usage
    // const myElement = document.getElementById("myElementId"); // Replace "myElementId" with the actual id of your element
    // const clientHeight = getClientHeight(myElement);
    // console.log("Client Height:", clientHeight);
    useEffect(() => {
        setLoading(true);
        if (mountRef.current) {
        }

        const { scene, camera, renderer, controls } = setupScene();
        setThreeObjects({ scene, camera, renderer, controls });
        mountRef.current.appendChild(renderer.domElement);
        loadModel(scene, modelPath, camera, controls, (onModelLoaded) => {
            setLoading(false);
            // console.log("Moddel Loadded.", onModelLoaded);
        }); // => SA 2024-05-10 12:04:47 To pass url in the ""
        setInitialized(true);

        const animate = () => {
            requestAnimationFrame(animate);
            renderer.render(scene, camera);
            camera.updateProjectionMatrix();
            controls.update();
        };

        animate();

        const onWindowResize = () => {
            const width = mountRef?.current?.clientWidth;
            const height = mountRef?.current?.clientHeight;
            renderer.setSize(width, height);
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
            controls.update();
        };

        window.addEventListener("resize", onWindowResize, false);
        onWindowResize();

        //Cleanup function to remove the renderer from the DOM and clear event listeners
        return () => {
            if (
                mountRef.current &&
                mountRef.current.contains(renderer?.domElement)
            ) {
                mountRef.current.removeChild(renderer?.domElement);
            }
            window.removeEventListener("resize", onWindowResize, false);
        };
    }, [modelPath]);
    return (
        <ThreeViewerView
            mountRef={mountRef}
            initialized={initialized}
            toogleShowGenerate={toogleShowGenerate}
            showGeneratePopup={showGeneratePopup}
            modelData={props.modelData}
            loading={loading}
        />
    );
}

export default ThreeViewerContainer;
