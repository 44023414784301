import { useState, useEffect } from "react";
import GenerateARModelView from "./GenerateARModel.view";
import { UserService } from "../../services";

function GenerateARModelContainer(props) {
    const [apiKeys, setApiKeys] = useState([]);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [selectedHost, setSelectedHost] = useState(null);
    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [generation, setGeneration] = useState(false);
    const messageController = () => setShowMessage(!showMessage);
    const handleChangeHost = (event) => {
        setSelectedHost(event.target.value);
    };
    const toggleGeneration = () => {
        setGeneration(true);
    };
    function copyTextToClipboard(textToCopy) {
        const textarea = document.createElement("textarea");
        textarea.value = textToCopy;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        setShowMessage(true);
        setMessageClass("Success");
        setMessageText("Copied successfully!!");
    }
    const fetchAPIKeys = async () => {
        try {
            setFetchLoading(true);
            const data = await UserService.fetchHostKey();
            setApiKeys(data?.hostKeys ? data.hostKeys : []);
            setSelectedHost(data?.hostKeys[0]?.key);
            setFetchLoading(false);
        } catch (err) {
            console.error(err);
        }
    };
    useEffect(() => {
        fetchAPIKeys();
    }, []);
    return (
        <GenerateARModelView
            toogleShowGenerate={props.toogleShowGenerate}
            apiKeys={apiKeys}
            fetchLoading={fetchLoading}
            handleChangeHost={handleChangeHost}
            selectedHost={selectedHost}
            toggleGeneration={toggleGeneration}
            generation={generation}
            copyTextToClipboard={copyTextToClipboard}
            messageText={messageText}
            messageClass={messageClass}
            showMessage={showMessage}
            messageController={messageController}
            modelId={props.modelId}
        />
    );
}

export default GenerateARModelContainer;
