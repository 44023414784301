import { Message, Spinner } from "..";

export default function ForgotPasswordView(props) {
    return (
        <>
            {props.showMessage && (
                <Message
                    messageText={props.messageText}
                    messageClass={props.messageClass}
                    messageController={props.messageController}
                />
            )}
            <div className="font-['barlow']">
                {!props.enteredOTP && (
                    <>
                        <div>
                            <h5 className="font-[500] py-2">
                                Select Mode of the Verification
                            </h5>
                            <label className="mr-3 cursor-pointer">
                                <input
                                    type="radio"
                                    id="phone"
                                    value="phone"
                                    name="verification"
                                    checked={props.verificationMode === "phone"}
                                    onChange={props.handleModeChange}
                                    className="pr-2"
                                />
                                &nbsp; Mobile
                            </label>
                            <label className="cursor-pointer">
                                <input
                                    type="radio"
                                    id="email"
                                    value="email"
                                    name="verification"
                                    checked={props.verificationMode === "email"}
                                    onChange={props.handleModeChange}
                                />
                                &nbsp; Email
                            </label>
                        </div>
                        <div className="py-2 grid">
                            <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3">
                                {props.verificationMode === "email"
                                    ? "Email"
                                    : "Mobile Number"}
                                <span className="text-red-500">*</span>
                            </label>
                            <input
                                className={`${props.verificationMode === "email" ? "" : "input-number"} w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-transparent border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1`}
                                type={
                                    props.verificationMode === "email"
                                        ? "email"
                                        : "number"
                                }
                                name={props.verificationMode}
                                placeholder={
                                    props.verificationMode === "email"
                                        ? "Enter your Email"
                                        : "Enter your Mobile Number"
                                }
                                onChange={props.handleInputChange}
                                required
                            />
                            <button
                                onClick={(event) =>
                                    // props.toggleEnterOtp(event, true, "request")
                                    props.forgotPasswordFuntion("request")
                                }
                                className="py-3 w-full my-3 font-[600] text-white transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600 flex justify-center"
                            >
                                {props.loading ? <Spinner /> : "GET OTP"}
                            </button>
                        </div>
                    </>
                )}
                {props.enteredOTP && (
                    <>
                        <div>
                            <p>
                                Didn’t get OTP ?{" "}
                                {!props.isDisabled ? (
                                    <span
                                        className="font-[600] text-[#489BF6] cursor-pointer"
                                        onClick={(event) =>
                                            props.toggleEnterOtp(
                                                event,
                                                true,
                                                "resend"
                                            )
                                        }
                                    >
                                        Resend OTP
                                    </span>
                                ) : (
                                    <span className="font-[500]">
                                        Resend OTP in{" "}
                                        <span className="font-[500] text-[#489BF6]">
                                            {props.timer}
                                        </span>
                                        &nbsp;secs
                                    </span>
                                )}
                            </p>
                        </div>
                        <div className="grid mb-2">
                            <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3">
                                OTP<span className="text-red-500">*</span>
                            </label>
                            <input
                                className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-transparent border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                type="otp"
                                name="otp"
                                value={props.otp}
                                placeholder="Enter your OTP"
                                onChange={props.handleInputChange}
                                required
                            />
                        </div>
                        <div className="grid mb-2">
                            <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3">
                                New Password
                                <span className="text-red-500">*</span>
                            </label>
                            <div className="relative flex items-center">
                                <input
                                    className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-transparent border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                    type={
                                        props.passwordVisible
                                            ? "text"
                                            : "password"
                                    }
                                    name="password"
                                    placeholder="Enter your New password"
                                    value={props.password}
                                    onChange={props.handleInputChange}
                                    required
                                />
                                <i
                                    className={`${
                                        props.passwordVisible
                                            ? "fa fa-eye"
                                            : "fa fa-eye-slash"
                                    } absolute right-0 px-4 py-2 cursor-pointer`}
                                    onClick={props.togglePasswordVisibility}
                                    aria-hidden="true"
                                ></i>
                            </div>
                        </div>
                        <div className="grid">
                            <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3">
                                Confirm Password
                                <span className="text-red-500">*</span>
                            </label>
                            <div className="relative flex items-center">
                                <input
                                    className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-transparent border-[1px] border-[#CACACA] focus:outline-[#489BF6] rounded-md my-1"
                                    type={
                                        props.confirmPasswordVisible
                                            ? "text"
                                            : "password"
                                    }
                                    name="confirm-password"
                                    placeholder="Enter your Confirm password"
                                    value={props.confirmPassword}
                                    onChange={props.handleConfirmPasswordChange}
                                    required
                                />
                                <i
                                    className={`${
                                        props.confirmPasswordVisible
                                            ? "fa fa-eye"
                                            : "fa fa-eye-slash"
                                    } absolute right-0 px-4 py-2 cursor-pointer`}
                                    onClick={
                                        props.toggleConfirmPasswordVisibility
                                    }
                                    aria-hidden="true"
                                ></i>
                            </div>
                        </div>
                        {!props.passwordMatch && (
                            <p className="text-red-500 text-[12px]  pt-1">
                                *Passwords do not match !
                            </p>
                        )}
                        <button
                            disabled={!props.passwordMatch}
                            onClick={() =>
                                props.forgotPasswordFuntion("verify")
                            }
                            className="py-4 px-16 my-3 font-[600] text-white transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600"
                        >
                            Save
                        </button>
                    </>
                )}
            </div>
        </>
    );
}
