import { useState, useEffect } from "react";
import GenerateKeysView from "./GenerateKeys.view";
import { UserService } from "../../services";
function GenerateKeysContainer(props) {
    const [showPopUp, setShowPopup] = useState(false);
    const [privateKeyId, setPrivateKeyId] = useState("");
    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [apiKeys, setApiKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(false);

    const messageController = () => setShowMessage(!showMessage);
    function copyTextToClipboard(textToCopy) {
        const textarea = document.createElement("textarea");
        textarea.value = textToCopy;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        setShowMessage(true);
        setMessageClass("Success");
        setMessageText("Copied successfully!!");
    }

    const apiKeyGeneration = async () => {
        try {
            setLoading(true);
            const data = await UserService.create();
            setShowMessage(true);
            setMessageClass(data?.success ? "Success" : "error");
            setMessageText(data?.message);
            if (data?.success) {
                fetchAPIKeys();
            }
            setLoading(false);
            setTimeout(function () {
                setShowMessage(false);
            }, 1500);
        } catch (err) {
            console.error(err);
        }
    };

    const maxAPIKeysLimitMessageToggle = () => {
        setShowMessage(true);
        setMessageClass("error");
        setMessageText("Maximum limit of the API Key Generation Reached!!");
    };

    const deleteApiKeyConfirmed = async () => {
        try {
            setLoading(true);
            if (!privateKeyId) throw new Error("PrivateKey ID missing!");
            const data = await UserService.delete(privateKeyId);
            setShowMessage(true);
            setMessageClass(data?.success ? "Success" : "error");
            setMessageText(data?.message);
            if (data?.success) {
                fetchAPIKeys();
                setShowPopup(false);
            }
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };
    const deleteApiKey = (id) => {
        setPrivateKeyId(id);
        setShowPopup(true);
    };
    const togglePopup = () => {
        setShowPopup(!showPopUp);
    };
    const fetchAPIKeys = async () => {
        try {
            setFetchLoading(true);
            const data = await UserService.fetch();
            setApiKeys(data?.keys ? data.keys : []);
            setFetchLoading(false);
        } catch (err) {
            console.error(err);
        }
    };
    useEffect(() => {
        fetchAPIKeys();
    }, []);

    return (
        <GenerateKeysView
            copyTextToClipboard={copyTextToClipboard}
            messageText={messageText}
            messageClass={messageClass}
            showMessage={showMessage}
            messageController={messageController}
            apiKeys={apiKeys}
            apiKeyGeneration={apiKeyGeneration}
            maxAPIKeysLimitMessageToggle={maxAPIKeysLimitMessageToggle}
            deleteApiKey={deleteApiKey}
            togglePopup={togglePopup}
            showPopUp={showPopUp}
            deleteApiKeyConfirmed={deleteApiKeyConfirmed}
            loading={loading}
            fetchLoading={fetchLoading}
        />
    );
}

export default GenerateKeysContainer;
