import { useState, useContext } from "react";
import MyAccountView from "./MyAccount.view";
import { Context } from "../../context/Context";
export default function MyAccountContainer(props) {
    const [navOptions, setNavOptions] = useState("Basic Details");
    const { user, setUser } = useContext(Context);
    const changeNavOptions = (value) => setNavOptions(value);
    const phoneCode = "+91";
    const country = "IN";
    return (
        <>
            <MyAccountView
                changeNavOptions={changeNavOptions}
                navOptions={navOptions}
                phoneCode={phoneCode}
                country={country}
                popupController={props.popupController}
                user={user}
            />
        </>
    );
}
