import { lazy } from "react";

const AuthPage = lazy(() => import("./Auth.page"));
const DashboardPage = lazy(() => import("./Dashboard.page"));
const AIVoiceCloningPage = lazy(() => import("./AIVoiceCloning.page"));
const APIKeysPage = lazy(() => import("./APIKeys.page"));
const MyAccountPage = lazy(() => import("./MyAccount.page"));
const ARProductPage = lazy(() => import("./ARProduct.page"));
const DomainVerificationPage = lazy(() => import("./DomainVerification.page"));

export {
    DashboardPage,
    AuthPage,
    AIVoiceCloningPage,
    APIKeysPage,
    MyAccountPage,
    ARProductPage,
    DomainVerificationPage,
};
