import { useState, useEffect, useContext } from "react";
import TextToSpeechView from "./TextToSpeech.view";
import { VoiceService } from "../../services";
import { Context } from "../../context/Context";

function TextToSpeechContainer(props) {
    const { useVoice, setUseVoice } = useContext(Context);
    const [showDropdown, setShowDropdown] = useState(false);
    const [voices, setVoices] = useState([]);
    const [selectedVoice, setSelectedVoice] = useState("");
    const [textInput, setTextInput] = useState("");
    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [showVoiceSettings, setShowVoiceSettings] = useState(false);
    const [loading, setLoading] = useState(false);
    const [noVoiceClick, setNoVoiceClick] = useState(false);
    const [showPopUp, setShowPopup] = useState(false);

    const {
        model,
        setModel,
        setModels,
        similarity,
        stability,
        style,
        userSpeedBoost,
    } = useContext(Context);
    const messageController = () => setShowMessage(!showMessage);
    const voiceController = () => setShowVoiceSettings(!showVoiceSettings);
    const noVoiceController = () => setNoVoiceClick(!showVoiceSettings);
    const maxCharacters = 5000; // Maximum allowed characters
    const [characterCount, setCharacterCount] = useState(0);
    const togglePopup = () => {
        setShowPopup(!showPopUp);
    };
    useEffect(() => {
        const count = textInput.length;
        setCharacterCount(count);
    }, [textInput]);

    const toggleShowDropdown = () => setShowDropdown(!showDropdown);
    const handleChangeVoice = (event) => {
        setSelectedVoice(event.target.value);
    };
    const handleText = (event) => setTextInput(event.target.value);

    useEffect(() => {
        async function fetchVoices() {
            const data = await VoiceService.fetchVoices();
            if (data?.success) {
                setVoices(data);
                setSelectedVoice(
                    useVoice ? useVoice : data?.data?.voices[0]?.voiceId
                );
            }
        }
        fetchVoices();
        fetchModelsFunction();
    }, []);

    const [showDropdown2, setShowDropdown2] = useState(false);
    const toggleShowDropdown2 = () => {
        setShowDropdown2(!showDropdown2);
    };
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };
    const textToSpeechConversion = async () => {
        setLoading(true);
        setShowPopup(true);
        const data = await VoiceService.convertVoiceTextToSpeech(
            textInput,
            model?.model_id,
            stability / 100,
            similarity / 100,
            style / 100,
            userSpeedBoost,
            selectedVoice
        );
        setShowMessage(true);
        setMessageClass(data?.success ? "Success" : "error");
        setMessageText(data?.message);
        setTimeout(function () {
            setShowMessage(false);
        }, 5000);
        if (data?.success) {
            setTimeout(function () {
                setLoading(false);
            }, 2000);
            setUseVoice("");
        } else {
            setLoading(false);
        }
    };
    const clearDataInForm = () => {
        setSelectedVoice(voices?.data?.voices[0]?.voiceId);
        setTextInput("");
    };

    const fetchModelsFunction = async () => {
        const data = await VoiceService.fetchModels();
        if (data?.success) {
            setModels(data?.data);
            setModel(data?.data[0]);
        }
    };
    const leftbuttonfunction = () => {
        togglePopup();
        clearDataInForm();
    };
    const rightbuttonfunction = () => {
        props.changeNavOptions("ClonedVoice");
    };

    return (
        <TextToSpeechView
            showDropdown={showDropdown}
            toggleShowDropdown={toggleShowDropdown}
            voices={voices}
            handleChangeVoice={handleChangeVoice}
            handleText={handleText}
            showDropdown2={showDropdown2}
            toggleShowDropdown2={toggleShowDropdown2}
            isChecked={isChecked}
            handleCheckboxChange={handleCheckboxChange}
            textToSpeechConversion={textToSpeechConversion}
            showMessage={showMessage}
            messageText={messageText}
            messageClass={messageClass}
            messageController={messageController}
            textInput={textInput}
            maxCharacters={maxCharacters}
            selectedVoice={selectedVoice}
            characterCount={characterCount}
            loading={loading}
            voiceController={voiceController}
            showVoiceSettings={showVoiceSettings}
            noVoiceClick={noVoiceClick}
            noVoiceController={noVoiceController}
            changeNavOptions={props.changeNavOptions}
            showPopUp={showPopUp}
            togglePopup={togglePopup}
            clearDataInForm={clearDataInForm}
            leftbuttonfunction={leftbuttonfunction}
            rightbuttonfunction={rightbuttonfunction}
            content={content}
        />
    );
}

const content = {
    heading: "Success",
    paragraph:
        "Your voice clone has been created successfully! Get ready to experience a new dimension of realism in your digital communications.",
    leftButtonText: "Create more voices",
    rightButtonText: "View voice",
    loadingtext: "Voice Clonning in Progress...",
    imageUrl: "../images/clonedvoice/createvoice.webp",
};
export default TextToSpeechContainer;
