import { useState, useEffect, useRef } from "react";
import AudioPlayerView from "./AudioPlayer.view";
import { UserService } from "../../services";

function AudioPlayerContainer(props) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioURL, setAudioURL] = useState("");
    const metaBucket = process.env.REACT_APP_META_BUCKET_NAME;
    const [audio, setAudio] = useState(null);
    const [audioLoaded, setAudioLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const intervalRef = useRef(null);

    useEffect(() => {
        let cleanupFunction = () => {};

        if (audioURL) {
            setIsLoading(true);
            const newAudio = new Audio(audioURL);
            newAudio.addEventListener("canplaythrough", handleAudioLoaded);
            newAudio.addEventListener("ended", handleAudioEnded);
            newAudio.addEventListener("timeupdate", updateProgress);
            setAudio(newAudio);
            setIsPlaying(false);

            cleanupFunction = () => {
                newAudio.pause(); // Pause audio when component unmounts or audioURL changes
                newAudio.removeEventListener(
                    "canplaythrough",
                    handleAudioLoaded
                );
                newAudio.removeEventListener("ended", handleAudioEnded);
                newAudio.removeEventListener("timeupdate", updateProgress);
                clearInterval(intervalRef.current);
            };
        }

        return cleanupFunction;
    }, [audioURL]);

    useEffect(() => {
        // Stop audio when audioPopupController is called
        if (props.audioPopupController) {
            stopAudio();
        }
    }, [props.audioPopupController]);

    const stopAudio = () => {
        if (audio) {
            audio.pause();
            setIsPlaying(false);
        }
    };

    const handleAudioEnded = () => {
        setIsPlaying(false);
        setProgress(0);
    };

    const handleAudioLoaded = () => {
        setAudioLoaded(true);
        setIsLoading(false);
    };

    const updateProgress = () => {
        if (audio && audio.duration > 0) {
            const progress = (audio.currentTime / audio.duration) * 100;
            setProgress(progress);
        }
    };

    const startProgressUpdateInterval = () => {
        intervalRef.current = setInterval(() => {
            if (audio && !audio.paused) {
                updateProgress();
            }
        }, 1000);
    };

    const stopProgressUpdateInterval = () => {
        clearInterval(intervalRef.current);
    };

    const togglePlay = async (s3ObjectKey) => {
        if (!audio || audio.src !== audioURL) {
            await getSpeechFunction(s3ObjectKey);
            return;
        }

        if (isPlaying) {
            audio.pause();
            stopProgressUpdateInterval();
        } else {
            audio.play();
            startProgressUpdateInterval();
        }
        setIsPlaying(!isPlaying);
    };

    const getSpeechFunction = async (s3ObjectKey) => {
        try {
            if (!s3ObjectKey) throw new Error("S3 Object Key missing!");
            setIsLoading(true);
            const audioMap = props.audio || {};
            if (audioMap[s3ObjectKey]) {
                const storedURL = audioMap[s3ObjectKey];
                setAudioURL(storedURL);
                setIsLoading(false);
            } else {
                const data = await UserService.presignedURL(
                    "GET",
                    metaBucket,
                    s3ObjectKey
                );
                const presignedURL = data?.data?.presigned_url;
                if (presignedURL) {
                    props.setAudioDownloadFunction(s3ObjectKey, presignedURL);
                    setAudioURL(presignedURL);
                    setIsLoading(false);
                } else {
                    throw new Error("Failed to retrieve presigned URL");
                }
            }
        } catch (err) {
            console.error(err);
            setIsLoading(false);
        }
    };

    const handleForward = () => {
        if (audio) {
            audio.currentTime = Math.min(audio.currentTime + 5, audio.duration);
            updateProgress(); // Update progress immediately
        }
    };

    const handleBackward = () => {
        if (audio) {
            audio.currentTime = Math.max(audio.currentTime - 5, 0);
            updateProgress(); // Update progress immediately
        }
    };
    useEffect(() => {
        getSpeechFunction(props?.playerSpeech?.s3ObjectKey);
    }, [props.audioPopupController]);
    return (
        <AudioPlayerView
            audioPopupController={props.audioPopupController}
            togglePlay={togglePlay}
            playerSpeech={props.playerSpeech}
            isPlaying={isPlaying}
            isLoading={isLoading}
            progress={progress}
            handleForward={handleForward}
            handleBackward={handleBackward}
            downloadSpeechFunction={props.downloadSpeechFunction}
            audio={props.audio}
        />
    );
}

export default AudioPlayerContainer;
