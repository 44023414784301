export default function SliderView(props) {
    return (
        <div>
            <h5 className="text-[#1C2347] text-[18px] font-[500] pb-1  flex justify-between items-center">
                {props.title}
                {props.warningMessage && (
                    <span className={`text-[12px] font-[400] text-yellow-500`}>
                        {props.warningMessage}
                    </span>
                )}
            </h5>
            <div className="flex w-full justify-between text-[#595959] text-[14px] font-[400]">
                <p>{props.minLabel}</p> <p>{props.maxLabel}</p>
            </div>
            <input
                type="range"
                min="0"
                max="100"
                value={props.preseletedValue}
                name={props.title}
                onChange={props.changefunction}
                className="w-full h-[7px] bg-gray-200 rounded-lg appearance-none cursor-pointer outline-none"
            />
            <div className="text-right text-[#1C2347] text-[14px] font-[500]">
                {props.preseletedValue}%
            </div>
        </div>
    );
}
