import api from "./Axios.service";

const VoiceService = {
    fetchVoices: async () => {
        try {
            const response = await api.get(`/voices`);
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
    addVoice: async (name, s3ObjectKey, description) => {
        try {
            const response = await api.post(`/voice`, {
                name: name,
                s3ObjectKey: s3ObjectKey,
                description: description,
            });
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
    convertVoiceTextToSpeech: async (
        text,
        model_id,
        stability,
        similarity_boost,
        style,
        use_speaker_boost,
        voiceId
    ) => {
        try {
            const response = await api.post(`/voice/speech`, {
                text: text,
                model_id,
                stability,
                similarity_boost,
                style,
                use_speaker_boost,
                voiceId,
            });
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
    fetchSpeeches: async () => {
        try {
            const response = await api.get(`/voice/speech`);
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
    fetchIncompleteSpeeches: async () => {
        try {
            const response = await api.get(`/voice/speech?is_complete=false`);
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
    deleteSpeech: async (_id, deleteFrom) => {
        try {
            const response = await api.put(`/voice/speech`, {
                _id,
                deleteFrom,
            });
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
    deleteVoice: async (_id, deleteFrom) => {
        try {
            const response = await api.put(`/voice`, { _id, deleteFrom });
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
    fetchModels: async () => {
        try {
            const response = await api.get(
                `/voice/models?can_do_text_to_speech=true`
            );
            return response?.data;
        } catch (error) {
            console.error(error);
            return { success: false, ...error?.response?.data };
        }
    },
};
export default VoiceService;
