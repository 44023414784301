import React, { createContext, useState, useEffect } from "react";
import { UserService } from "../services";

const Context = createContext();

const ContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [useVoice, setUseVoice] = useState("");
    const [model, setModel] = useState(null);
    const [models, setModels] = useState(null);
    const [stability, setStability] = useState(50);
    const [similarity, setSimilarity] = useState(50);
    const [style, setStyle] = useState(25);
    const [userSpeedBoost, setUserSpeedBoost] = useState(false);
    useEffect(() => {
        const path = window.location.pathname;
        if (path === "/") return;
        if (path !== "/auth") {
            fetchUserFunction();
        }
    }, []);
    const fetchUserFunction = async () => {
        const data = await UserService.getUser();
        if (data?.success) setUser(data?.data?.user);
    };

    return (
        <Context.Provider
            value={{
                user,
                setUser,
                useVoice,
                setUseVoice,
                model,
                setModel,
                models,
                setModels,
                similarity,
                setSimilarity,
                stability,
                setStability,
                style,
                setStyle,
                userSpeedBoost,
                setUserSpeedBoost,
            }}
        >
            {children}
        </Context.Provider>
    );
};

export { Context, ContextProvider };
