import { Message, Spinner } from "../";
import ForgotPassword from "../ForgotPassword";
import { sortBySearchTerm } from "../../utility/sortBySearchTerm.js";
function SignInView(props) {
    return (
        <>
            {props.showMessage && (
                <Message
                    messageText={props.messageText}
                    messageClass={props.messageClass}
                    messageController={props.messageController}
                />
            )}
            <h5 className="text-left font-[600] text-[28px] leading-[34px] text-[#1C2347]">
                Sign In
            </h5>
            <p className="font-[300] text-[14px] text-[#444444] mb-5 mt-1">
                Sign in to your account to get dive into the metaverse
            </p>
            {!props.forgotPassword ? (
                <>
                    <div className="">
                        <div>
                            {props.authType === "Phone and Otp" && (
                                <>
                                    {!props.enterPhoneOtp && (
                                        <div className="grid">
                                            {" "}
                                            <div>
                                                <div className="relative flex items-center border-[1px] border-[#C1C3C0] text-sm font-normal  rounded-lg">
                                                    <div
                                                        onClick={
                                                            props.toggleDropdown
                                                        }
                                                        className="flex items-center p-4 cursor-pointer w-[120px] bg-[#489BF638] hover:bg-[#489BF6] group hover:rounded-lg"
                                                    >
                                                        <img
                                                            src={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${props.country}.svg`}
                                                            alt={props.country}
                                                            className="w-[30px]"
                                                        />

                                                        <p className="font-[600] text-gray-700 group-hover:text-white pl-1">
                                                            {props.phoneCode}
                                                        </p>

                                                        <i className="fa fa-angle-down pl-1 group-hover:text-white"></i>
                                                    </div>
                                                    {props.isOpen && (
                                                        <div className="absolute z-[9999] mt-56 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 h-[150px] w-[250px]  thin-scrollbar overflow-y-auto">
                                                            <input
                                                                id="search-input"
                                                                className="block w-full px-4 py-2 text-gray-800 border rounded-md border-gray-300 focus:outline-none"
                                                                type="text"
                                                                placeholder="Search items"
                                                                onChange={
                                                                    props.handleSearchInput
                                                                }
                                                            ></input>
                                                            {sortBySearchTerm(
                                                                props.countryCodes.filter(
                                                                    (item) =>
                                                                        item.name
                                                                            .toLowerCase()
                                                                            .includes(
                                                                                props.searchTerm.toLowerCase()
                                                                            )
                                                                ),
                                                                props.searchTerm
                                                            ).map((item, i) => (
                                                                <li
                                                                    key={i}
                                                                    className="flex items-center px-2 py-2 text-[12px] text-gray-700 text-center hover:bg-[#489BF6] hover:text-white font-[600] cursor-pointer rounded-md"
                                                                    value={
                                                                        item.code
                                                                    }
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        props.togglePhone(
                                                                            "+" +
                                                                                e.currentTarget.value.toString()
                                                                        );
                                                                        props.toggleCountry(
                                                                            item.abbr
                                                                        );
                                                                        props.toggleDropdown();
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${item.abbr}.svg`}
                                                                        alt={
                                                                            item.code
                                                                        }
                                                                        className="w-[30px] pr-1"
                                                                    />
                                                                    {item.name}(
                                                                    {item.code})
                                                                </li>
                                                            ))}
                                                        </div>
                                                    )}
                                                    <input
                                                        className="w-full text-grey px-4 max-md:px-2 bg-transparent input-number py-4 focus:outline-none text-[16px]"
                                                        type="number"
                                                        placeholder="Enter phone no."
                                                        name="phoneNumber"
                                                        onChange={
                                                            props.handleInputChange
                                                        }
                                                        onKeyDown={(event) =>
                                                            props.toggleEnterPhoneOtp(
                                                                event,
                                                                true,
                                                                "request"
                                                            )
                                                        }
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <span className="italic text-[14px] font-[400] text-[#FF6767] mt-1">
                                                Note - Phone number with OTP is
                                                valid for India region only
                                            </span>
                                            <button
                                                onClick={(event) =>
                                                    props.toggleEnterPhoneOtp(
                                                        event,
                                                        true,
                                                        "request"
                                                    )
                                                }
                                                className="py-3 w-full my-3 font-[600] text-white transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600 flex justify-center"
                                            >
                                                {props.loading ? (
                                                    <Spinner />
                                                ) : (
                                                    "GET OTP"
                                                )}
                                            </button>
                                        </div>
                                    )}
                                    {props.enterPhoneOtp && (
                                        <div className="grid">
                                            <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3">
                                                OTP
                                                <span className="text-red-500">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-transparent border-[1px] input-number border-[#C1C3C0] focus:outline-[#489BF6] rounded-md my-1"
                                                type="number"
                                                placeholder="Enter OTP"
                                                name="phone-otp"
                                                onChange={
                                                    props.handleInputChange
                                                }
                                                onKeyDown={(e) =>
                                                    props.login(e, "phone")
                                                }
                                                required
                                            />
                                            <div className="text-[12px] font-[400] text-[#595959] leading-[16.41px] py-3">
                                                <p>
                                                    Didn’t get OTP ?{" "}
                                                    {!props.isDisabled ? (
                                                        <span
                                                            className="font-[600] text-[#489BF6] cursor-pointer"
                                                            onClick={(event) =>
                                                                props.toggleEnterPhoneOtp(
                                                                    event,
                                                                    true,
                                                                    "resend"
                                                                )
                                                            }
                                                        >
                                                            Resend OTP
                                                        </span>
                                                    ) : (
                                                        <span className="font-[500]">
                                                            Resend OTP in{" "}
                                                            <span className="font-[500] text-[#489BF6]">
                                                                {props.timer}
                                                            </span>
                                                            &nbsp;secs
                                                        </span>
                                                    )}
                                                </p>
                                            </div>
                                            <button
                                                onClick={(e) =>
                                                    props.login(e, "phone")
                                                }
                                                className="py-3 w-full my-3 font-[600] text-white transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600 flex justify-center"
                                            >
                                                {props.loading ? (
                                                    <Spinner />
                                                ) : (
                                                    "Verify & Login"
                                                )}
                                            </button>
                                        </div>
                                    )}
                                </>
                            )}
                            {props.authType === "Email and Otp" && (
                                <>
                                    {!props.enterEmailOtp && (
                                        <div className="grid">
                                            {" "}
                                            <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3">
                                                Email
                                                <span className="text-red-500">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-transparent border-[1px] border-[#C1C3C0] focus:outline-[#489BF6] rounded-md my-1"
                                                type="email"
                                                placeholder="Enter Email Address"
                                                name="email"
                                                onChange={
                                                    props.handleInputChange
                                                }
                                                onKeyDown={(event) => {
                                                    props.toggleEnterEmailOtp(
                                                        event,
                                                        true,
                                                        "request"
                                                    );
                                                }}
                                                required
                                            />
                                            <button
                                                onClick={(event) => {
                                                    props.toggleEnterEmailOtp(
                                                        event,
                                                        true,
                                                        "request"
                                                    );
                                                }}
                                                className="py-3 w-full my-3 font-[600] text-white transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600 flex justify-center"
                                            >
                                                {props.loading ? (
                                                    <Spinner />
                                                ) : (
                                                    "GET OTP"
                                                )}
                                            </button>
                                        </div>
                                    )}
                                    {props.enterEmailOtp && (
                                        <div className="grid">
                                            <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3">
                                                OTP
                                                <span className="text-red-500">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-transparent border-[1px] border-[#C1C3C0] focus:outline-[#489BF6] rounded-md my-1 input-number"
                                                type="number"
                                                placeholder="Enter OTP"
                                                name="email-otp"
                                                onChange={
                                                    props.handleInputChange
                                                }
                                                onKeyDown={(e) =>
                                                    props.login(e, "email-otp")
                                                }
                                                required
                                            />
                                            <div className="text-[12px] font-[400] text-[#595959] leading-[16.41px] py-3">
                                                <p>
                                                    Didn’t get OTP ?{" "}
                                                    {!props.isDisabled ? (
                                                        <span
                                                            onClick={(
                                                                event
                                                            ) => {
                                                                props.toggleEnterEmailOtp(
                                                                    event,
                                                                    true,
                                                                    "resend"
                                                                );
                                                            }}
                                                            className="font-[600] text-[#489BF6] cursor-pointer"
                                                        >
                                                            Resend OTP
                                                        </span>
                                                    ) : (
                                                        <span className="font-[500]">
                                                            Resend OTP in{" "}
                                                            <span className="font-[500] text-[#489BF6]">
                                                                {props.timer}
                                                            </span>
                                                            &nbsp;secs
                                                        </span>
                                                    )}
                                                </p>
                                            </div>
                                            <button
                                                onClick={(e) =>
                                                    props.login(e, "email-otp")
                                                }
                                                className="py-3 w-full my-3 font-[600] text-white transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600 flex justify-center"
                                            >
                                                {props.loading ? (
                                                    <Spinner />
                                                ) : (
                                                    "Verify & Login"
                                                )}
                                            </button>
                                        </div>
                                    )}
                                </>
                            )}
                            {props.authType === "Email and Password" && (
                                <>
                                    <div className="grid">
                                        <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3">
                                            Email
                                            <span className="text-red-500">
                                                *
                                            </span>
                                        </label>
                                        <input
                                            className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-transparent border-[1px] border-[#C1C3C0] focus:outline-[#489BF6] rounded-md my-1"
                                            type="email"
                                            placeholder="Enter Email Address"
                                            name="email"
                                            onChange={props.handleInputChange}
                                            required
                                        />
                                        <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3">
                                            Password
                                            <span className="text-red-500">
                                                *
                                            </span>
                                        </label>
                                        <div className="relative flex items-center">
                                            <input
                                                className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-transparent border-[1px] border-[#C1C3C0] focus:outline-[#489BF6] rounded-md my-1"
                                                type={
                                                    props.passwordVisible
                                                        ? "text"
                                                        : "password"
                                                }
                                                autoComplete="new-password"
                                                placeholder="Enter password"
                                                name="password"
                                                onChange={
                                                    props.handleInputChange
                                                }
                                                onKeyDown={(e) => {
                                                    props.login(e, "email");
                                                }}
                                                required
                                            />
                                            <i
                                                className={`${
                                                    props.passwordVisible
                                                        ? "fa fa-eye"
                                                        : "fa fa-eye-slash"
                                                } absolute right-0 px-4 py-2 cursor-pointer`}
                                                onClick={
                                                    props.togglePasswordVisibility
                                                }
                                                aria-hidden="true"
                                            ></i>
                                        </div>

                                        <button
                                            onClick={(e) => {
                                                props.login(e, "email");
                                            }}
                                            disabled={props.loading}
                                            className="py-3 w-full my-3 font-[600] text-white transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600 flex justify-center"
                                        >
                                            {props.loading ? (
                                                <Spinner />
                                            ) : (
                                                "Login"
                                            )}
                                        </button>
                                    </div>
                                </>
                            )}

                            <div
                                id="recaptcha-container"
                                className="px-10 max-md:px-4 flex justify-center"
                            ></div>
                        </div>
                        <div className="py-5 text-[#6B6B6B8F] font-[700]">
                            <hr />
                            <p className="text-center -mt-[14px]">
                                <span className="bg-[#FFFFFF] px-2">Or</span>
                            </p>
                        </div>
                        <button
                            onClick={(e) => props.login(e, "google")}
                            className="login-page-buttons"
                        >
                            <img
                                src="../images/login/googlelogo.webp"
                                alt=""
                                className="pr-2"
                            />
                            Sign in with Google
                        </button>
                        {props.authType !== "Email and Password" && (
                            <button
                                className="login-page-buttons"
                                onClick={() =>
                                    props.toggleAuthType("Email and Password")
                                }
                            >
                                <img
                                    src="../images/login/envelopeviolet.webp"
                                    alt=""
                                    className="pr-2"
                                />
                                Sign in Email and Password
                            </button>
                        )}
                        {props.authType !== "Email and Otp" && (
                            <button
                                className="login-page-buttons"
                                onClick={() =>
                                    props.toggleAuthType("Email and Otp")
                                }
                            >
                                <img
                                    src="../images/login/envelopeorange.webp"
                                    alt=""
                                    className="pr-2"
                                />
                                Sign in with Email & OTP
                            </button>
                        )}
                        {props.authType !== "Phone and Otp" && (
                            <button
                                className="login-page-buttons"
                                onClick={() =>
                                    props.toggleAuthType("Phone and Otp")
                                }
                            >
                                <img
                                    src="../images/login/phone.webp"
                                    alt=""
                                    className="pr-2"
                                />
                                Sign in with Phone Number
                            </button>
                        )}
                    </div>
                </>
            ) : (
                <ForgotPassword />
            )}
            <div
                className="flex justify-end text-[12px] font-[600] text-[#489BF6] cursor-pointer leading-[16.41px] py-1"
                onClick={props.toogleForgotPassword}
            >
                {props.forgotPassword
                    ? "Remember Password? Sign In..."
                    : "Forgot Password?"}
            </div>
        </>
    );
}

export default SignInView;
