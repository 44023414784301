import { useEffect, useRef, useState } from "react";
import { UserService, VoiceService } from "../../services";
import ClonedVoiceView from "./ClonedVoice.view";
function ClonedVoiceContainer(props) {
    const [speeches, setSpeeches] = useState([]);
    const [playerSpeech, setPlayerSpeech] = useState([]);
    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [showPopUp, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showAudioPlayer, setShowAudioPlayer] = useState(false);
    const [id, setId] = useState("");
    const [audio, setAudio] = useState({});
    const metaBucket = process.env.REACT_APP_META_BUCKET_NAME;
    const intervalRef = useRef(null);
    const deleteSpeechToogle = (i) => {
        setId(i);
        togglePopup();
    };
    const togglePopup = () => {
        setShowPopup(!showPopUp);
    };
    const setAudioDownloadFunction = (s3ObjectKey, newURL) => {
        setAudio((prevPairs) => ({
            ...prevPairs,
            [s3ObjectKey]: newURL,
        }));
    };
    const audioPopupController = (speech) => {
        setShowAudioPlayer(!showAudioPlayer);
        setPlayerSpeech(speech);
    };
    const messageController = () => setShowMessage(!showMessage);
    const deleteSpeechFunction = async () => {
        setLoading(true);
        try {
            if (!id) throw new Error("Voice ID missing!");
            const data = await VoiceService.deleteSpeech(id, "speech");
            setShowMessage(true);
            setMessageClass(data.success ? "Success" : "error");
            setMessageText(data.message);
            if (data.success) {
                togglePopup();
                fetchSpeeches();
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    };
    const downloadSpeechFunction = async (s3ObjectKey, name) => {
        setLoading(true);
        try {
            if (!s3ObjectKey) throw new Error("S3 Object Key missing!");
            const data = await UserService.presignedURL(
                "GET",
                metaBucket,
                s3ObjectKey
            );
            handleDownload(data?.data?.presigned_url, name);
            setLoading(false);
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    };
    const handleDownload = (data, name) => {
        if (data) {
            fetch(data)
                .then((response) => response.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = name + ".mp3";
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch((error) => {
                    console.error("Error downloading file:", error);
                });
        }
    };
    const pollFetchSpeeches = async (intervalRef) => {
        try {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null; // Reset the reference
            }

            const data = await VoiceService.fetchSpeeches();
            if (!data.success) throw new Error(data);
            setSpeeches(data);

            const incompleteSpeeches = data.data.speeches.filter(
                (speech) => !speech.isComplete
            );
            const incompleteSpeechesCount = incompleteSpeeches.length;

            if (incompleteSpeechesCount === 0) {
                return;
            }
            if (intervalRef.current) return;
            intervalRef.current = setInterval(async () => {
                try {
                    const incompleteData =
                        await VoiceService.fetchIncompleteSpeeches();
                    if (!incompleteData.success)
                        throw new Error(incompleteData);

                    const newIncompleteSpeechesCount =
                        incompleteData.data.speeches.length;
                    if (
                        incompleteSpeechesCount !== newIncompleteSpeechesCount
                    ) {
                        pollFetchSpeeches(intervalRef, setSpeeches);
                    }
                } catch (error) {
                    console.error("Error polling incomplete speeches:", error);
                }
            }, 5000);
        } catch (error) {
            console.error("Error fetching speeches:", error);
        }
    };
    async function fetchSpeeches() {
        setLoading(true);
        await pollFetchSpeeches(intervalRef);
        setLoading(false);
    }
    useEffect(() => {
        fetchSpeeches();
    }, []);
    return (
        <ClonedVoiceView
            speeches={speeches}
            deleteSpeechFunction={deleteSpeechFunction}
            showMessage={showMessage}
            messageText={messageText}
            messageClass={messageClass}
            messageController={messageController}
            showPopUp={showPopUp}
            togglePopup={togglePopup}
            deleteSpeechToogle={deleteSpeechToogle}
            downloadSpeechFunction={downloadSpeechFunction}
            showAudioPlayer={showAudioPlayer}
            audioPopupController={audioPopupController}
            playerSpeech={playerSpeech}
            loading={loading}
            setAudioDownloadFunction={setAudioDownloadFunction}
            audio={audio}
        />
    );
}

export default ClonedVoiceContainer;
