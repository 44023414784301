import SliderView from "./Slider.view";
export default function SliderContainer(props) {
    return (
        <>
            <SliderView
                title={props.title}
                minLabel={props.minLabel}
                maxLabel={props.maxLabel}
                preseletedValue={props.preseletedValue}
                changefunction={props.changefunction}
                warningMessage={props.warningMessage}
            />
        </>
    );
}
