import AudioPlayer from "../AudioPlayer";
import { Message, Loader, Spinner } from "..";
function ClonedVoiceView(props) {
    return (
        <>
            {props.showMessage && (
                <Message
                    messageText={props.messageText}
                    messageClass={props.messageClass}
                    messageController={props.messageController}
                />
            )}
            {props.showAudioPlayer && (
                <AudioPlayer
                    audioPopupController={props.audioPopupController}
                    playerSpeech={props.playerSpeech}
                    downloadSpeechFunction={props.downloadSpeechFunction}
                    setAudioDownloadFunction={props.setAudioDownloadFunction}
                    audio={props.audio}
                />
            )}
            {props.showPopUp && (
                <>
                    <div className="absolute left-0 top-0 w-full h-screen grid justify-items-center items-center font-['barlow']">
                        <div
                            className="absolute left-0 top-0 bg-[#00000060] z-[999] w-full h-screen"
                            data-testid="background"
                            onClick={props.togglePopup}
                        ></div>
                        <div className="text-center fixed z-[9999999999] bg-white rounded-lg p-8 w-[600px] max-[1200px]:w-[400px] max-[1200px]:p-4 max-[400px]:w-full max-md:p-2 text-center grid grid-cols-1 justify-items-center">
                            <div className="flex flex-col items-center">
                                <img
                                    alt=""
                                    src="../images/generatekeys/header.webp"
                                />
                                <h5 className="text-[#1C2347] text-[22px] font-[500] leading-[26px] my-5">
                                    Are you sure you want to delete this audio
                                    file?
                                </h5>
                                <div>
                                    <button
                                        onClick={props.togglePopup}
                                        className="py-3 px-10 my-3 font-[600] transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 text-[#489BF6] border-[2px] border-[#489BF6]"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={props.deleteSpeechFunction}
                                        disabled={props.loading}
                                        className={`bg-gradient-to-r from-blue-500 to-purple-600 transition duration-300 ease-in-out hover:scale-[1.05] py-3.5 px-10 my-3 font-[600] text-white  rounded-lg mr-4 hover:bg-gradient-to-l`}
                                    >
                                        {props.loading ? (
                                            <Spinner />
                                        ) : (
                                            "Confirm"
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                </>
            )}
            {props.loading && <Loader />}
            {!props.loading && (
                <>
                    <div className="grid grid-cols-4 max-[1800px]:grid-cols-3 max-[1450px]:grid-cols-2 max-[1450px]:justify-items-center max-[1100px]:grid-cols-1">
                        {props?.speeches?.data?.speeches?.map((speech, i) => (
                            <div className="font-['barlow'] my-2" key={i}>
                                <div
                                    className={`${!props.showAudioPlayer && "relative"}`}
                                >
                                    <div
                                        className={`${!speech.isComplete ? "border-[#B2BEB5] blur-background" : !speech.error ? "border-[#D6E8FD]" : "border-red-500"} border-[1px] rounded-md p-2 w-[323px]`}
                                    >
                                        <div className="flex justify-between my-2">
                                            <img
                                                src="../images/clonedvoice/clonedvoice-header.webp"
                                                alt=""
                                            />
                                            <p className="text-[12px] font-[400] text-[#3D3C46]">
                                                {new Date(
                                                    speech?.createdAt
                                                ).toLocaleString()}
                                            </p>
                                        </div>
                                        <div className="my-5">
                                            <h5
                                                className={`${speech.error && "text-red-500"} text-[#1C2347] text-[14px] font-[600]`}
                                            >
                                                {speech?.voiceName}
                                            </h5>
                                            <p
                                                className={`${speech.error && "text-[#D3D3D3]"} text-[12px] font-[400] py-2 text-[#646464] h-[100px] overflow-y-hidden hover:overflow-y-scroll hover:thin-scrollbar`}
                                            >
                                                {speech?.text}
                                                {/* <span className="text-[#489BF6] text-[14px] font-[400] cursor-pointer pl-28">
                                        .....
                                    </span> */}
                                            </p>
                                        </div>
                                        <div className="flex justify-between my-2">
                                            {speech.isComplete &&
                                                !speech.error && (
                                                    <button
                                                        onClick={() =>
                                                            props.audioPopupController(
                                                                speech
                                                            )
                                                        }
                                                        className="rounded-l-[31px] rounded-r-[31px] bg-[#489BF626] flex items-center text-[16px] font-[400] text-[#489BF6] w-[100px] p-1"
                                                    >
                                                        <i className="fa fa-play-circle pr-2 text-[32px]"></i>
                                                        PLAY
                                                    </button>
                                                )}
                                            <div className="p-2 flex">
                                                {speech.isComplete &&
                                                    !speech.error && (
                                                        <>
                                                            <img
                                                                onClick={() =>
                                                                    props.downloadSpeechFunction(
                                                                        speech.s3ObjectKey,
                                                                        speech.voiceName
                                                                    )
                                                                }
                                                                src="../images/clonedvoice/arrow-down.webp"
                                                                className="pr-2 cursor-pointer"
                                                                alt=""
                                                            />
                                                        </>
                                                    )}
                                                <img
                                                    onClick={() =>
                                                        props.deleteSpeechToogle(
                                                            speech._id
                                                        )
                                                    }
                                                    className="cursor-pointer"
                                                    src="../images/clonedvoice/trash.webp"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* {!speech.isComplete && (
                                        <div className="absolute inset-0 flex justify-center items-center font-[800] text-[18px] text-[#489BF6]">
                                            <p className="diagonal-text">
                                                Processing...
                                            </p>
                                        </div>
                                    )} */}
                                </div>
                            </div>
                        ))}
                    </div>
                    {props?.speeches?.data?.speeches?.length === 0 && (
                        <>
                            <div className="text-[14px] font-['barlow'] text-red-500 font-[600] flex justify-center items-center h-full">
                                No data available!
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
}

export default ClonedVoiceView;
